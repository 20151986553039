import store from '../../app/store';
import authApi from '../authApi';
import {customBusinessApi} from '../businessesApi';
import superusersApi from '../superusersApi';

export const clearRTKApiCache = () => {
  console.log('clear RTK API Cache');
  store.dispatch(superusersApi.util.resetApiState());
  store.dispatch(authApi.util.resetApiState());
  store.dispatch(customBusinessApi.util.resetApiState());
};
