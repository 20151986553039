import {Box, Button, Stack, Typography} from '@mui/material';
import React from 'react';
import FirebaseStorageImage from '../../firebase-image/components/FirebaseStorageImage';
import getLineBreakHTMLProp from '../../helpers/getLineBreakHTMLProp';
import {FormattedMessage} from '../../intl';
import useAppLanguage from '../../intl/hooks/use-app-language';
import AspectRatioImage from '../atoms/AspectRatioImage';
import GoogleMapsLink from '../atoms/GoogleMapsLink';
import Maps from '../organisms/Maps';

const CLAMP_STYLE = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '10',
  WebkitBoxOrient: 'vertical',
  overflowWrap: 'break-word',
};

const BusinessMiniDetails = ({
  business,
  imageVisible = false,
  nameVisible = true,
  header = null,
}: {
  business: WithCouponsSent<PopulatedBusiness>;
  imageVisible?: boolean;
  nameVisible?: boolean;
  header?: React.ReactNode;
}) => {
  const lang = useAppLanguage();
  const [clamped, setClamped] = React.useState<any>(CLAMP_STYLE);

  return (
    <Box px={1} py={1}>
      {header}
      {/*<Outlet /> */}
      <Stack direction="column" spacing={2} py={1}>
        {nameVisible && (
          <Typography
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
            align="left"
            variant="h6">
            {business?.name}
          </Typography>
        )}
        {imageVisible && (
          <Box sx={{width: '100%'}}>
            <Box
              sx={{
                width: '50%',
                margin: '0 auto',
              }}>
              <FirebaseStorageImage
                reference={business?.image.reference}
                Component={AspectRatioImage}
                ratio="1:1"
              />
            </Box>
          </Box>
        )}
        <Stack
          alignSelf="stretch"
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between">
          <Typography align="left" variant="body2" color="grey.700">
            {business?.category.nameIntl[lang]}
          </Typography>
          <Typography align="left" variant="body2" color="grey.700">
            <FormattedMessage
              id="businessDetails.promotion"
              values={{count: business?.couponsCount || 0}}
            />
          </Typography>
        </Stack>
        {business?.descriptionIntl['FR'] && (
          <>
            <Typography
              variant="body1"
              align="justify"
              color="grey.700"
              {...getLineBreakHTMLProp(business?.descriptionIntl['FR'])}
              sx={clamped}
            />
            <Button
              size="small"
              variant="text"
              onClick={() =>
                setClamped(Object.keys(clamped).length ? {} : CLAMP_STYLE)
              }>
              <FormattedMessage
                id={`common.${
                  Object.keys(clamped).length ? 'showMore' : 'showLess'
                }`}
              />
            </Button>
          </>
        )}
        <Stack direction="column" spacing={1} pb={1} mt={1}>
          <Typography variant="body2" align="left">
            {business?.address || ''}
          </Typography>
          <GoogleMapsLink address={business?.address} />
        </Stack>
      </Stack>
      <Maps
        containerStyle={{
          width: '100%',
          height: '300px',
        }}
        location={{
          lat: business?.location?.coordinates[1],
          lng: business?.location?.coordinates[0],
        }}
      />
    </Box>
  );
};

export default BusinessMiniDetails;
