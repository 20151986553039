import React from 'react';

import {useSendCouponB2CMutation} from '../../redux/couponApi';

import SendCoupon from './SendCoupon';
import useSendCouponMutationEffect from './useSendCouponMutationEffect';

const SendCouponB2C = () => {
  const [sendCoupon, mutation] = useSendCouponB2CMutation();
  const ref = React.useRef<any>();

  useSendCouponMutationEffect({
    mutation,
    ref,
  });

  return <SendCoupon sendCoupon={sendCoupon} mutation={mutation} ref={ref} />;
};

export default SendCouponB2C;
