import {configureStore} from '@reduxjs/toolkit';
import adsApi from '../redux/adsApi';
import {authReducer} from '../redux/auth/authSlice';
import {customBusinessApi} from '../redux/businessesApi';
import {businessInformationSectionsApi} from '../redux/businessInformationSectionsApi';
import businessReferralsApi from '../redux/businessReferralsApi';
import catalogsApi from '../redux/catalogsApi';
import {
  businessCouponsApi,
  extraCouponsApi,
  partnersCouponsApi,
  staffCouponsApi,
} from '../redux/couponApi';
import {loaderReducer} from '../redux/loaderSlice';
import postsApi from '../redux/postsApi';
import productsApi from '../redux/productsApi';

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

const store = configureStore({
  reducer: {
    auth: authReducer,
    loader: loaderReducer,
    [extraCouponsApi.reducerPath]: extraCouponsApi.reducer,
    [businessInformationSectionsApi.reducerPath]:
      businessInformationSectionsApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [customBusinessApi.reducerPath]: customBusinessApi.reducer,
    [partnersCouponsApi.reducerPath]: partnersCouponsApi.reducer,
    [staffCouponsApi.reducerPath]: staffCouponsApi.reducer,
    [businessCouponsApi.reducerPath]: businessCouponsApi.reducer,
    [businessReferralsApi.reducerPath]: businessReferralsApi.reducer,
    [catalogsApi.reducerPath]: catalogsApi.reducer,
    [postsApi.reducerPath]: postsApi.reducer,
    [adsApi.reducerPath]: adsApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      extraCouponsApi.middleware,
      productsApi.middleware,
      customBusinessApi.middleware,
      businessInformationSectionsApi.middleware,
      businessCouponsApi.middleware,
      partnersCouponsApi.middleware,
      businessReferralsApi.middleware,
      catalogsApi.middleware,
      postsApi.middleware,
      adsApi.middleware,
    ]),
});

export default store;
