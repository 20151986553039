import {AppBar, Stack, Toolbar, Typography} from '@mui/material';

import LanguagePicker from '../../lib/language-picker/components/LanguagePicker';
import BusinessRoundImage from '../../lib/ui/atoms/BusinessRoundImage';

const BusinessDetailsAppBar = ({
  business,
}: {
  business: WithImage<Business, 'image'>;
}) => {
  return (
    <AppBar
      position="fixed"
      variant="outlined"
      elevation={0}
      color="primary"
      sx={{
        top: 0,
        backgroundColor: theme => theme.palette.background.default,
        color: theme => theme.palette.common.black,
        border: 0,
      }}>
      <Toolbar>
        <Stack
          spacing={2}
          sx={{width: '100%'}}
          direction="row"
          alignItems="center">
          <Typography sx={{flex: 1}} align="left" variant="h5">
            {business.name}
          </Typography>
          <LanguagePicker />
          <BusinessRoundImage business={business} />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default BusinessDetailsAppBar;
