import {createApi} from '@reduxjs/toolkit/dist/query/react';

import providesTagsFromListResult from '../lib/helpers/providesTagsFromListResult';

import {authBaseQuery} from './apiBaseQuery';

const catalogsApi = createApi({
  reducerPath: 'catalogsApi',
  baseQuery: authBaseQuery,
  tagTypes: ['Catalog', 'CatalogData'],
  endpoints(build) {
    return {
      getPartnersCatalogs: build.query<
        PaginateResult<Catalog & Populate<Catalog, 'business'>>,
        PaginateParams & {
          business: string;
          search?: string;
          order?: any;
          skip?: number;
          limit?: number;
        }
      >({
        query: ({
          business,
          search = '',
          order = {order: 'desc', orderBy: 'createdAt'},
          skip = 0,
          limit = 100,
        }) => ({
          url: `/getPartnersCatalogs`,
          method: 'get',
          params: {
            skip,
            limit,
            business,
            search,
            active: 'true',
            ...order,
          },
        }),
        providesTags: result => providesTagsFromListResult('Catalog')(result),
      }),
      getCatalogs: build.query<
        PaginateResult<Catalog & Populate<Catalog, 'business'>>,
        PaginateParams & {
          business: string;
          search?: string;
          order?: any;
          skip?: number;
          limit?: number;
          partners: boolean;
          category: string;
        }
      >({
        query: ({
          business,
          search = '',
          order = {order: 'desc', orderBy: 'createdAt'},
          skip = 0,
          limit = 100,
          category,
        }) => ({
          url: '/getCatalogs',
          method: 'get',
          params: {
            skip,
            limit,
            business,
            search,
            active: 'true',
            ...order,
            partners: true,
            category,
          },
        }),
        providesTags: result => providesTagsFromListResult('Catalog')(result),
      }),
      getCatalogById: build.query<PopulatedCatalog, string>({
        query: id => ({
          method: 'get',
          url: `/getCatalogById/${id}`,
          params: {
            active: 'true',
            categories: 1,
          },
        }),
        providesTags: result =>
          result
            ? [{id: result._id, type: 'Catalog'}]
            : [{id: 'LIST', type: 'Catalog'}],
      }),
      getStaffCatalogs: build.query<
        PaginateResult<Catalog & Populate<Catalog, 'business'>>,
        PaginateParams & {
          staff: string;
          search?: string;
          order?: any;
          skip?: number;
          limit?: number;
        }
      >({
        query: ({
          staff,
          search = '',
          order = {order: 'desc', orderBy: 'createdAt'},
          skip = 0,
          limit = 100,
        }) => ({
          url: '/getStaffCatalogs',
          method: 'get',
          params: {
            skip,
            limit,
            staff,
            search,
            active: 'true',
            ...order,
          },
        }),
        providesTags: result => providesTagsFromListResult('Catalog')(result),
      }),
    };
  },
});

export const {
  useGetCatalogByIdQuery,
  useGetCatalogsQuery,
  useGetPartnersCatalogsQuery,
  useGetStaffCatalogsQuery,
} = catalogsApi;

export default catalogsApi;
