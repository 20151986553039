import {Chip} from '@mui/material';
import {useEffect, useRef} from 'react';
import useAppLanguage from '../../lib/intl/hooks/use-app-language';

const BusinessInformationSectionChip = ({
  section,
  selected,
  onSelect,
}: {
  section: BusinessInformationSection;
  selected: boolean;
  onSelect: (id: string) => void;
}) => {
  const ref = useRef<any>(null);
  const lang = useAppLanguage();

  useEffect(() => {
    if (selected) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [selected]);

  return (
    <Chip
      ref={ref}
      size="medium"
      color={selected ? 'primary' : 'default'}
      onClick={evt => {
        evt.preventDefault();
        onSelect(section._id);
      }}
      label={section.nameIntl[lang]}
    />
  );
};

export default BusinessInformationSectionChip;
