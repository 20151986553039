import {useEffect, useState} from 'react';
import {NotificationManager} from 'react-notifications';
import {useIntl} from '../intl';

const useGeolocation = () => {
  const intl = useIntl();
  const [position, setPosition] = useState<GeolocationPosition | null>(null);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      NotificationManager.error(
        intl.formatMessage({id: 'common.geoLocationNotActivated'}),
      );
      return;
    }
    const watcher = geo.watchPosition(setPosition, setError);
    return () => geo.clearWatch(watcher);
  }, []);

  return {position, error};
};

export default useGeolocation;
