import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import {ThemeProvider} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import {QueryParamProvider} from 'use-query-params';
import {ReactRouter6Adapter} from 'use-query-params/adapters/react-router-6';
import './App.css';
import store from './app/store';
import {theme} from './appTheme';
import AppLocaleProvider from './lib/intl/components/AppLocaleProvider';
import AppRoutes from './routes/AppRoutes';

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <AppLocaleProvider>
                <AppRoutes />
              </AppLocaleProvider>
            </QueryParamProvider>
          </BrowserRouter>
          <NotificationContainer />
        </ThemeProvider>
      </Provider>
    </LocalizationProvider>
  );
}

export default App;
