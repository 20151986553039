import {Paper} from '@mui/material';

import ProductCard from '../../lib/ui/molecules/ProductCard/ProductCard';
import DataList from '../../lib/ui/organisms/DataList';
import EmptyPageList from '../../lib/ui/organisms/EmptyPageList';

import {FormattedMessage} from '../../lib/intl';
import {useGetProductsQuery} from '../../redux/productsApi';

const CatalogCategoryProductList = ({
  category,
  business,
}: {
  category: string;
  business: string;
}) => {
  return (
    <DataList
      columns={2}
      itemsPerPage={6}
      paginationParams={{
        limit: 'limit',
        skip: 'skip',
      }}
      emptyList={
        <EmptyPageList
          message={
            <FormattedMessage id="catalogCategoryProductList.emptyList" />
          }
        />
      }
      useQuery={useGetProductsQuery}
      renderItem={(product: PopulatedProduct) => (
        <Paper variant="outlined">
          <ProductCard product={product} />
        </Paper>
      )}
      extraQueryParams={{
        category,
        business,
      }}
    />
  );
};

export default CatalogCategoryProductList;
