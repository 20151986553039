import React from 'react';

import {SendSharp} from '@mui/icons-material';
import {LoadingButton, LoadingButtonProps} from '@mui/lab';

import differenceInMinutes from 'date-fns/differenceInMinutes';

import {useGetLatestCouponSendByIdQuery} from '../../redux/couponApi';
import {useLoadingEffect} from '../../redux/loaderSlice';

import config from '../../config.json';
import {FormattedMessage} from '../../lib/intl';
import CouponSendButtonCounter from './CouponSendButtonCounter';

const CouponSendButton = React.forwardRef(
  (
    {
      disabled,
      coupon,
      loading,
      onClick,
      onCouponReachedZero,
    }: {
      coupon?: string;
      onCouponReachedZero: () => void;
    } & LoadingButtonProps,
    ref: any,
  ) => {
    const [shouldWait, setShouldWait] = React.useState(false);
    const query = useGetLatestCouponSendByIdQuery(coupon as string, {
      skip: !coupon,
    });

    const queryData = query.data;

    const checkIfWeShouldWait = React.useCallback(() => {
      const result =
        query.isSuccess &&
        !!queryData?.sentAt &&
        differenceInMinutes(Date.now(), queryData.sentAt) <
          config.couponSendInterval;

      setShouldWait(result);
    }, [query.isSuccess, queryData?.sentAt]);

    const buttonDisabled = disabled || !coupon || query.isLoading || shouldWait;

    const getButtonText = () => {
      if (shouldWait && queryData?.sentAt) {
        return (
          <CouponSendButtonCounter
            latestSentAt={queryData.sentAt}
            onCountReachesZero={() => {
              checkIfWeShouldWait();
              onCouponReachedZero();
            }}
            secondsToWait={config.couponSendInterval * 60}
          />
        );
      }

      return <FormattedMessage id="common.send" />;
    };

    if (ref) {
      ref.current = {
        fetchLatestCouponSend: query.refetch,
      };
    }

    React.useEffect(() => {
      checkIfWeShouldWait();
    }, [checkIfWeShouldWait]);

    useLoadingEffect(query.isLoading);

    return (
      <LoadingButton
        disabled={buttonDisabled}
        loading={loading}
        variant="contained"
        size="large"
        color="primary"
        startIcon={<SendSharp fontSize="small" />}
        onClick={onClick}>
        {getButtonText()}
      </LoadingButton>
    );
  },
);

export default CouponSendButton;
