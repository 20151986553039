export default {
  'couponDescription.reductionBy': 'Descuento del <b>{percentage}</b>',
  'couponDescription.withAt': 'en <b>{name}</b> en',
  'couponDescription.ALL_PRODUCTS': 'en todos los productos o servicios:',
  'couponDescription.PRODUCT': 'en los siguientes productos o servicios:',
  'couponDescription.CATEGORY': 'en las siguientes categorías:',
  'couponDescription.enterCouponInfo':
    'Ingrese su información para recibir el cupón',
  'sendCoupon.contactType': '¿Tipo de contacto?',
  'sendCoupon.labels.phoneNumber': 'Teléfono',
} as const;
