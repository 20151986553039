export default {
  'businessesList.labels.title': 'Businesses',
  'businessFilter.allFilters': 'All Filters',
  'businessFilter.sort': 'Sort',
  'businessFilter.use': 'Use:',
  'businessFilter.order': 'Order:',
  'businessFilter.distance': 'Distance',
  'businessFilter.distanceValue': '{distance} Kms',
  'businessFilter.asc': 'Ascending',
  'businessFilter.desc': 'Descending',
  'businessFilter.relevance': 'Relevance',
} as const;
