import React from 'react';

import {
  AccountTree,
  LocalActivity,
  MenuBook,
  PinDrop,
  RssFeed,
} from '@mui/icons-material';
import {IconButton, Toolbar, Typography} from '@mui/material';

import {useParams} from 'react-router-dom';
import useGetBusinessSiteHomePageName from '../../lib/helpers/useGetBusinessSiteHomePageName';
import {useIntl} from '../../lib/intl';
import MenuLink from '../../lib/ui/atoms/MenuLink';

const MenuIcon: React.FC<{
  Icon: any;
  text: string;
  sx?: any;
}> = ({Icon, text, sx}) => (
  <IconButton color="inherit" sx={{flexDirection: 'column', ...sx}}>
    <Icon fontSize="medium" />
    <Typography variant="body2">{text}</Typography>
  </IconButton>
);

const DisplayBusinessTabs = ({
  sectionsTabVisible = false,
}: {
  sectionsTabVisible?: boolean;
}) => {
  const intl = useIntl();
  const {id} = useParams();
  const siteHomePageName = useGetBusinessSiteHomePageName(id as string);

  return (
    <Toolbar sx={{justifyContent: 'space-around', flex: 1}}>
      {sectionsTabVisible && (
        <MenuLink to="site">
          <MenuIcon Icon={AccountTree} text={`${siteHomePageName}`} />
        </MenuLink>
      )}
      <MenuLink to="nearby">
        <MenuIcon
          Icon={PinDrop}
          text={intl.formatMessage({
            id: 'displayBusinessTabs.home',
          })}
        />
      </MenuLink>
      <MenuLink to="catalogs">
        <MenuIcon
          Icon={MenuBook}
          text={intl.formatMessage({
            id: 'displayBusinessTabs.catalogs',
          })}
        />
      </MenuLink>
      <MenuLink to="coupons">
        <MenuIcon
          Icon={LocalActivity}
          text={intl.formatMessage({
            id: 'displayBusinessTabs.coupons',
          })}
        />
      </MenuLink>
      <MenuLink to="posts">
        <MenuIcon
          Icon={RssFeed}
          text={intl.formatMessage({
            id: 'displayBusinessTabs.posts',
          })}
        />
      </MenuLink>
    </Toolbar>
  );
};

export default DisplayBusinessTabs;
