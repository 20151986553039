export default {
  'businessTabs.aProximite': 'Nearby',
  'businessTabs.catalogues': 'Catalogs',
  'businessTabs.coupons': 'Coupons',
  'businessTabs.actualites': 'News',
  'catalogsList.titlePartners': 'Catalogs and partner catalogs',
  'catalogsList.titleNearby': 'Catalogs of nearby businesses',
  'couponsList.titlePartners': 'Coupons and partner coupons',
  'couponsList.titleNearby': 'Promotions nearby',
  'businessDetailsContent.owner': 'Owner <b>{fullName}</b>',
  'businessInformation.website': 'Website',
  'businessInformation.availableOffers': '{count} Offer(s) available',
  'businessPartners.noPartnerSelected': 'No partner selected',
  'businessPosts.noPosts': 'No posts found',
  'displayBusinessTabs.home': 'Home',
  'displayBusinessTabs.nearby': 'Nearby',
  'displayBusinessTabs.catalogs': 'Catalogs',
  'displayBusinessTabs.coupons': 'Coupons',
  'displayBusinessTabs.posts': 'News',
  'horizontalBusinessPartnersList.noPartnerFound': 'No partner found',
  'partnerCatalogs.noCatalogFound': 'No catalog found',
  'partnerCatalogs.searchPlaceholder': 'Search for partner catalogs',
  'businessDetailsContent.noAddressProvided': 'No address provided',
  'businessDetailsContent.NoSellerNearby': 'No seller nearby',
  'couponsList.searchCouponsPartners': 'Search for coupon partners',
} as const;
