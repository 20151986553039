import isStandardCoupon from '../../helpers/isStandardCoupon';
import {FormattedMessage} from '../../intl';

const CouponSelection = ({coupon}: {coupon: Coupon}) => {
  const standardSelection = coupon.selection as StandardDiscountSelection;
  const bogoSelection = coupon.selection as BuyOneGetOneFreeDiscountSelection;

  if (isStandardCoupon(coupon)) {
    return (
      <FormattedMessage id={`couponSelection.${standardSelection.type}`} />
    );
  }

  if (coupon.discountType === 'BUY_ONE_GET_ONE_FREE') {
    return (
      <>
        <FormattedMessage
          id="couponSelection.products_services"
          values={{quantity: bogoSelection.purchased[0]?.quantity}}
        />{' '}
        <FormattedMessage
          id="couponSelection.purchased"
          values={{name: (bogoSelection.purchased[0].product as Product).name}}
        />{' '}
        <FormattedMessage
          id="couponSelection.products_services"
          values={{quantity: bogoSelection.offered[0]?.quantity}}
        />
        <FormattedMessage
          id="couponSelection.offered"
          values={{name: (bogoSelection.offered[0].product as Product).name}}
        />
      </>
    );
  }

  return null;
};

export default CouponSelection;
