import React from 'react';

import {Room} from '@mui/icons-material';
import {Stack, Typography} from '@mui/material';
import {FormattedMessage} from '../../intl';

const GoogleMapsLink = ({
  address,
  variant = 'body2',
  children,
}: {
  address: string;
  variant?: string;
  children?: React.ReactNode;
}) =>
  !children ? (
    <a
      rel="noreferrer"
      href={`https://maps.google.com/?q=${encodeURIComponent(address)}`}
      onClick={e => e.stopPropagation()}
      target="_blank"
      style={{
        textDecoration: 'none',
        color: 'inherit',
      }}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Room fontSize="small" />
        <Typography variant="body2">
          <FormattedMessage id="googleMaps.openMap" />
        </Typography>
      </Stack>
    </a>
  ) : (
    <Typography
      variant={variant as any}
      component="a"
      style={{
        textDecoration: 'none',
      }}
      target="_blank"
      href={`http://maps.google.com/?q=${encodeURIComponent(address)}`}
      onClick={e => e.stopPropagation()}>
      {children}
    </Typography>
  );

export default GoogleMapsLink;
