import {Outlet, useLocation, useParams} from 'react-router-dom';

import {Box} from '@mui/material';

import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import ActiveBusinessTabs from '../../lib/ui/organisms/ActiveBusinessTabs';

import {useGetBusinessByIdQuery} from '../../redux/businessesApi';
import {useLoadingEffect} from '../../redux/loaderSlice';

import AppBar from '../../lib/ui/atoms/AppBar';
import B2BWebBusinessTabs from './B2BWebBusinessTabs';
import BusinessDetailsAppBar from './BusinessDetailsAppBar';
import BusinessInformation from './BusinessInformation';
import DisplayBusinessTabs from './DisplayBusinessTabs';

const BusinessDetails = () => {
  const {id} = useParams();
  const query = useGetBusinessByIdQuery(id as string);
  const location = useLocation();

  useLoadingEffect(query.isLoading);

  useQueryInternalError(query);

  if (query.data) {
    const business = query.data;

    return (
      <Box sx={{paddingTop: '40px'}}>
        <BusinessDetailsAppBar business={business} />
        {!location.pathname?.includes('partners') && (
          <BusinessInformation business={business} />
        )}
        <Box
          sx={{
            pb: '50px',
          }}>
          <Outlet />
        </Box>
        <AppBar>
          {business?.status === 'ACTIVE' && (
            <ActiveBusinessTabs sectionsTabVisible={business?.withSections} />
          )}
          {business?.status === 'REFERENCE' && (
            <DisplayBusinessTabs sectionsTabVisible={business?.withSections} />
          )}
          {business?.status === 'B2B_WEB' && (
            <B2BWebBusinessTabs sectionsTabVisible={business?.withSections} />
          )}
        </AppBar>
      </Box>
    );
  }

  return null;
};

export default BusinessDetails;
