import {Check} from '@mui/icons-material';
import {Button, DialogActions, DialogTitle, Typography} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import React from 'react';
import useAppStorage from '../../hooks/useAppStorage';
import {FormattedMessage} from '../../intl';

const DEFAULT_MESSAGE = `J’accepte que mon numéro de téléphone ou mon e-mail soit utilisé pour
          m’envoyer le coupon.`;

const UserConscent = ({
  message = DEFAULT_MESSAGE,
}: {
  message?: string | React.ReactNode;
}) => {
  const [consent, setConsent] = useAppStorage(
    '@app/user_email_phone_usage_consent',
  );

  let isOpen;

  try {
    isOpen = consent ? JSON.parse(consent) === true : false;
  } catch (err) {
    isOpen = false;
  }

  return (
    <Dialog open={!isOpen}>
      <DialogTitle>
        <FormattedMessage id="userConscent.dataUsage" />
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<Check fontSize="small" />}
          variant="contained"
          color="primary"
          onClick={() => setConsent('true')}>
          <FormattedMessage id="userConscent.accept" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserConscent;
