export default {
  'common.send': 'Envoyer',
  'common.internalError':
    'Une erreur est survenue, veuillez réessayer plus tard',
  'common.save': 'Enregistrer',
  'common.yes': 'Oui',
  'common.no': 'Non',
  'common.fieldRequired': 'Champs requis',
  'common.invalidPhone': 'Numéro de téléphone invalide',
  'common.inValidEmail': 'Email invalide',
  'common.invalidContact': 'Contact invalide',
  'common.emailUsed': 'Email déjà utilisé',
  'common.phoneUsed': 'Numéro de téléphone déjà utilisé',
  'common.safeGuard': 'Sauvegarder',
  'common.showMore': '...voir plus',
  'common.showLess': 'voir moins',
  'common.category': 'Catégorie',
  'common.apply': 'Appliquer',
  'common.geoLocationNotActivated':
    "La géolocalisation n'est pas prise en charge",
  'common.country': 'Pays',
  'common.phoneNumber': 'Numéro de tél',
} as const;
