import {Search} from '@mui/icons-material';
import React from 'react';

import useDebouncedSearch from '../../hooks/useDebouncedSearch';
import {useIntl} from '../../intl';
import RoundInput from '../atoms/RoundInput';
import CouponCard from '../molecules/CouponCard/CouponCard';
import TitleSection from '../molecules/TitleSection';
import DataList from '../organisms/DataList';
import EmptyPageList from '../organisms/EmptyPageList';
import BusinessesCategoriesScrollableList from './BusinessesCategoriesScrollableList';

const CouponsList = ({
  title,
  useQuery,
  extraParams,
}: {
  title?: string;
  useQuery: any;
  extraParams: any;
}) => {
  const intl = useIntl();
  const {search, debouncedSearchChange} = useDebouncedSearch();

  const [category, setCategory] = React.useState('');
  const handleSelectCategory = React.useCallback(
    (id: string) => {
      setCategory(id === category ? '' : id);
    },
    [category],
  );

  title = title || intl.formatMessage({id: 'couponsList.defaultTitle'});

  return (
    <TitleSection title={title} variant="h6">
      <DataList
        header={
          <>
            <RoundInput
              fullWidth
              onChange={evt => debouncedSearchChange(evt.target.value || '')}
              placeholder={intl.formatMessage({
                id: 'couponsList.searchCoupons',
              })}
              startAdornment={<Search fontSize="medium" />}
            />
            <BusinessesCategoriesScrollableList
              selectedCategory={category}
              onSelectCategory={handleSelectCategory}
            />
          </>
        }
        emptyList={
          <EmptyPageList
            message={intl.formatMessage({id: 'couponsList.emptyCouponsList'})}
          />
        }
        useQuery={useQuery}
        extraQueryParams={{
          ...extraParams,
          orderType: 'MOST_USED',
          hideSoldOut: true,
          search,
          category,
        }}
        renderItem={(coupon: PopulatedCoupon) => <CouponCard coupon={coupon} />}
      />
    </TitleSection>
  );
};

export default CouponsList;
