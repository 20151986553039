import React from 'react';

import {TextField, TextFieldProps} from '@mui/material';

import {Field, FieldProps} from 'formik';
import {useIntl} from '../../../intl';

const FormikTextField: React.FC<{name: string} & TextFieldProps> = ({
  name,
  ...props
}) => {
  const intl = useIntl();

  return (
    <Field name={name}>
      {({field, form}: FieldProps) => (
        <TextField
          {...field}
          {...props}
          error={!!form.errors[name]}
          helperText={
            form.errors[name]
              ? intl.formatMessage({id: form.errors[name] as string})
              : undefined
          }
        />
      )}
    </Field>
  );
};

export default FormikTextField;
