import React from 'react';
import {EqualHeight, EqualHeightElement} from 'react-equal-height';
import {SwiperSlide} from 'swiper/react';

import Swiper from '../atoms/Swiper';
import ProductCard from '../molecules/ProductCard/ProductCard';

const ProductEqualHeightElement = ({
  children,
}: {
  children?: React.ReactNode;
}) => <EqualHeightElement name="product">{children}</EqualHeightElement>;

const ProductsSwiper = ({
  products,
}: {
  products: (Product & Populate<Product, 'image'>)[];
}) => (
  <EqualHeight>
    <Swiper
      spaceBetween={10}
      slidesPerView={2}
      navigation={products.length > 0}>
      {products.map(product => (
        <SwiperSlide key={product._id}>
          <ProductCard
            product={product}
            TitleWrapper={ProductEqualHeightElement}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  </EqualHeight>
);

export default ProductsSwiper;
