import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useParams} from 'react-router-dom';

import {LoadingButton} from '@mui/lab';
import {CircularProgress, Stack, Typography} from '@mui/material';

import {internalError} from '../../lib/helpers/intenralError';

import {Formik} from 'formik';
import * as yup from 'yup';

import FormikPhoneNumberInput from '../../lib/ui/atoms/formik/FormikPhoneNumberInput';
import FormikTextField from '../../lib/ui/atoms/formik/FormikTextField';
import FormikBusinessCategoryPicker from '../../lib/ui/molecules/FormikBusinessCategoryPicker';

import APP_STORE from '../../assets/images/app_store.png';
import GOOGLE_PLAY from '../../assets/images/google_play.png';
import LOGO_IMAGE from '../../assets/images/logo_text.png';

import {
  useAcceptBusinessReferralMutation,
  useGetBusinessReferralByIdQuery,
} from '../../redux/businessReferralsApi';
import {useLoadingEffect} from '../../redux/loaderSlice';

import {FormattedMessage, useIntl} from '../../lib/intl';
import UserConscent from '../../lib/ui/organisms/UserConscent';

const validationSchema = yup.object({
  name: yup.string().required('common.fieldRequired'),
  firstName: yup.string().required('common.fieldRequired'),
  lastName: yup.string().required('common.fieldRequired'),
  category: yup.string().required('common.fieldRequired'),
  email: yup
    .string()
    .required('common.fieldRequired')
    .email('common.inValidEmail'),
  // address: yup.string().required("common.fieldRequired"),
});

const AcceptBusinessReferral = () => {
  const {id} = useParams();
  const intl = useIntl();
  const [acceptBusinessReferral, mutation] =
    useAcceptBusinessReferralMutation();
  const query = useGetBusinessReferralByIdQuery(id as string);

  useLoadingEffect(query.isLoading || mutation.isLoading);

  React.useEffect(() => {
    const responseError = mutation.error as any;
    if (responseError) {
      if (responseError?.data?.error?.code === 'EMAIL_ALREADY_TAKEN') {
        NotificationManager.error(intl.formatMessage({id: 'common.emailUsed'}));
      } else if (
        responseError?.data?.error?.code === 'PHONE_NUMBER_ALREADY_TAKEN'
      ) {
        NotificationManager.error(intl.formatMessage({id: 'common.phoneUsed'}));
      } else {
        internalError(mutation.error);
      }
    }
  }, [mutation.error]);

  return (
    <>
      <UserConscent
        message={
          <FormattedMessage id="acceptBusinessReferral.messages.acceptPhoneNumberEmail" />
        }
      />
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={{
          name: query.data?.name || '',
          firstName: query.data?.firstName || '',
          lastName: query.data?.lastName || '',
          phoneNumber: query.data?.phoneNumber || '',
          email: query.data?.email || '',
          category: (query.data?.category as string) || '',
          businessReferral: id as string,
          address: '',
          location: '',
        }}
        onSubmit={data => acceptBusinessReferral(data)}>
        {({handleSubmit}) => (
          <Stack py={2} px={2} direction="column" spacing={2}>
            {query.data?.accepted ? (
              <img
                src={LOGO_IMAGE}
                style={{
                  width: '300px',
                  height: '300px',
                  alignSelf: 'center',
                }}
              />
            ) : (
              <img
                src={LOGO_IMAGE}
                style={{
                  width: '200px',
                  height: '200px',
                  alignSelf: 'center',
                }}
              />
            )}
            {query.isLoading && <CircularProgress sx={{alignSelf: 'center'}} />}
            {query.data && (
              <>
                {!query.data?.accepted ? (
                  <>
                    <FormikTextField
                      name="firstName"
                      label={
                        <FormattedMessage id="acceptBusinessReferral.labels.yourFirstName" />
                      }
                    />
                    <FormikTextField
                      name="lastName"
                      label={
                        <FormattedMessage id="acceptBusinessReferral.labels.yourName" />
                      }
                    />
                    <FormikTextField
                      name="email"
                      label={
                        <FormattedMessage id="acceptBusinessReferral.labels.email" />
                      }
                    />
                    <FormikPhoneNumberInput
                      name="phoneNumber"
                      label={
                        <FormattedMessage id="acceptBusinessReferral.labels.phoneNumber" />
                      }
                    />
                    <FormikTextField
                      name="name"
                      label={
                        <FormattedMessage id="acceptBusinessReferral.labels.yourBusinessName" />
                      }
                    />
                    <FormikBusinessCategoryPicker
                      label={
                        <FormattedMessage id="acceptBusinessReferral.labels.businessSector" />
                      }
                      name="category"
                    />
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      loading={mutation.isLoading}
                      disabled={mutation.isSuccess}
                      onClick={() => handleSubmit()}>
                      <FormattedMessage id="acceptBusinessReferral.button.continue" />
                    </LoadingButton>
                  </>
                ) : (
                  <Stack direction="column" spacing={2} alignItems="center">
                    <Typography variant="body1">
                      <FormattedMessage id="acceptBusinessReferral.messages.thankYouForRegistration" />
                    </Typography>
                    <Stack direction="column" spacing={1}>
                      <a href="https://play.google.com/store/apps/details?id=com.fluxence.mobile.pro&pli=1">
                        <img
                          style={{width: '96%', height: 'auto'}}
                          src={GOOGLE_PLAY}
                        />
                      </a>
                      <a
                        style={{marginLeft: '-10px'}}
                        href="https://apps.apple.com/fr/app/fluxence-pro/id1631820487">
                        <img
                          style={{width: '100%', height: 'auto'}}
                          src={APP_STORE}
                        />
                      </a>
                    </Stack>
                  </Stack>
                )}
              </>
            )}
          </Stack>
        )}
      </Formik>
    </>
  );
};

export default AcceptBusinessReferral;
