import {Stack} from '@mui/material';

import {StringParam, useQueryParam} from 'use-query-params';

import EmptyList from '../../lib/ui/molecules/EmptyList';
import DataList from '../../lib/ui/organisms/DataList';

import {useParams} from 'react-router-dom';
import {FormattedMessage} from '../../lib/intl';
import {useGetBusinessInformationSectionBlocksQuery} from '../../redux/businessInformationSectionsApi';
import BusinessInformationSectionBlockCard from './BusinessInformationSectionBlockCard';
import BusinessInformationSectionsList from './BusinessInformationSectionsList';

const BusinessSectionsSite = () => {
  const {id: businessId} = useParams() as any;
  const [section, setSelection] = useQueryParam('section', StringParam);

  return (
    <Stack
      sx={{
        position: 'relative',
      }}
      direction="column"
      spacing={1}>
      <BusinessInformationSectionsList
        businessId={businessId}
        selectedSection={section}
        onSelectSection={setSelection}
      />
      {section && (
        <DataList
          renderItem={(item: PopulatedBusinessInformationSectionBlock) => (
            <BusinessInformationSectionBlockCard block={item} />
          )}
          useQuery={useGetBusinessInformationSectionBlocksQuery}
          emptyList={
            <EmptyList
              message={
                <FormattedMessage id="businessSectionsSite.noInformation" />
              }
            />
          }
          extraQueryParams={{
            section,
            business: businessId,
          }}
        />
      )}
    </Stack>
  );
};

export default BusinessSectionsSite;
