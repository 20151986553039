import {createApi} from '@reduxjs/toolkit/dist/query/react';

import providesTagsFromListResult from '../lib/helpers/providesTagsFromListResult';

import {baseBaseQuery} from './apiBaseQuery';

export const customBusinessApi = createApi({
  reducerPath: 'getBusinessApi',
  baseQuery: baseBaseQuery,
  tagTypes: ['Business', 'BusinessSiteHomePageName'],
  endpoints(build) {
    return {
      getReferenceBusinessNearbyBusinesses: build.query<
        PaginateResult<PopulatedBusiness>,
        PaginateParams & {
          category: string;
          id: string;
        }
      >({
        query: ({id, ...params}) => ({
          url: `/getReferenceBusinessNearbyBusinesses/${id}`,
          method: 'get',
          params,
        }),
      }),
      getStaffBusinesses: build.query<
        PaginateResult<WithCouponsSent<PopulatedBusiness>>,
        {
          staff: string;
          limit: number;
          skip: number;
          search?: string;
          order?: string;
          orderBy?: string;
          distance?: number;
          userLocation?: {
            longitude: number;
            latitude: number;
          };
          categories: string[];
        }
      >({
        query: ({
          staff,
          userLocation,
          search,
          limit,
          skip,
          order,
          orderBy,
          distance,
          categories,
        }) => ({
          url: '/getStaffBusinesses',
          method: 'get',
          params: {
            staff,
            userLocation: userLocation
              ? [userLocation.longitude, userLocation.latitude].join(',')
              : undefined,
            order,
            orderBy,
            distance,
            limit,
            skip,
            search,
            categories: categories.join(','),
            includeCouponsCount: 'true',
            B2B: 1,
            B2C: 1,
          },
        }),
        providesTags: providesTagsFromListResult('Business'),
      }),
      getBusinesses: build.query<
        PaginateResult<WithCouponsSent<PopulatedBusiness>>,
        {
          limit: number;
          skip: number;
          search?: string;
          order?: string;
          orderBy?: string;
          distance?: number;
          userLocation?: {
            longitude: number;
            latitude: number;
          };
          categories: string[];
        }
      >({
        query: ({
          userLocation,
          search,
          limit,
          skip,
          order,
          orderBy,
          distance,
          categories,
        }) => ({
          url: '/getBusinesses',
          method: 'get',
          params: {
            userLocation: userLocation
              ? [userLocation.longitude, userLocation.latitude].join(',')
              : undefined,
            order,
            orderBy,
            distance,
            limit,
            skip,
            search,
            categories: categories.join(','),
            includeCouponsCount: 'true',
            B2C: 1,
          },
        }),
        providesTags: result => providesTagsFromListResult('Business')(result),
      }),
      getPartners: build.query<
        PaginateResult<WithCouponsSent<PopulatedBusiness>>,
        {
          business: string;
          search?: string;
          category?: string;
        } & PaginateParams
      >({
        query: ({business, $skip, $limit, category}) => ({
          url: `/getBusinessPartners/${business}`,
          method: 'get',
          params: {
            $skip,
            $limit,
            category,
          },
        }),
        providesTags: result => providesTagsFromListResult('Business')(result),
      }),
      getAllBusinessCategories: build.query<BusinessCategory[], void>({
        query: () => ({
          url: '/getBusinessCategories',
          method: 'get',
          keepUnusedDataFor: 1000000,
        }),
      }),
      getBusinessById: build.query<WithCouponsSent<PopulatedBusiness>, string>({
        query: id => ({
          url: `/getBusinessById/${id}`,
          method: 'get',
          params: {
            includeCouponsCount: 'true',
          },
        }),
        providesTags: result => providesTagsFromListResult('Business')(result),
      }),
      getNearBusinesses: build.query({
        query: ({lng, lat, raduis = 30}) => ({
          url: `/getNearBusinesses`,
          method: 'get',
          params: {lng, lat, raduis: raduis},
        }),
      }),
      getBusinessSiteHomePageName: build.query<IntlContent, string>({
        query: id => ({
          url: `/getBusinessSiteHomePageName/${id}`,
          method: 'get',
        }),
        providesTags: (result, error, id) => [
          {type: 'BusinessSiteHomePageName', id},
        ],
      }),
    };
  },
});

export const {
  useGetBusinessesQuery,
  useGetAllBusinessCategoriesQuery,
  useGetPartnersQuery,
  useGetBusinessByIdQuery,
  useGetStaffBusinessesQuery,
  useGetReferenceBusinessNearbyBusinessesQuery,
  useGetNearBusinessesQuery,
  useGetBusinessSiteHomePageNameQuery,
} = customBusinessApi;
