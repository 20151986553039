export default {
  'couponDescription.reductionBy': 'Rabatt von <b>{percentage}</b>',
  'couponDescription.withAt': 'bei <b>{name}</b> in',
  'couponDescription.ALL_PRODUCTS': 'auf alle Produkte oder Dienstleistungen:',
  'couponDescription.PRODUCT':
    'auf die folgenden Produkte oder Dienstleistungen:',
  'couponDescription.CATEGORY': 'auf die folgenden Kategorien:',
  'couponDescription.enterCouponInfo':
    'Geben Sie Ihre Informationen ein, um den Gutschein zu erhalten',
  'sendCoupon.contactType': 'Kontaktart?',
  'sendCoupon.labels.phoneNumber': 'Telefon',
} as const;
