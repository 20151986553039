import {useState} from 'react';

import {
  ArrowDownward,
  ArrowUpward,
  Close,
  Height,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Whatshot,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Slider,
  Stack,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {FormattedMessage} from '../../../intl';

const orderOptions = [
  {
    value: 'relevance',
    label: 'businessFilter.relevance',
    Icon: Whatshot,
  },
  {
    value: 'distance',
    label: 'businessFilter.distance',
    Icon: Height,
  },
];

const orderByOptions = [
  {
    value: 'asc',
    label: 'businessFilter.asc',
    Icon: ArrowUpward,
  },
  {
    value: 'desc',
    label: 'businessFilter.desc',
    Icon: ArrowDownward,
  },
];

const BusinessFilter = ({
  order,
  orderBy,
  distance,
  visible,
  onClose,
  onOrderChange,
  onOrderByChange,
  onDistanceChange,
}: {
  order: string;
  orderBy: string;
  distance: number;
  visible: boolean;
  onClose: () => void;
  onOrderChange: (order: string) => void;
  onOrderByChange: (orderBy: string) => void;
  onDistanceChange: (distance: number) => void;
}) => {
  const [distanceValue, setDistanceValue] = useState<number>(distance);
  const [orderValue, setOrderValue] = useState<string>(order);
  const [orderByValue, setOrderByValue] = useState<string>(orderBy);

  return (
    <Dialog fullScreen open={visible} onClose={onClose}>
      <Stack spacing={2} direction="row" p={2} alignItems="center">
        <IconButton size="large" onClick={onClose}>
          <Close />
        </IconButton>
        <Typography variant="h5">
          <FormattedMessage id="businessFilter.allFilters" />
        </Typography>
      </Stack>
      <Divider />
      <DialogContent>
        <Stack direction="column" spacing={2}>
          <Typography variant="h5">
            <FormattedMessage id="businessFilter.sort" />
          </Typography>
          <Stack spacing={1}>
            <Typography>
              <FormattedMessage id="businessFilter.use" />
            </Typography>
            <List>
              {orderOptions.map(({value, label, Icon}) => (
                <ListItemButton
                  key={value}
                  onClick={() => setOrderByValue(value)}
                  sx={{px: 0}}>
                  <Stack
                    direction="row"
                    sx={{width: '100%'}}
                    alignItems="center">
                    <ListItemIcon>
                      <Icon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText sx={{flex: 1}}>
                      <FormattedMessage id={label} />
                    </ListItemText>
                    <ListItemIcon>
                      {orderByValue === value ? (
                        <RadioButtonChecked fontSize="medium" />
                      ) : (
                        <RadioButtonUnchecked fontSize="medium" />
                      )}
                    </ListItemIcon>
                  </Stack>
                </ListItemButton>
              ))}
            </List>
          </Stack>
          <Stack spacing={1}>
            <Typography>
              <FormattedMessage id="businessFilter.order" />
            </Typography>
            <List>
              {orderByOptions.map(({value, label, Icon}) => (
                <ListItemButton
                  key={value}
                  onClick={() => setOrderValue(value)}
                  sx={{px: 0}}>
                  <Stack
                    direction="row"
                    sx={{width: '100%'}}
                    alignItems="center">
                    <ListItemIcon>
                      <Icon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText sx={{flex: 1}}>
                      <FormattedMessage id={label} />
                    </ListItemText>
                    <ListItemIcon>
                      {orderValue === value ? (
                        <RadioButtonChecked fontSize="medium" />
                      ) : (
                        <RadioButtonUnchecked fontSize="medium" />
                      )}
                    </ListItemIcon>
                  </Stack>
                </ListItemButton>
              ))}
            </List>
          </Stack>
          <Divider />
          <Stack spacing={1}>
            <Typography>
              <FormattedMessage id="businessFilter.distance" />:
            </Typography>
            <Slider
              onChange={(_, value) => setDistanceValue(value as number)}
              defaultValue={distanceValue}
              step={10}
              marks
              min={0}
              max={100}
              valueLabelDisplay="auto"
            />
            <Typography>
              <FormattedMessage
                id="businessFilter.distanceValue"
                values={{distance}}
              />
            </Typography>
            <Box
              sx={{
                position: 'absolute',
                bottom: theme => theme.spacing(1),
                left: theme => theme.spacing(1),
                right: theme => theme.spacing(1),
              }}>
              <Button
                fullWidth
                onClick={() => {
                  onOrderChange(orderValue);
                  onOrderByChange(orderByValue);
                  onDistanceChange(distanceValue);
                  onClose();
                }}
                variant="contained"
                color="primary"
                size="large">
                <FormattedMessage id="common.apply" />
              </Button>
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default BusinessFilter;
