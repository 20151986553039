import {useParams} from 'react-router-dom';

import {Box} from '@mui/material';

import CatalogsList from '../../lib/ui/organisms/CatalogsList';

import {useGetStaffCatalogsQuery} from '../../redux/catalogsApi';

const StaffCatalogs = () => {
  const {id} = useParams();

  return (
    <Box pt={2}>
      <CatalogsList
        sectionVariant="h6"
        useQuery={useGetStaffCatalogsQuery}
        extraParams={{
          staff: id,
        }}
      />
    </Box>
  );
};

export default StaffCatalogs;
