import React, {ComponentProps, ReactNode} from 'react';

import {Box, Stack, Typography} from '@mui/material';

const TitleSection = ({
  title,
  children,
  variant = 'h5',
}: {
  title: string | React.ReactNode;
  children: ReactNode;
  variant?: ComponentProps<typeof Typography>['variant'];
}) => (
  <Stack spacing={1}>
    <Typography variant={variant}>{title}</Typography>
    <Box>{children}</Box>
  </Stack>
);

export default TitleSection;
