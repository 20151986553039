import {useNavigate} from 'react-router-dom';

import {ButtonBase, Paper, Stack, Typography} from '@mui/material';

import format from 'date-fns/format';

import BLANK_IMAGE_RECTANGULAR from '../../../../assets/images/blankimage_rectangular.png';
import BLANK_IMAGE_SQUARE from '../../../../assets/images/blankimage_square.png';

import FirebaseStorageImage from '../../../firebase-image/components/FirebaseStorageImage';
import {FormattedMessage} from '../../../intl';
import useAppLanguage from '../../../intl/hooks/use-app-language';
import AspectRatioImage from '../../atoms/AspectRatioImage';
import GoogleMapsLink from '../../atoms/GoogleMapsLink';
import PhoneNumberLink from '../../atoms/PhoneNumberLink';

const formatDate = (date: Date | number) => format(date, 'd MMMM yyyy');

const CatalogCard = ({catalog}: {catalog: PopulatedCatalog}) => {
  const navigate = useNavigate();
  const language = useAppLanguage();

  return (
    <Paper variant="outlined">
      <ButtonBase
        sx={{
          width: '100%',
        }}
        onClick={() => {
          navigate(`/catalog/${catalog._id}`, {
            state: {
              goBack: true,
            },
          });
        }}>
        <Stack
          sx={{
            width: '100%',
            p: 1,
          }}
          direction="column"
          spacing={1}>
          <Stack
            direction="row"
            sx={{
              margin: '-8px 0 -3px -8px',
            }}>
            <Typography
              sx={{
                color: theme => theme.palette.common.white,
                backgroundColor: '#b96d74',
                px: 2,
              }}>
              {catalog.business?.name}
            </Typography>
          </Stack>
          {catalog?.image?.reference ? (
            <FirebaseStorageImage
              Component={AspectRatioImage}
              reference={catalog?.image.reference}
              ratio={catalog.imageAspectRatio}
            />
          ) : (
            <AspectRatioImage
              src={
                catalog.imageAspectRatio === '1:1'
                  ? BLANK_IMAGE_SQUARE
                  : BLANK_IMAGE_RECTANGULAR
              }
              ratio={catalog.imageAspectRatio}
            />
          )}
          <Typography
            variant="h6"
            align="left"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            {catalog.nameIntl[language]}
          </Typography>
          <Stack direction="column" spacing={0.5}>
            <Typography align="left" variant="body2">
              <FormattedMessage
                id="catalog.expirationFromTo"
                values={{
                  startsAt: formatDate(new Date(catalog.startsAt)),
                  expiresAt: formatDate(new Date(catalog.expiresAt)),
                }}
              />
            </Typography>
          </Stack>
          <Stack spacing={1}>
            {(catalog.address?.address || catalog.business?.address) && (
              <Stack direction="column" spacing={1}>
                <Typography align="left" variant="body2" color="grey.700">
                  {catalog?.category?.nameIntl[language] || ''}
                </Typography>
                <Typography align="left" variant="body2">
                  {catalog.address?.address || catalog.business?.address}
                </Typography>
                <GoogleMapsLink
                  address={
                    catalog.address?.address || catalog.business?.address
                  }
                />
              </Stack>
            )}
            {catalog.phoneNumber?.phoneNumber && (
              <PhoneNumberLink phoneNumber={catalog.phoneNumber?.phoneNumber} />
            )}
          </Stack>
        </Stack>
      </ButtonBase>
    </Paper>
  );
};

export default CatalogCard;
