import {createApi} from '@reduxjs/toolkit/dist/query/react';

import invalidatesTags from '../lib/helpers/invalidatesTags';
import providesTagsArray from '../lib/helpers/providesTagsArray';
import providesTagsPagination from '../lib/helpers/providesTagsPagination';

import {authBaseQuery} from './apiBaseQuery';

export const businessInformationSectionsApi = createApi({
  reducerPath: 'businessInformationSectionsApi',
  baseQuery: authBaseQuery,
  tagTypes: [
    'BusinessInformationSection',
    'BusinessInformationSectionBlock',
    'Business',
  ],
  endpoints(build) {
    return {
      getBusinessInformationSections: build.query<
        BusinessInformationSection[],
        string
      >({
        query: business => ({
          url: '/getBusinessInformationSections',
          method: 'get',
          params: {
            business,
          },
        }),
        providesTags: providesTagsArray('BusinessInformationSection'),
      }),
      createBusinessInformationSection: build.mutation<
        BusinessInformationSection,
        {
          name: string;
          business: string;
        }
      >({
        query: ({name, business}) => ({
          url: '/createBusinessInformationSection',
          method: 'post',
          data: {
            name,
            business,
          },
        }),
        invalidatesTags: invalidatesTags('BusinessInformationSection'),
      }),
      updateBusinessInformationSection: build.mutation<
        BusinessInformationSection,
        {
          id: string;
          name: string;
          business: string;
        }
      >({
        query: ({id, name, business}) => ({
          url: `/updateBusinessInformationSection/${id}`,
          method: 'put',
          data: {
            name,
            business,
          },
        }),
        invalidatesTags: invalidatesTags('BusinessInformationSection'),
      }),
      createBusinessInformationSectionBlock: build.mutation<
        BusinessInformationSection,
        {
          heading: string;
          content: string;
          business: string;
          section: string;
          image?: string;
        }
      >({
        query: ({heading, content, section, image, business}) => ({
          url: '/createBusinessInformationSectionBlock',
          method: 'post',
          data: {
            image,
            heading,
            content,
            business,
            section,
          },
        }),
        invalidatesTags: invalidatesTags('BusinessInformationSectionBlock'),
      }),
      updateBusinessInformationSectionBlock: build.mutation<
        BusinessInformationSection,
        {
          id: string;
          heading: string;
          content: string;
          business: string;
          section: string;
        }
      >({
        query: ({id, heading, content, section, business}) => ({
          url: `$/updateBusinessInformationSectionBlock/${id}`,
          method: 'post',
          data: {
            heading,
            content,
            business,
            section,
          },
        }),
        invalidatesTags: invalidatesTags('BusinessInformationSectionBlock'),
      }),
      updateBusinessInformationSectionBlockImage: build.mutation<
        BusinessInformationSection,
        {
          id: string;
          image: string;
        }
      >({
        query: ({id, image}) => ({
          url: `$/updateBusinessInformationSectionBlockImage/${id}`,
          method: 'post',
          data: {
            image,
          },
        }),
        invalidatesTags: invalidatesTags('BusinessInformationSectionBlock'),
      }),
      getBusinessInformationSectionBlocks: build.query({
        query: ({business, section, skip, limit}) => ({
          url: '/getBusinessInformationSectionBlocks',
          method: 'get',
          params: {
            business,
            section,
            skip,
            limit,
          },
        }),
        providesTags: providesTagsPagination('BusinessInformationSectionBlock'),
      }),
      getBusinessInformationSectionBlockById: build.query<
        PopulatedBusinessInformationSectionBlock,
        {
          id: string;
          business: string;
        }
      >({
        query: ({id, business}) => ({
          url: `/getBusinessInformationSectionBlockById/${id}`,
          method: 'get',
          params: {
            business,
          },
        }),
        providesTags: result =>
          result
            ? [
                {
                  type: 'BusinessInformationSectionBlock',
                  id: result._id,
                },
              ]
            : [],
      }),
      getBusinessInformationSectionById: build.query<
        BusinessInformationSection,
        {
          id: string;
          business: string;
        }
      >({
        query: ({id, business}) => ({
          url: `/getBusinessInformationSectionById/${id}`,
          method: 'get',
          params: {
            business,
          },
        }),
        providesTags: result =>
          result
            ? [
                {
                  type: 'BusinessInformationSection',
                  id: result._id,
                },
              ]
            : [],
      }),
      deleteBusinessInformationSectionBlock: build.mutation<
        string,
        {
          id: string;
          business: string;
        }
      >({
        query: ({id, business}) => ({
          url: `/deleteBusinessInformationSectionBlock/${id}`,
          method: 'delete',
          params: {
            business,
          },
        }),
        invalidatesTags: result => [
          {
            type: 'BusinessInformationSectionBlock',
            id: 'LIST',
          },
        ],
      }),
      deleteBusinessInformationSection: build.mutation<
        string,
        {
          id: string;
          business: string;
        }
      >({
        query: ({id, business}) => ({
          url: `/deleteBusinessInformationSection/${id}`,
          method: 'delete',
          params: {
            business,
          },
        }),
        invalidatesTags: result => [
          {
            type: 'BusinessInformationSection',
            id: 'LIST',
          },
          {
            type: 'BusinessInformationSectionBlock',
            id: 'LIST',
          },
        ],
      }),
      moveBusinessInformationSectionBlock: build.mutation<
        string,
        {
          id: string;
          business: string;
          direction: 'up' | 'down';
        }
      >({
        query: ({id, business, direction}) => ({
          url: `/moveBusinessInformationSectionBlock/${id}`,
          method: 'patch',
          data: {
            business,
            direction,
          },
        }),
        invalidatesTags: result => [
          {
            type: 'BusinessInformationSectionBlock',
            id: 'LIST',
          },
        ],
      }),
    };
  },
});

export const {
  useDeleteBusinessInformationSectionBlockMutation,
  useGetBusinessInformationSectionBlockByIdQuery,
  useGetBusinessInformationSectionBlocksQuery,
  useCreateBusinessInformationSectionBlockMutation,
  useCreateBusinessInformationSectionMutation,
  useGetBusinessInformationSectionsQuery,
  useGetBusinessInformationSectionByIdQuery,
  useDeleteBusinessInformationSectionMutation,
  useUpdateBusinessInformationSectionBlockMutation,
  useUpdateBusinessInformationSectionBlockImageMutation,
  useUpdateBusinessInformationSectionMutation,
  useMoveBusinessInformationSectionBlockMutation,
} = businessInformationSectionsApi;
