export default {
  'businessesList.emptyList': 'Keine Unternehmen gefunden',
  'businessCard.available': 'Verfügbar',
  'notFound.content': '404 Nicht gefunden',
  'internalError.content': 'Unbekannter Fehler',
  'sendCoupon.couponSoldout': 'Der Gutschein ist bereits ausverkauft',
  'sendCoupon.couponSent': 'Der Gutschein wurde erfolgreich gesendet',
  'sendCoupon.couponNotActivated':
    'Dieser Gutschein ist noch nicht aktiviert. Bitte versuchen Sie es später erneut',
  'sendCoupon.dailyQuotaExceeded':
    'Sie haben heute die maximale Anzahl von Versuchen für diesen Gutschein erreicht',
  'couponsList.searchCoupons': 'Nach Gutscheinen suchen',
  'couponsList.emptyCouponsList': 'Keine Gutscheine gefunden',
  'couponsList.defaultTitle': 'Gutscheine und Partnergutscheine',
  'activeBusinessTabs.home': 'Startseite',
  'activeBusinessTabs.partners': 'Partner',
  'activeBusinessTabs.catalogs': 'Kataloge',
  'activeBusinessTabs.coupons': 'Gutscheine',
  'activeBusinessTabs.posts': 'Nachrichten',
  'userConscent.accept': 'Akzeptieren',
  'userConscent.dataUsage': 'Datenverwendung',
  'unauthorized.accessDenied': 'Zugriff verweigert',
  'unauthorized.message': 'Sie haben keinen Zugriff auf diese Seite',
  'dashboardFilter.search': 'Suche',
  'dashboardFilter.filter': 'Filtern',
  'dashboardFilter.customInterval': 'Benutzerdefiniertes Intervall',
  'confirmation.description':
    'Sind Sie sicher, dass Sie diese Aktion bestätigen möchten?',
  'catalogsList.searchCatalogs': 'Nach Katalogen nach Produkt suchen...',
  'catalogsList.emptyList': 'Keine Kataloge gefunden',
  'dashboardFilter.thisMonth': 'Diesen Monat',
  'dashboardFilter.from': 'Von',
  'dashboardFilter.to': 'Bis',
} as const;
