import {Box, CircularProgress, Stack, Typography} from '@mui/material';
import React from 'react';
import {useParams} from 'react-router-dom';
import {SwiperSlide} from 'swiper/react';

import {FormattedMessage} from '../../lib/intl';
import Swiper from '../../lib/ui/atoms/Swiper';
import AdDisplay from '../../lib/ui/molecules/AdDisplay';
import BusinessPartnerCard from '../../lib/ui/molecules/BusinessCard/BusinessPartnerCard';
import TitleSection from '../../lib/ui/molecules/TitleSection';
import BusinessesCategoriesScrollableList from '../../lib/ui/organisms/BusinessesCategoriesScrollableList';
import {useGetPartnersQuery} from '../../redux/businessesApi';
import {useLoadingEffect} from '../../redux/loaderSlice';

const HorizontalBusinessPartnersList = ({onClickItem}: any) => {
  const [category, setCategory] = React.useState('');
  const {id} = useParams();
  const query = useGetPartnersQuery({
    business: id as string,
    category,
  });
  const partners = query.data?.data || [];

  useLoadingEffect(query.isLoading);

  const handleSelectCategory = React.useCallback(
    (id: string) => {
      setCategory(id === category ? '' : id);
    },
    [category],
  );

  if (query.isLoading) {
    return (
      <Stack alignItems="center" py={8} px={5}>
        <CircularProgress />
      </Stack>
    );
  }

  /*if (partners.length === 0) {
    return (
      <Box py={8} px={5}>
        <Typography variant="body1" align="center">
          Aucun partenaire trouvé
        </Typography>
      </Box>
    );
  }*/

  return (
    <Box
      py={1}
      sx={{
        backgroundColor: 'grey.100',
      }}>
      <Box
        sx={{
          backgroundColor: 'white',
          py: 2,
          px: 2,
          '& .swiper-wrapper': {
            paddingBottom: '40px',
          },
          '& .swiper-button-prev': {
            top: 'auto',
            bottom: 0,
          },
          '& .swiper-button-next': {
            top: 'auto',
            bottom: 0,
          },
        }}>
        <TitleSection title="Partenaires" variant="h6">
          <BusinessesCategoriesScrollableList
            selectedCategory={category}
            onSelectCategory={v => handleSelectCategory(v)}
          />
          {partners.length > 0 ? (
            <Swiper spaceBetween={16} slidesPerView={2} navigation>
              <AdDisplay
                options={{quantity: 2, Wrapper: SwiperSlide, isSlider: true}}
              />
              {partners?.map(partner => (
                <SwiperSlide key={partner._id}>
                  <BusinessPartnerCard
                    sx={{width: '100%'}}
                    partner={partner}
                    onClick={() => onClickItem(partner)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <Box py={8} px={5}>
              <Typography variant="body1" align="center">
                <FormattedMessage id="horizontalBusinessPartnersList.noPartnerFound" />
              </Typography>
            </Box>
          )}
        </TitleSection>
      </Box>
    </Box>
  );
};

export default HorizontalBusinessPartnersList;
