import {useLocation, useSearchParams} from 'react-router-dom';
import useGeolocation from '../lib/hooks/use-geolocation';
import {useGetAdByCampaignQuery, useGetAdsQuery} from '../redux/adsApi';
import {useGetBusinessByIdQuery} from '../redux/businessesApi';

export const useGetAd = (businessId: string = '', $limit: number) => {
  const navigate = useLocation();
  const [searchParams] = useSearchParams();
  const {position} = useGeolocation();
  const campaign = searchParams.get('campaign');

  const placement: string = navigate.pathname.substring(
    navigate.pathname.lastIndexOf('/') + 1,
  );
  const businessQuery = useGetBusinessByIdQuery(businessId, {
    skip: !businessId,
  });

  const query = useGetAdsQuery(
    {
      coordinates: [
        position?.coords.longitude as number,
        position?.coords.latitude as number,
      ],
      category: businessQuery.data?.category?._id,
      placement,
      $limit,
    },
    {
      skip:
        !businessId ||
        !position ||
        !businessQuery.data?.category?._id ||
        !!campaign,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    },
  );

  const adPreview = useGetAdByCampaignQuery(
    {campaign},
    {
      skip: !campaign,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    },
  );

  if (Object.keys(adPreview.data || {}).length) {
    const ad = adPreview.data;
    return [
      {
        _id: ad._id,
        adSet: ad.adSet._id,
        ad: ad.ad._id,
        budget: ad.budget,
        primaryText: ad.ad.primaryText,
        headline: ad.ad.headline,
        description: ad.ad.description,
        websiteURL: ad.ad.websiteURL,
        media: ad.media.reference,
      },
    ];
  }

  return query.data ? query.data : [];
};
