import {useNavigate} from 'react-router-dom';

import {ArrowBack} from '@mui/icons-material';
import {AppBar, IconButton, Stack, Toolbar, Typography} from '@mui/material';
import LanguagePicker from '../../lib/language-picker/components/LanguagePicker';

const MAX_NAME_LENGTH = 25;

const SendCouponBar = ({
  coupon,
  backVisible = false,
}: {
  coupon: Coupon;
  backVisible?: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <AppBar
      position="fixed"
      variant="outlined"
      elevation={0}
      color="primary"
      sx={{
        top: 0,
        backgroundColor: theme => theme.palette.background.default,
        color: theme => theme.palette.common.black,
        border: 0,
      }}>
      <Toolbar>
        <Stack
          spacing={2}
          sx={{width: '100%'}}
          direction="row"
          alignItems="center">
          {backVisible && (
            <IconButton color="inherit" onClick={() => navigate(-1)}>
              <ArrowBack fontSize="medium" />
            </IconButton>
          )}
          <Typography sx={{flex: 1}} align="left" variant="h5">
            {coupon.nameIntl['FR'].substring(0, MAX_NAME_LENGTH)}
            {''}
            {coupon.nameIntl['FR'].length > MAX_NAME_LENGTH ? '...' : ''}
          </Typography>
          <LanguagePicker />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default SendCouponBar;
