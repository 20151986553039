import {ComponentProps} from 'react';

import {Input} from '@mui/material';

const RoundInput = ({sx, ...props}: ComponentProps<typeof Input>) => (
  <Input
    sx={{
      flex: 1,
      border: '2px solid',
      borderColor: 'grey.100',
      p: 0.5,
      ...sx,
    }}
    disableUnderline
    {...props}
  />
);

export default RoundInput;
