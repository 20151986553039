import {createApi} from '@reduxjs/toolkit/dist/query/react';

import {authBaseQuery} from './apiBaseQuery';

const productsApi = createApi({
  reducerPath: 'productsApi',
  baseQuery: authBaseQuery,
  endpoints(build) {
    return {
      getProducts: build.query<
        PaginateResult<Product>,
        {
          skip?: number;
          limit?: number;
        }
      >({
        query: params => ({
          url: `/getProducts`,
          method: 'get',
          params,
        }),
      }),
    };
  },
});

export const {useGetProductsQuery} = productsApi;

export default productsApi;
