import {Box, LinearProgress} from '@mui/material';

import {useAppSelector} from '../../../app/hooks';

const GlobalLoader = () => {
  const loaders = useAppSelector(state => state.loader.loaders);
  const loading = Object.keys(loaders).reduce(
    (acc, key) => acc || loaders[key],
    false,
  );

  if (!loading) {
    return null;
  }

  return (
    <Box
      sx={{position: 'absolute', top: 0, left: 0, right: 0, zIndex: 1000000}}>
      <LinearProgress />
    </Box>
  );
};

export default GlobalLoader;
