import {Chip, Stack} from '@mui/material';

import {useGetAllBusinessCategoriesQuery} from '../../../redux/businessesApi';
import useAppLanguage from '../../intl/hooks/use-app-language';

const BusinessesCategoriesScrollableList = ({
  selectedCategory,
  onSelectCategory,
}: {
  selectedCategory: string;
  onSelectCategory: (id: string) => void;
}) => {
  const lang = useAppLanguage();
  const query = useGetAllBusinessCategoriesQuery();

  return (
    <Stack
      direction="row"
      py={2}
      mb={1}
      spacing={1}
      sx={{
        overflowX: 'scroll',
        borderRadius: '2px',
      }}>
      {query.data?.map(businessesCategory => (
        <Chip
          size="medium"
          color={
            businessesCategory._id === selectedCategory ? 'primary' : 'default'
          }
          key={businessesCategory._id}
          onClick={evt => {
            evt.preventDefault();
            onSelectCategory(businessesCategory._id);
          }}
          label={businessesCategory.nameIntl[lang]}
        />
      ))}
    </Stack>
  );
};

export default BusinessesCategoriesScrollableList;
