import {
  GoogleMap,
  MarkerF,
  PolylineF,
  useJsApiLoader,
} from '@react-google-maps/api';
import React from 'react';

import GPS_IMAGE from '../../../assets/images/gps.png';
import STORE_IMAGE from '../../../assets/images/store.png';
import config from '../../../config.json';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const GoogleMaps = ({containerStyle: propContainerStyle, location}: any) => {
  const {isLoaded} = useJsApiLoader({
    id: 'react-google-map-script',
    googleMapsApiKey: config.googleMapsApi,
  });

  const [map, setMap] = React.useState<any>(null);
  const [userPosition, setUserPosition] = React.useState<any>();

  const onLoad = React.useCallback((map: any) => setMap(map), []);

  const onUnmount = React.useCallback(() => {
    setMap(null);
  }, []);

  React.useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      if (location && navigator.geolocation) {
        navigator?.geolocation.getCurrentPosition(
          ({coords: {latitude: lat, longitude: lng}}) => {
            setUserPosition({lat, lng});
            bounds.extend(location);
            bounds.extend({lat, lng});
            map.fitBounds(bounds);
          },
        );
      }
    }
  }, [location, map]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={propContainerStyle || containerStyle}
      center={location}
      zoom={8}
      onLoad={onLoad}
      onUnmount={onUnmount}>
      {location?.lat && <MarkerF position={location} icon={STORE_IMAGE} />}
      {userPosition?.lat && (
        <>
          <MarkerF position={userPosition} icon={GPS_IMAGE} />
          <PolylineF
            path={[userPosition, location]}
            options={{
              strokeColor: '#000',
              strokeOpacity: 0.6,
              geodesic: true,
            }}
          />
        </>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default React.memo(GoogleMaps);
