export default {
  'acceptBusinessReferral.labels.yourName': 'Tu nombre',
  'acceptBusinessReferral.labels.yourFirstName': 'Tu primer nombre',
  'acceptBusinessReferral.labels.email': 'Correo electrónico',
  'acceptBusinessReferral.labels.phoneNumber': 'Número de teléfono',
  'acceptBusinessReferral.labels.yourBusinessName': 'Nombre de tu negocio',
  'acceptBusinessReferral.labels.businessSector': 'Sector empresarial',
  'acceptBusinessReferral.button.continue': 'Continuar',
  'acceptBusinessReferral.messages.acceptPhoneNumberEmail':
    'Acepto que se utilice mi número de teléfono o correo electrónico',
  'acceptBusinessReferral.messages.thankYouForRegistration':
    'Gracias por registrarte. Para continuar, descarga la aplicación',
  'acceptBusinessReferral.errorMessages.emailAlreadyTaken':
    'Correo electrónico ya registrado',
  'acceptBusinessReferral.errorMessages.phoneNumberAlreadyTaken':
    'Número de teléfono ya registrado',
  'acceptBusinessReferral.validationErrors.requiredField': 'Campo requerido',
  'acceptBusinessReferral.validationErrors.invalidEmail':
    'Correo electrónico inválido',
} as const;
