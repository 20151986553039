import TextAppBar from '../../lib/ui/molecules/TextAppBar';

const PostDetailsAppBar = ({
  post,
  backVisible = false,
}: {
  post?: any;
  backVisible?: boolean;
}) => <TextAppBar text={post?.title || ''} backVisible={backVisible} />;

export default PostDetailsAppBar;
