import {useParams} from 'react-router-dom';

import {Box} from '@mui/material';

import useDebouncedSearch from '../../lib/hooks/useDebouncedSearch';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import CouponsList from '../../lib/ui/organisms/CouponsList';

import {useIntl} from '../../lib/intl';
import {useGetBusinessByIdQuery} from '../../redux/businessesApi';
import {useGetBusinessCouponsQuery} from '../../redux/couponApi';
import {useLoadingEffect} from '../../redux/loaderSlice';

const BusinessCoupons = () => {
  const {id} = useParams();
  const intl = useIntl();
  const {search, debouncedSearchChange} = useDebouncedSearch();
  const query = useGetBusinessByIdQuery(id as string);

  useLoadingEffect(query.isLoading);

  useQueryInternalError(query);

  return (
    <Box px={1} pt={2}>
      <CouponsList
        title={
          query.data?.status === 'ACTIVE'
            ? intl.formatMessage({
                id: 'couponsList.titlePartners',
              })
            : intl.formatMessage({
                id: 'couponsList.titleNearby',
              })
        }
        useQuery={useGetBusinessCouponsQuery}
        extraParams={{
          business: id,
          search,
        }}
      />
    </Box>
  );
};

export default BusinessCoupons;
