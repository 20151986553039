export default {
  'businessTabs.aProximite': 'In der Nähe',
  'businessTabs.catalogues': 'Kataloge',
  'businessTabs.coupons': 'Gutscheine',
  'businessTabs.actualites': 'Nachrichten',
  'catalogsList.titlePartners': 'Kataloge und Partnerkataloge',
  'catalogsList.titleNearby': 'Kataloge von nahegelegenen Unternehmen',
  'couponsList.titlePartners': 'Gutscheine und Partnergutscheine',
  'couponsList.titleNearby': 'Aktionen in der Nähe',
  'businessDetailsContent.owner': 'Eigentümer <b>{fullName}</b>',
  'businessInformation.website': 'Website',
  'businessInformation.availableOffers': '{count} Angebot(e) verfügbar',
  'businessPartners.noPartnerSelected': 'Kein Partner ausgewählt',
  'businessPosts.noPosts': 'Keine Beiträge gefunden',
  'displayBusinessTabs.home': 'Startseite',
  'displayBusinessTabs.nearby': 'In der Nähe',
  'displayBusinessTabs.catalogs': 'Kataloge',
  'displayBusinessTabs.coupons': 'Gutscheine',
  'displayBusinessTabs.posts': 'Nachrichten',
  'horizontalBusinessPartnersList.noPartnerFound': 'Kein Partner gefunden',
  'partnerCatalogs.noCatalogFound': 'Kein Katalog gefunden',
  'partnerCatalogs.searchPlaceholder': 'Nach Partnerkatalogen suchen',
  'businessDetailsContent.noAddressProvided': 'Keine Adresse angegeben',
  'businessDetailsContent.NoSellerNearby': 'Kein Verkäufer in der Nähe',
  'couponsList.searchCouponsPartners': 'Nach Partner für Gutscheine suchen',
} as const;
