export default {
  'businessTabs.aProximite': 'A proximité',
  'businessTabs.catalogues': 'Catalogues',
  'businessTabs.coupons': 'Coupons',
  'businessTabs.actualites': 'Actualités',
  'catalogsList.titlePartners': 'Catalogues et catalogues partenaires',
  'catalogsList.titleNearby': 'Catalogues des commerces à proximité',
  'couponsList.titlePartners': 'Coupons et coupons partenaires',
  'couponsList.titleNearby': 'Promotions à proximité',
  'businessDetailsContent.owner': 'Propriétaire <b>{fullName}</b>',
  'businessInformation.website': 'Site web',
  'businessInformation.availableOffers': '{count} Offre(s) disponible(s)',
  'businessPartners.noPartnerSelected': 'Aucun partenaire sélectionné',
  'businessPosts.noPosts': "Aucune publication n'a été trouvée",
  'displayBusinessTabs.home': 'Accueil',
  'displayBusinessTabs.nearby': 'A proximité',
  'displayBusinessTabs.catalogs': 'Catalogues',
  'displayBusinessTabs.coupons': 'Coupons',
  'displayBusinessTabs.posts': 'Actualités',
  'horizontalBusinessPartnersList.noPartnerFound': 'Aucun partenaire trouvé',
  'partnerCatalogs.noCatalogFound': 'Aucun catalogue trouvé',
  'partnerCatalogs.searchPlaceholder': 'Rechercher des catalogues partenaires',
  'businessDetailsContent.noAddressProvided': 'Aucune addresse donnée',
  'businessDetailsContent.NoSellerNearby': 'Aucun commerce à proximité',
  'couponsList.searchCouponsPartners': 'Rechercher des coupons partenaires',
} as const;
