import React from 'react';

import padNumber from '../../lib/helpers/padNumber';

import differenceInSeconds from 'date-fns/differenceInSeconds';

const CouponSendButtonCounter = ({
  latestSentAt,
  onCountReachesZero,
  secondsToWait,
}: {
  latestSentAt: Date | number;
  onCountReachesZero: () => void;
  secondsToWait: number;
}) => {
  const [counter, setCounter] = React.useState(
    secondsToWait - differenceInSeconds(new Date(), latestSentAt),
  );

  const minutes = React.useMemo(() => {
    return Math.floor(counter / 60);
  }, [counter]);

  const seconds = React.useMemo(() => {
    return counter % 60;
  }, [counter]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      const diff =
        secondsToWait - differenceInSeconds(new Date(), latestSentAt);

      if (diff < 0) {
        onCountReachesZero();
        clearInterval(interval);
      } else {
        setCounter(diff);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [latestSentAt, onCountReachesZero, secondsToWait]);

  return (
    <>
      {padNumber(minutes, 2)}:{padNumber(seconds, 2)}
    </>
  );
};

export default CouponSendButtonCounter;
