import React from 'react';

import {Radio, RadioProps} from '@mui/material';

import {Field, FieldProps} from 'formik';

declare type Props = {name: string; value: any} & RadioProps;

const FormikRadio: React.FC<Props> = ({name, value, ...props}) => {
  return (
    <Field name={name}>
      {({field, form}: FieldProps) => (
        <Radio
          checked={field.value === value}
          onChange={() => {
            form.setFieldValue(name, value);
          }}
          {...props}
        />
      )}
    </Field>
  );
};

export default FormikRadio;
