import {createApi} from '@reduxjs/toolkit/dist/query/react';

import {setCouponSendContact} from '../lib/helpers/storage';

import {parseISO} from 'date-fns';

import {authBaseQuery, baseBaseQuery} from './apiBaseQuery';

export const extraCouponsApi = createApi({
  reducerPath: 'extraCouponsApi',
  baseQuery: authBaseQuery,
  tagTypes: ['Coupon'],
  endpoints(build) {
    return {
      sendCouponB2C: build.mutation<
        unknown,
        {
          contact: string;
          coupon: string;
          geolocation?: {
            longitude: number;
            latitude: number;
          };
        }
      >({
        query: data => ({
          url: `/sendCouponB2C/${data.coupon}`,
          method: 'post',
          data: {
            contact: data.contact,
            geolocation: data.geolocation,
          },
        }),
        async onQueryStarted(arg) {
          setCouponSendContact(arg.contact);
        },
      }),
      sendCouponStaff: build.mutation<
        unknown,
        {
          contact: string;
          coupon: string;
          staff: string;
        }
      >({
        query: data => ({
          url: `/sendCouponStaff/${data.coupon}`,
          method: 'post',
          data: {
            contact: data.contact,
            staff: data.staff,
          },
        }),
        async onQueryStarted(arg) {
          setCouponSendContact(arg.contact);
        },
      }),
      getCouponById: build.query<Coupon & Populate<Coupon, 'business'>, string>(
        {
          query: id => ({
            url: `/getCouponById/${id}`,
            method: 'get',
          }),
          providesTags: (result, error, id) => [{type: 'Coupon', id}],
        },
      ),
      getLatestCouponSendById: build.query<CouponSend | null, string>({
        query: id => ({
          url: `/getLatestCouponSendById/${id}`,
          method: 'get',
        }),
        transformResponse(result: CouponSend | null) {
          if (result) {
            return {
              ...result,
              sentAt: parseISO(result.sentAt as any).getTime(),
            };
          }

          return result;
        },
      }),
    };
  },
});

export const {
  useSendCouponB2CMutation,
  useSendCouponStaffMutation,
  useGetCouponByIdQuery,
  useGetLatestCouponSendByIdQuery,
} = extraCouponsApi;

export const partnersCouponsApi = createApi({
  reducerPath: 'otherCouponsApi',
  baseQuery: baseBaseQuery,
  tagTypes: ['OtherCoupon'],
  endpoints(build) {
    return {
      getPartnersCoupons: build.query<
        PaginateResult<PopulatedCoupon>,
        PaginateParams & {
          business: string;
          orderType: string;
          hideSoldOut: boolean;
          search: string;
        }
      >({
        query: ({business, orderType, hideSoldOut, search}) => ({
          url: '/otherCoupons',
          method: 'get',
          params: {
            business,
            orderType,
            hideSoldOut,
            search,
          },
        }),
        providesTags: result =>
          result
            ? result.data.map(o => ({type: 'OtherCoupon', id: o._id}))
            : [{type: 'OtherCoupon', id: 'LIST'}],
      }),
    };
  },
});

export const businessCouponsApi = createApi({
  reducerPath: 'myCouponsApi',
  baseQuery: baseBaseQuery,
  tagTypes: ['MyCoupon'],
  endpoints(build) {
    return {
      getBusinessCoupons: build.query<
        PaginateResult<PopulatedCoupon>,
        {
          business: string;
          orderType: string;
          expired: boolean;
          hideSoldOut: boolean;
          search: string;
          category: string;
        } & PaginateParams
      >({
        // @ts-ignore
        query: ({
          business,
          orderType,
          $skip,
          $limit,
          expired = false,
          hideSoldOut,
          search,
          category,
        }) => ({
          url: '/myCoupons',
          method: 'get',
          params: {
            business,
            orderType,
            $skip,
            $limit,
            expired,
            hideSoldOut,
            search,
            partners: true,
            category,
          },
        }),
        providesTags: result =>
          result
            ? [
                ...result.data.map(({_id}) => ({
                  type: 'MyCoupon' as const,
                  id: _id,
                })),
                {type: 'MyCoupon', id: 'LIST'},
              ]
            : ['MyCoupon'],
      }),
    };
  },
});

export const staffCouponsApi = createApi({
  reducerPath: 'staffCouponsApi',
  baseQuery: baseBaseQuery,
  tagTypes: ['StaffCoupon'],
  endpoints(build) {
    return {
      getStaffCoupons: build.query<
        PaginateResult<PopulatedCoupon>,
        {
          staff: string;
          orderType: string;
          expired: boolean;
          hideSoldOut: boolean;
          search: string;
        } & PaginateParams
      >({
        // @ts-ignore
        query: ({
          staff,
          orderType,
          $skip,
          $limit,
          expired = false,
          hideSoldOut,
          search,
        }) => ({
          url: `/staffCouponsTypes`,
          method: 'get',
          params: {
            staff,
            orderType,
            $skip,
            $limit,
            expired,
            hideSoldOut,
            search,
          },
        }),
        providesTags: result =>
          result
            ? [
                ...result.data.map(({_id}) => ({
                  type: 'StaffCoupon' as const,
                  id: _id,
                })),
              ]
            : [{type: 'StaffCoupon', id: 'LIST'}],
      }),
    };
  },
});

export const {useGetStaffCouponsQuery} = staffCouponsApi;

export const {useGetPartnersCouponsQuery} = partnersCouponsApi;

export const {useGetBusinessCouponsQuery} = businessCouponsApi;
