import React, {ComponentProps} from 'react';
import {Swiper as LibSwiper} from 'swiper/react';

import {Box} from '@mui/material';

import {A11y, Navigation, Pagination, Scrollbar} from 'swiper';

const Swiper = React.forwardRef(
  (props: ComponentProps<typeof LibSwiper>, ref: any) => (
    <Box
      sx={{
        '& .swiper': {
          width: theme => `calc(100vw - ${theme.spacing(4)})`,
        },
        '& .swiper-pagination': {
          position: 'static',
          padding: theme => theme.spacing(2),
        },
        '& .swiper-pagination-bullet-active': {
          backgroundColor: theme => theme.palette.primary.main,
        },
        '& .swiper-button-prev:after, & .swiper-button-next:after': {
          fontFamily: 'Material Icons',
          color: theme => theme.palette.primary.main,
        },
        '& .swiper-button-prev:after': {
          content: "'chevron_left'",
        },
        '& .swiper-button-next:after': {
          content: "'chevron_right'",
        },
      }}>
      <LibSwiper
        ref={ref}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        {...props}
      />
    </Box>
  ),
);

export default Swiper;
