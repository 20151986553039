import {FormControl, InputLabel, Select, SelectProps} from '@mui/material';

import {Field, FieldProps} from 'formik';
import {FormattedMessage} from '../../../intl';

const FormikSelect = ({
  name,
  label,
  ...props
}: SelectProps & {label: string}) => (
  <Field name={name}>
    {({field}: FieldProps) => (
      <FormControl fullWidth>
        <InputLabel>
          <FormattedMessage id={label} />
        </InputLabel>
        <Select {...field} label={<FormattedMessage id={label} />} {...props} />
      </FormControl>
    )}
  </Field>
);

export default FormikSelect;
