import useNavigateToBusinessPage from '../../helpers/useNavigateToBusinessPage';
import useGeolocation from '../../hooks/use-geolocation';
import {FormattedMessage} from '../../intl';
import BusinessCard from '../molecules/BusinessCard/BusinessCard';
import EmptyPageList from '../organisms/EmptyPageList';
import DataList from './DataList';

const VerticalBusinessesList = ({
  useQuery,
  extraParams,
  categories,
  search,
  orderBy,
  order,
  distance,
}: {
  useQuery: any;
  extraParams?: any;
  categories?: string[];
  search?: string;
  orderBy?: string;
  order?: string;
  distance?: number;
}) => {
  const {position} = useGeolocation();
  const navigateToBusinessPage = useNavigateToBusinessPage();

  return (
    <DataList
      paginationParams={{
        skip: 'skip',
        limit: 'limit',
      }}
      useQuery={useQuery}
      extraQueryParams={{
        ...extraParams,
        search,
        distance,
        userLocation: position
          ? {
              latitude: position?.coords.latitude,
              longitude: position?.coords.longitude,
            }
          : undefined,
        order,
        orderBy,
        categories,
      }}
      emptyList={
        <EmptyPageList
          message={<FormattedMessage id="businessesList.emptyList" />}
        />
      }
      columns={2}
      renderItem={(business: WithCouponsSent<PopulatedBusiness>) => (
        <BusinessCard
          onClick={() => navigateToBusinessPage(business)}
          business={business}
        />
      )}
    />
  );
};

export default VerticalBusinessesList;
