export default {
  'businessesList.labels.title': 'Commerces',
  'businessFilter.allFilters': 'Tous les filtres',
  'businessFilter.sort': 'Tri',
  'businessFilter.use': 'Utiliser:',
  'businessFilter.order': 'Ordre:',
  'businessFilter.distance': 'Distance',
  'businessFilter.distanceValue': '{distance} Kms',
  'businessFilter.asc': 'Croissant',
  'businessFilter.desc': 'Décroissant',
  'businessFilter.relevance': 'Pertinence',
} as const;
