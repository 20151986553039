export default {
  'couponSelection.ALL_PRODUCTS':
    'Applicable sur tous les produits et les services',
  'couponSelection.PRODUCT': 'Applicable sur quelques produits et services',
  'couponSelection.CATEGORY': 'Applicable sur quelques catégories',
  'couponSelection.products_services': '{quantity} produit(s)/service(s)',
  'couponSelection.purchased': '{name} acheté(s)',
  'couponSelection.offered': '{name} offert(s)',
  'googleMaps.openMap': 'Ouvrir sur la carte',
} as const;
