import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {apiBaseQuery} from './apiBaseQuery';

const adsApi = createApi({
  reducerPath: 'AdsApi',
  baseQuery: apiBaseQuery,
  tagTypes: ['Ads'],
  // keepUnusedDataFor: 0,
  endpoints(build) {
    return {
      getAds: build.query<
        any,
        {
          placement: string;
          coordinates: [number, number];
          category?: string;
          $limit: number;
        }
      >({
        query: ({category, placement, coordinates, $limit}) => ({
          url: '/campaigns',
          method: 'get',
          params: {category, placement, coordinates, $limit},
        }),
        providesTags: ['Ads'],
      }),
      getAdByCampaign: build.query({
        query: ({campaign}) => ({
          url: `/campaigns/${campaign}`,
          method: 'get',
        }),
        providesTags: ['Ads'],
      }),
      updateCampaignInsight: build.mutation({
        query: data => ({
          url: '/insights',
          method: 'patch',
          data,
        }),
      }),
    };
  },
});

export const {
  useGetAdsQuery,
  useGetAdByCampaignQuery,
  useLazyGetAdsQuery,
  useUpdateCampaignInsightMutation,
} = adsApi;

export default adsApi;
