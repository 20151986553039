// <----
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import firebaseConfig from '../../firebase.json';

firebase.initializeApp(firebaseConfig);

if (!firebase.auth().currentUser?.isAnonymous) {
  firebase.auth().signInAnonymously();
}

// getAnalytics(app);

export default firebase;
