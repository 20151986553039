import {ComponentProps} from 'react';

import {ButtonBase, Stack, Typography} from '@mui/material';

import BLANK_IMAGE_SQUARE from '../../../../assets/images/blankimage_square.png';

import FirebaseStorageImage from '../../../firebase-image/components/FirebaseStorageImage';
import useAppLanguage from '../../../intl/hooks/use-app-language';
import AspectRatioImage from '../../atoms/AspectRatioImage';

const BusinessPartnerCard = ({
  partner,
  ...props
}: {
  partner: WithCouponsSent<PopulatedBusiness>;
} & ComponentProps<typeof ButtonBase>) => {
  const lang = useAppLanguage();

  return (
    <ButtonBase {...props}>
      <Stack
        sx={{
          border: '1px solid',
          borderColor: 'grey.200',
          position: 'relative',
          flex: 1,
          p: 1,
          mb: 1,
        }}>
        {partner.image ? (
          <FirebaseStorageImage
            Component={AspectRatioImage}
            reference={partner.image.reference}
            ratio="1:1"
          />
        ) : (
          <AspectRatioImage ratio="1:1" src={BLANK_IMAGE_SQUARE} />
        )}
        <Typography align="left" variant="h6">
          {partner.name}
        </Typography>
        <Typography align="left" variant="body1" color="grey.700">
          {partner.category.nameIntl[lang]}
        </Typography>
        <Typography align="left" variant="body2" color="grey.700">
          {partner.descriptionIntl['FR']}
        </Typography>
        <Typography align="left" variant="body2" color="grey.700">
          {partner.address}
        </Typography>
        <Typography align="left" variant="body2" color="grey.700">
          ({partner.couponsCount || 0}) promotions(s)
        </Typography>
      </Stack>
    </ButtonBase>
  );
};
export default BusinessPartnerCard;
