export default {
  'ads.sponsored': 'Patrocinado',
  'businessDetails.promotion': '{count} promoción(es)',
  'couponCard.couponsNotActivatedYet':
    'Los cupones aún no están activados para este socio, estamos enviándoles un correo electrónico para activarlos.',
  'couponCard.couponsLeft': 'Te quedan {quantity} más',
  'catalog.expirationFromTo':
    'desde <b>{startsAt}</b> hasta <b>{expiresAt}</b>',
  'businessCard.couponsAvailable': '{count} cupón(es) disponible(s)',
} as const;
