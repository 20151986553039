import {AddCircle} from '@mui/icons-material';
import {Box, Stack, Typography} from '@mui/material';

import isStandardCoupon from '../../lib/helpers/isStandardCoupon';

import {FormattedMessage} from '../../lib/intl';
import GoogleMapsLink from '../../lib/ui/atoms/GoogleMapsLink';
import ProductCard from '../../lib/ui/molecules/ProductCard/ProductCard';
import CategoriesList from '../../lib/ui/organisms/CategoriesList';
import ProductsSwiper from '../../lib/ui/organisms/ProductsSwiper';

const CouponDescription = ({coupon}: {coupon: PopulatedCoupon}) => {
  if (isStandardCoupon(coupon)) {
    const standardCouponSelection =
      coupon.selection as StandardDiscountSelection;

    const address = coupon.address?.address || coupon.business.address;

    return (
      <>
        <Typography variant="body1">
          <FormattedMessage
            id="couponDescription.reductionBy"
            values={{
              percentage: `${coupon?.amount} ${
                coupon.discountType === 'MONETARY' ? '€' : '%'
              }`,
            }}
          />{' '}
          <FormattedMessage
            id="couponDescription.withAt"
            values={{name: coupon.business.name}}
          />{' '}
          <GoogleMapsLink variant="body1" address={address}>
            {address}
          </GoogleMapsLink>{' '}
          <FormattedMessage
            id={`couponDescription.${standardCouponSelection.type}`}
          />{' '}
        </Typography>
        {standardCouponSelection.type === 'PRODUCT' && (
          <ProductsSwiper
            products={
              standardCouponSelection.products as (Product &
                Populate<Product, 'image'>)[]
            }
          />
        )}
        {standardCouponSelection.type === 'CATEGORY' && (
          <CategoriesList
            categories={
              standardCouponSelection.categories as PopulatedCategory[]
            }
          />
        )}
      </>
    );
  }

  if (coupon.discountType === 'BUY_ONE_GET_ONE_FREE') {
    const bogoSelection = coupon.selection as BuyOneGetOneFreeDiscountSelection;

    return (
      <Stack direction="row" spacing={1} position="relative">
        <Stack direction="column" alignItems="center" spacing={1} flex={1}>
          <Typography variant="h6">
            {bogoSelection.purchased[0].quantity} Acheté(s)
          </Typography>
          <ProductCard
            product={
              bogoSelection.purchased[0].product as Product &
                Populate<Product, 'image'>
            }
          />
        </Stack>
        <Stack direction="column" alignItems="center" spacing={1} flex={1}>
          <Typography variant="h6">
            {bogoSelection.offered[0].quantity} Gratuit(s)
          </Typography>
          <ProductCard
            product={
              bogoSelection.offered[0].product as Product &
                Populate<Product, 'image'>
            }
          />
        </Stack>
        <Box
          sx={{
            inset: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <AddCircle sx={{position: 'relative', left: -4}} fontSize="large" />
        </Box>
      </Stack>
    );
  }

  return null;
};

export default CouponDescription;
