import {Box, Typography} from '@mui/material';

const CouponAmount = ({coupon}: {coupon: Coupon}) => {
  const getCouponAmount = () => {
    if (coupon.discountType === 'PERCENTAGE') {
      return `-${coupon.amount} %`;
    } else {
      return `-${coupon.amount} €`;
    }
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        padding: theme => theme.spacing(0.5),
        paddingRight: theme => theme.spacing(1.1),
        bottom: theme => theme.spacing(3),
        left: theme => theme.spacing(0),
        backgroundColor: theme => theme.palette.primary.main,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
      }}>
      <Typography
        variant="body2"
        sx={{
          color: theme => theme.palette.common.white,
        }}>
        {getCouponAmount()}
      </Typography>
    </Box>
  );
};

export default CouponAmount;
