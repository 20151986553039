export default {
  'acceptBusinessReferral.labels.yourName': 'Dein Name',
  'acceptBusinessReferral.labels.yourFirstName': 'Dein Vorname',
  'acceptBusinessReferral.labels.email': 'E-Mail',
  'acceptBusinessReferral.labels.phoneNumber': 'Telefonnummer',
  'acceptBusinessReferral.labels.yourBusinessName': 'Name deines Unternehmens',
  'acceptBusinessReferral.labels.businessSector': 'Unternehmensbranche',
  'acceptBusinessReferral.button.continue': 'Weiter',
  'acceptBusinessReferral.messages.acceptPhoneNumberEmail':
    'Ich stimme zu, dass meine Telefonnummer oder E-Mail verwendet wird',
  'acceptBusinessReferral.messages.thankYouForRegistration':
    'Vielen Dank für die Registrierung. Um fortzufahren, lade die Anwendung herunter',
  'acceptBusinessReferral.errorMessages.emailAlreadyTaken':
    'E-Mail bereits verwendet',
  'acceptBusinessReferral.errorMessages.phoneNumberAlreadyTaken':
    'Telefonnummer bereits verwendet',
  'acceptBusinessReferral.validationErrors.requiredField': 'Pflichtfeld',
  'acceptBusinessReferral.validationErrors.invalidEmail': 'Ungültige E-Mail',
} as const;
