import {Box, Typography} from '@mui/material';
import {FormattedMessage} from '../../../intl';

const CouponQuantity = ({coupon}: {coupon: Coupon}) => {
  return (
    <Box
      sx={{
        padding: theme => theme.spacing(0.5),
        paddingLeft: theme => theme.spacing(1.1),
        position: 'absolute',
        bottom: theme => theme.spacing(3),
        right: theme => theme.spacing(0),
        backgroundColor: coupon.quantity > 10 ? 'green' : 'red',
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
      }}>
      <Typography
        variant="body2"
        sx={{
          color: theme => theme.palette.common.white,
        }}>
        <FormattedMessage
          id="couponCard.couponsLeft"
          values={{quantity: coupon.quantity}}
        />
      </Typography>
    </Box>
  );
};

export default CouponQuantity;
