import {Search} from '@mui/icons-material';
import {Box} from '@mui/material';
import React from 'react';

import useDebouncedSearch from '../../hooks/useDebouncedSearch';
import {FormattedMessage, useIntl} from '../../intl';
import RoundInput from '../atoms/RoundInput';
import CatalogCard from '../molecules/CatalogCard/CatalogCard';
import TitleSection from '../molecules/TitleSection';
import BusinessesCategoriesScrollableList from './BusinessesCategoriesScrollableList';
import DataList from './DataList';
import EmptyPageList from './EmptyPageList';

const CatalogsList = ({
  title = <FormattedMessage id="catalogsList.titlePartners" />,
  sectionVariant = 'h5',
  useQuery,
  extraParams,
}: {
  title?: string | React.ReactNode;
  useQuery: any;
  extraParams: any;
  sectionVariant?: 'h5' | 'h6';
}) => {
  const intl = useIntl();
  const {search, debouncedSearchChange} = useDebouncedSearch();

  const [category, setCategory] = React.useState('');
  const handleSelectCategory = React.useCallback(
    (id: string) => {
      setCategory(id === category ? '' : id);
    },
    [category],
  );

  return (
    <Box px={1}>
      <TitleSection title={title} variant={sectionVariant}>
        <DataList
          header={
            <>
              <RoundInput
                fullWidth
                onChange={evt => debouncedSearchChange(evt.target.value || '')}
                size="medium"
                placeholder={intl.formatMessage({
                  id: 'catalogsList.searchCatalogs',
                })}
                startAdornment={<Search fontSize="medium" />}
              />
              <BusinessesCategoriesScrollableList
                selectedCategory={category}
                onSelectCategory={handleSelectCategory}
              />
            </>
          }
          emptyList={
            <EmptyPageList
              message={<FormattedMessage id="catalogsList.emptyList" />}
            />
          }
          useQuery={useQuery}
          extraQueryParams={{
            ...extraParams,
            search,
            category,
          }}
          renderItem={(catalog: PopulatedCatalog) => (
            <CatalogCard key={catalog._id} catalog={catalog} />
          )}
        />
      </TitleSection>
    </Box>
  );
};

export default CatalogsList;
