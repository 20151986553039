import {Box, CircularProgress} from '@mui/material';

const LoadingOverlay = () => (
  <Box
    zIndex={1}
    top={1}
    left={1}
    right={1}
    bottom={1}
    display="flex"
    alignItems="center"
    justifyContent="center"
    position="absolute">
    <CircularProgress size={24} />
  </Box>
);

export default LoadingOverlay;
