import {BaseQueryFn} from '@reduxjs/toolkit/query/react';

import firebase from '../lib/app/firebase';

import axios, {AxiosError, AxiosRequestConfig} from 'axios';

import config from '../config.json';

const AxiosBaseQuery =
  (
    {baseUrl, includeAuth}: {baseUrl: string; includeAuth?: boolean} = {
      baseUrl: '',
      includeAuth: false,
    },
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    unknown
  > =>
  async ({url, method, data, params}) => {
    try {
      const headers: AxiosRequestConfig['headers'] = {};
      console.log(
        `${method}:${baseUrl}${url}`,
        method === 'get'
          ? params && JSON.stringify(params)
          : data && JSON.stringify(data),
      );
      if (includeAuth) {
        const idToken = await firebase.auth().currentUser?.getIdToken();
        headers.authorization = `Bearer ${idToken}`;
      }
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
      });

      return {data: result.data};
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const apiBaseQuery = AxiosBaseQuery({
  baseUrl: `${config.baseURL}/api` || '',
});

export const baseBaseQuery = AxiosBaseQuery({
  baseUrl: config.baseURL || '',
  includeAuth: false,
});

export const authBaseQuery = AxiosBaseQuery({
  baseUrl: config.baseURL || '',
  includeAuth: true,
});
