import {Stack, Typography} from '@mui/material';

import LOGO_IMAGE from '../../../assets/images/logo_text.png';
import {FormattedMessage} from '../../intl';

const InternalError = () => (
  <Stack flex={1} alignItems="center" justifyContent="center">
    <img src={LOGO_IMAGE} style={{width: 250, height: 'auto'}} />
    <Typography variant="body1">
      <FormattedMessage id="internalError.content" />
    </Typography>
  </Stack>
);

export default InternalError;
