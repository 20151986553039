import {SwiperSlide} from 'swiper/react';

import useAppLanguage from '../../intl/hooks/use-app-language';
import Swiper from '../atoms/Swiper';
import ImageTitleItem from '../molecules/ImageTitleItem';

const CategoriesList = ({categories}: {categories: PopulatedCategory[]}) => {
  const language = useAppLanguage();

  return (
    <Swiper spaceBetween={50} slidesPerView={2} navigation>
      {categories.map(category => (
        <SwiperSlide key={category._id}>
          <ImageTitleItem
            ratio="1:1"
            key={category._id}
            reference={category.image?.reference}
            title={category.nameIntl[language]}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default CategoriesList;
