import {useState} from 'react';

import {Box, Stack} from '@mui/material';

import {useDebounce} from 'use-debounce';

import {
  DEFAULT_BUSINESSES_LIST_DISTANCE,
  DEFAULT_BUSINESSES_LIST_ORDER,
  DEFAULT_BUSINESSES_LIST_ORDER_BY,
} from '../../../../constants';
import {FormattedMessage} from '../../../intl';
import TitleSection from '../../molecules/TitleSection';
import VerticalBusinessesList from '../VerticalBusinessesList';
import BusinessCategoriesStack from './BusinessCategoriesStack';
import BusinessFilter from './BusinessFilter';
import SearchBarWithFilterButton from './SearchBarWithFilterButton';

const BusinessesList = ({
  useQuery,
  extraParams,
}: {
  useQuery: any;
  extraParams?: any;
}) => {
  const [categories, setCategories] = useState<string[]>([]);
  const [search, setSearch] = useState<string | undefined>();
  const [orderBy, setOrderBy] = useState(DEFAULT_BUSINESSES_LIST_ORDER_BY);
  const [order, setOrder] = useState(DEFAULT_BUSINESSES_LIST_ORDER);
  const [distance, setDistance] = useState<number>(
    DEFAULT_BUSINESSES_LIST_DISTANCE,
  );

  const [visible, setVisible] = useState<boolean>(false);
  const handleClose = () => setVisible(false);

  const [debouncedSearch] = useDebounce(search, 500);

  return (
    <Stack spacing={1}>
      <Box px={1}>
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 100,
            backgroundColor: 'common.white',
            py: 1,
          }}>
          <SearchBarWithFilterButton
            onSearchChange={setSearch}
            onOpenFilter={() => setVisible(true)}
          />
          <BusinessFilter
            order={order}
            orderBy={orderBy}
            distance={distance}
            onClose={handleClose}
            visible={visible}
            onOrderChange={setOrder}
            onOrderByChange={setOrderBy}
            onDistanceChange={setDistance}
          />
          <BusinessCategoriesStack
            selectedCategories={categories}
            onCategorySelect={category =>
              setCategories(old => {
                if (old.includes(category)) {
                  return old.filter(c => c !== category);
                }
                return [...old, category];
              })
            }
          />
        </Box>

        <TitleSection
          title={<FormattedMessage id="businessesList.labels.title" />}>
          <VerticalBusinessesList
            useQuery={useQuery}
            extraParams={extraParams}
            categories={categories}
            search={debouncedSearch}
            orderBy={orderBy}
            order={order}
            distance={distance}
          />
        </TitleSection>
      </Box>
    </Stack>
  );
};

export default BusinessesList;
