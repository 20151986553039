import React, {useEffect} from 'react';
import {useLocation, useParams} from 'react-router-dom';

import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Stack, Tab, Typography} from '@mui/material';

import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import FirebaseStorageImage from '../../lib/firebase-image/components/FirebaseStorageImage';
import AspectRatioImage from '../../lib/ui/atoms/AspectRatioImage';
import GoogleMapsLink from '../../lib/ui/atoms/GoogleMapsLink';
import PhoneNumberLink from '../../lib/ui/atoms/PhoneNumberLink';
import LoadingOverlay from '../../lib/ui/molecules/LoadingOverlay';
import NotFound from '../../lib/ui/organisms/NotFound';

import {useGetCatalogByIdQuery} from '../../redux/catalogsApi';
import {useLoadingEffect} from '../../redux/loaderSlice';

import useAppLanguage from '../../lib/intl/hooks/use-app-language';
import LanguagePicker from '../../lib/language-picker/components/LanguagePicker';
import CatalogCategoryProductList from './CatalogCategoryProductList';
import CatalogDetailsAppBar from './CatalogDetailsAppBar';

const CatalogDetails = () => {
  const {state} = useLocation();
  const lang = useAppLanguage();
  const {id} = useParams();
  const [selectedCategory, setSelectedCategory] = React.useState<string>();

  const query = useGetCatalogByIdQuery(id as string);
  const catalog = query.data;
  const catalogImageReference = catalog?.image?.reference;
  const address = catalog?.address?.address || catalog?.business.address;

  useLoadingEffect(query.isLoading);
  useQueryInternalError(query);

  useEffect(() => {
    if (query.isSuccess) {
      setSelectedCategory(catalog?.categories[0]._id);
    }
  }, [query.isSuccess, catalog?.categories[0]._id]);

  if (query.isError && (query.error as any)?.status === 404) {
    return <NotFound />;
  }

  if (query.isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <Stack pt={4} direction="column" spacing={2}>
      <CatalogDetailsAppBar catalog={catalog} backVisible={state?.goBack} />
      <Box
        sx={{
          paddingTop: !state?.goBack ? '0px' : '5px',
        }}>
        <Stack
          spacing={2}
          sx={{
            py: 2,
            backgroundColor: 'grey.200',
          }}>
          <Box
            sx={{
              alignSelf: 'flex-end',
              px: 2,
            }}>
            <LanguagePicker />
          </Box>
          {catalogImageReference ? (
            <FirebaseStorageImage
              Component={AspectRatioImage}
              reference={catalogImageReference}
              ratio={catalog?.imageAspectRatio}
            />
          ) : null}
        </Stack>
        {query.isLoading && <LoadingOverlay />}
        <Stack spacing={2}>
          <Stack pt={2} px={2} direction="column" spacing={1}>
            <Typography variant="h5" align="left">
              {catalog?.business.name}
            </Typography>
            <Stack spacing={1}>
              <Typography variant="body1" align="left">
                {address}
              </Typography>
              {address && <GoogleMapsLink address={address} />}
            </Stack>
            {catalog?.phoneNumber?.phoneNumber && (
              <PhoneNumberLink
                phoneNumber={catalog?.phoneNumber?.phoneNumber}
              />
            )}
          </Stack>
          {selectedCategory && (
            <TabContext value={selectedCategory}>
              <TabList
                variant="scrollable"
                scrollButtons="auto"
                onChange={(_, newValue) => setSelectedCategory(newValue)}>
                {catalog?.categories.map((category: any) => (
                  <Tab
                    key={category._id}
                    label={category.nameIntl[lang]}
                    value={category._id}
                  />
                ))}
              </TabList>
              {catalog?.categories.map((category: any) => (
                <TabPanel
                  sx={{
                    px: 1,
                    py: 0,
                    mt: 0,
                  }}
                  value={category._id}
                  key={category._id}>
                  {selectedCategory === category._id && (
                    <CatalogCategoryProductList
                      category={category._id}
                      business={catalog.business._id}
                    />
                  )}
                </TabPanel>
              ))}
            </TabContext>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default CatalogDetails;
