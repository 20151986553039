import {useParams} from 'react-router-dom';

import {Box} from '@mui/material';

import CouponsList from '../../lib/ui/organisms/CouponsList';

import {useGetStaffCouponsQuery} from '../../redux/couponApi';

const StaffCoupons = () => {
  const {id} = useParams();

  return (
    <Box px={1} pt={2}>
      <CouponsList
        useQuery={useGetStaffCouponsQuery}
        extraParams={{
          staff: id,
        }}
      />
    </Box>
  );
};

export default StaffCoupons;
