import React from 'react';

import {ButtonBase, Paper, Stack, Typography} from '@mui/material';

import BLANK_IMAGE_RECTANGULAR from '../../../assets/images/blankimage_rectangular.png';
import BLANK_IMAGE_SQUARE from '../../../assets/images/blankimage_square.png';

import FirebaseStorageImage from '../../firebase-image/components/FirebaseStorageImage';
import AspectRatioImage from '../atoms/AspectRatioImage';

const ImageTitleItem = ({
  reference,
  ratio = '1:1',
  subtitle,
  title,
  small = false,
  TitleWrapper = React.Fragment,
  onClick,
}: {
  reference: string;
  title: string;
  subtitle?: string | JSX.Element;
  ratio?: AspectRatio;
  small?: boolean;
  onClick?: () => void;
  TitleWrapper?: React.ComponentType<React.PropsWithChildren>;
}) => (
  <ButtonBase
    sx={{
      width: '100%',
    }}
    onClick={onClick}>
    <Paper square elevation={0} sx={{width: '100%', border: 0}}>
      <Stack
        sx={{
          width: '100%',
          p: 2,
        }}
        direction="column"
        spacing={1}>
        {reference ? (
          <FirebaseStorageImage
            Component={AspectRatioImage}
            reference={reference}
            ratio={ratio}
          />
        ) : (
          <AspectRatioImage
            src={ratio === '1:1' ? BLANK_IMAGE_SQUARE : BLANK_IMAGE_RECTANGULAR}
            ratio={ratio}
          />
        )}
        <TitleWrapper>
          <Typography
            variant={small ? 'body1' : 'h6'}
            align="left"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            {title}
          </Typography>
        </TitleWrapper>
        {subtitle && (
          <Typography align="left" variant="body1">
            {subtitle}
          </Typography>
        )}
      </Stack>
    </Paper>
  </ButtonBase>
);

export default ImageTitleItem;
