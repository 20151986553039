import {useParams} from 'react-router-dom';

import {Box} from '@mui/material';

import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import CatalogsList from '../../lib/ui/organisms/CatalogsList';

import {FormattedMessage} from '../../lib/intl';
import {useGetBusinessByIdQuery} from '../../redux/businessesApi';
import {useGetCatalogsQuery} from '../../redux/catalogsApi';

const BusinessCatalogs = () => {
  const {id} = useParams();
  const query = useGetBusinessByIdQuery(id as string);

  useQueryInternalError(query);

  return (
    <Box pt={2}>
      <CatalogsList
        title={
          query.data?.status === 'ACTIVE' ? (
            <FormattedMessage id="catalogsList.titlePartners" />
          ) : (
            <FormattedMessage id="catalogsList.titleNearby" />
          )
        }
        sectionVariant="h6"
        useQuery={useGetCatalogsQuery}
        extraParams={{
          business: id,
        }}
      />
    </Box>
  );
};

export default BusinessCatalogs;
