import {Button} from '@mui/material';
import {ComponentProps} from 'react';

const ProductCardButton = ({
  ctaLink,
  ctaText,
  ...props
}: ComponentProps<typeof Button> & {
  ctaLink: string;
  ctaText?: string;
}) => (
  <a
    onClick={evt => {
      evt.stopPropagation();
    }}
    href={ctaLink}
    target="_blank">
    <Button variant="contained" color="primary" {...props}>
      {ctaText}
    </Button>
  </a>
);

export default ProductCardButton;
