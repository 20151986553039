import {ButtonBase, Paper, Stack, Typography} from '@mui/material';

import BLANK_IMAGE_SQUARE from '../../../../assets/images/blankimage_square.png';

import FirebaseStorageImage from '../../../firebase-image/components/FirebaseStorageImage';
import {FormattedMessage} from '../../../intl';
import useAppLanguage from '../../../intl/hooks/use-app-language';
import AspectRatioImage from '../../atoms/AspectRatioImage';

const BusinessCard = ({
  business,
  onClick,
}: {
  business: WithCouponsSent<PopulatedBusiness>;
  onClick?: () => void;
}) => {
  const lang = useAppLanguage();
  const businessImage = business.image as AppFile;

  return (
    <Paper variant="outlined">
      <ButtonBase
        sx={{
          width: '100%',
        }}
        onClick={onClick}>
        <Stack
          sx={{
            position: 'relative',
            flex: 1,
            p: 1,
            mb: 1,
          }}
          direction="column"
          spacing={1}
          pb={1}>
          {businessImage ? (
            <FirebaseStorageImage
              Component={AspectRatioImage}
              reference={businessImage.reference}
              ratio="1:1"
            />
          ) : (
            <AspectRatioImage ratio="1:1" src={BLANK_IMAGE_SQUARE} />
          )}
          <Typography align="left" variant="h5">
            {business.name}
          </Typography>
          <Typography align="left" variant="body1" color="grey.700">
            {business.category.nameIntl[lang]}
          </Typography>
          <Typography align="left" variant="body2" color="grey.700">
            {business.address}
          </Typography>
          {business.status === 'ACTIVE' && (
            <Typography align="left" variant="body2" color="grey.700">
              ({business.couponsCount || 0}) coupon(s)
              <FormattedMessage
                id="businessCard.couponsAvailable"
                values={{count: business.couponsCount || 0}}
              />
            </Typography>
          )}
        </Stack>
      </ButtonBase>
    </Paper>
  );
};
export default BusinessCard;
