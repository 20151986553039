export default {
  'common.send': 'Send',
  'common.internalError': 'An error occurred, please try again later',
  'common.save': 'Save',
  'common.yes': 'Yes',
  'common.no': 'No',
  'common.fieldRequired': 'Field required',
  'common.invalidPhone': 'Invalid phone number',
  'common.inValidEmail': 'Invalid email',
  'common.invalidContact': 'Invalid contact',
  'common.emailUsed': 'Email already used',
  'common.phoneUsed': 'Phone number already used',
  'common.safeGuard': 'Save',
  'common.showMore': '...show more',
  'common.showLess': 'show less',
  'common.category': 'Category',
  'common.apply': 'Apply',
  'common.geoLocationNotActivated': 'Geolocation is not supported',
  'common.country': 'Country',
  'common.phoneNumber': 'Phone number',
} as const;
