export default {
  'businessTabs.aProximite': 'Cerca',
  'businessTabs.catalogues': 'Catálogos',
  'businessTabs.coupons': 'Cupones',
  'businessTabs.actualites': 'Noticias',
  'catalogsList.titlePartners': 'Catálogos y catálogos de socios',
  'catalogsList.titleNearby': 'Catálogos de negocios cercanos',
  'couponsList.titlePartners': 'Cupones y cupones de socios',
  'couponsList.titleNearby': 'Promociones cercanas',
  'businessDetailsContent.owner': 'Propietario <b>{fullName}</b>',
  'businessInformation.website': 'Sitio web',
  'businessInformation.availableOffers': '{count} oferta(s) disponible(s)',
  'businessPartners.noPartnerSelected': 'Ningún socio seleccionado',
  'businessPosts.noPosts': 'No se encontraron publicaciones',
  'displayBusinessTabs.home': 'Inicio',
  'displayBusinessTabs.nearby': 'Cerca',
  'displayBusinessTabs.catalogs': 'Catálogos',
  'displayBusinessTabs.coupons': 'Cupones',
  'displayBusinessTabs.posts': 'Noticias',
  'horizontalBusinessPartnersList.noPartnerFound':
    'No se encontró ningún socio',
  'partnerCatalogs.noCatalogFound': 'No se encontró ningún catálogo',
  'partnerCatalogs.searchPlaceholder': 'Buscar catálogos de socios',
  'businessDetailsContent.noAddressProvided': 'No se proporcionó dirección',
  'businessDetailsContent.NoSellerNearby': 'No hay vendedor cercano',
  'couponsList.searchCouponsPartners': 'Buscar socios de cupones',
} as const;
