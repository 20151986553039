import {Chip, Stack} from '@mui/material';
import {useGetAllBusinessCategoriesQuery} from '../../../../redux/businessesApi';
import useAppLanguage from '../../../intl/hooks/use-app-language';

const BusinessCategoriesStack = ({
  selectedCategories,
  onCategorySelect,
}: {
  selectedCategories: string[];
  onCategorySelect: (category: string) => void;
}) => {
  const lang = useAppLanguage();
  const query = useGetAllBusinessCategoriesQuery();

  if (query.data) {
    return (
      <Stack
        direction="row"
        py={2}
        mb={1}
        spacing={1}
        sx={{
          overflowX: 'scroll',
          borderRadius: '2px',
        }}>
        {query.data.map(category => (
          <Chip
            size="small"
            color={
              selectedCategories.includes(category._id) ? 'primary' : 'default'
            }
            onClick={() => onCategorySelect(category._id)}
            key={category._id}
            label={category.nameIntl[lang]}
          />
        ))}
      </Stack>
    );
  }

  return null;
};

export default BusinessCategoriesStack;
