import {useNavigate} from 'react-router-dom';

const useNavigateToBusinessPage = () => {
  const navigate = useNavigate();

  return (business: Business) => {
    if (business.status !== 'PENDING') {
      navigate(`/business-space/${business._id}/coupons`);
    }
  };
};

export default useNavigateToBusinessPage;
