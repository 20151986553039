export default {
  'couponSelection.ALL_PRODUCTS':
    'Anwendbar auf alle Produkte und Dienstleistungen',
  'couponSelection.PRODUCT':
    'Anwendbar auf einige Produkte und Dienstleistungen',
  'couponSelection.CATEGORY': 'Anwendbar auf einige Kategorien',
  'couponSelection.products_services':
    '{quantity} Produkt(e)/Dienstleistung(en)',
  'couponSelection.purchased': '{name} gekauft',
  'couponSelection.offered': '{name} angeboten',
  'googleMaps.openMap': 'Auf Karte öffnen',
} as const;
