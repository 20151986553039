import {Box, Typography} from '@mui/material';
import ReactHtmlParser from 'html-react-parser';

const HtmlParser = ({children}: {children: string}) => {
  return (
    <Box maxWidth="100%">
      {ReactHtmlParser(children, {
        transform: (node: any, domNode, index) => {
          // Check if the node is an element
          if (domNode.type === 'tag') {
            console.log(domNode.name, domNode);

            switch (domNode.name) {
              case 'h4':
              case 'h5':
              case 'h6':
                // Example: wrap these headers in a div with a custom class
                return (
                  <Typography key={index} variant={domNode.name}>
                    {node.props.children}
                  </Typography>
                );
              case 'p':
                if (
                  domNode.children.length === 1 &&
                  domNode.children[0].type === 'tag' &&
                  domNode.children[0].name === 'br'
                ) {
                  return <br key={index} />;
                }

                return (
                  <Typography key={index} variant="body1">
                    {node.props.children}
                  </Typography>
                );
              case 'b':
              case 'strong':
                // Transform bold tags, e.g., wrap with a custom component or style
                return (
                  <Typography
                    key={index}
                    component="span"
                    sx={{
                      fontWeight: 'bold',
                    }}
                    variant="body1">
                    {node}
                  </Typography>
                );
              case 'i':
                // Transform italic tags
                return (
                  <Typography
                    key={index}
                    component="span"
                    sx={{
                      fontWeight: 'bold',
                    }}
                    variant="body1">
                    {node}
                  </Typography>
                );
              case 'ul':
              case 'ol':
                return <ul key={index}>{node.props.children}</ul>;
              case 'li':
                return <li key={index}>{node.props.children}</li>;
              case 'br':
                return <br key={index} />;
              case 'a':
                return (
                  <Typography
                    key={index}
                    target="_blank"
                    href={domNode.attribs.href}
                    component="a"
                    variant="body1">
                    {node}
                  </Typography>
                );
              default:
                return node;
            }
          }

          return node;
        },
      })}
    </Box>
  );
};

export default HtmlParser;
