import {Public} from '@mui/icons-material';
import {Box, Button, Chip, Paper, Stack, Typography} from '@mui/material';
import React from 'react';
import getLineBreakHTMLProp from '../../lib/helpers/getLineBreakHTMLProp';
import {FormattedMessage} from '../../lib/intl';
import useAppLanguage from '../../lib/intl/hooks/use-app-language';

const CLAMP_STYLE = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '10',
  WebkitBoxOrient: 'vertical',
  overflowWrap: 'break-word',
};

const BusinessInformation = ({
  business,
}: {
  business: WithImage<WithCouponsSent<PopulatedBusiness>, 'image'>;
}) => {
  const lang = useAppLanguage();

  const [clamped, setClamped] = React.useState<any>(CLAMP_STYLE);
  return (
    <Box
      sx={{
        backgroundColor: 'grey.100',
        paddingTop: '25px',
        paddingBottom: '10px',
      }}>
      <Paper variant="outlined" square>
        <Stack
          spacing={1}
          sx={{
            position: 'relative',
            width: '100%',
            p: 1,
            mb: 1,
            backgroundColor: 'common.white',
          }}>
          <Stack
            sx={{
              '& a': {
                textDecoration: 'none',
                color: 'black',
                '&:hover': {
                  textDecoration: 'underline',
                },
              },
            }}
            alignItems="flex-start"
            flex={2}
            direction="column"
            spacing={1}>
            <Typography align="left" variant="body1" color="black">
              {business.address}
            </Typography>
            {business.website && (
              <a href={business.website} target="_blank">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Public />
                  <Typography align="left" variant="body1" color="black">
                    <FormattedMessage id="businessInformation.website" />
                  </Typography>
                </Stack>
              </a>
            )}
            {business.descriptionIntl?.[lang] && (
              <>
                <Typography
                  align="left"
                  variant="body1"
                  color="black"
                  {...getLineBreakHTMLProp(business.descriptionIntl[lang])}
                  sx={clamped}
                />
                {business.descriptionIntl[lang].length > 150 && (
                  <Button
                    size="small"
                    variant="text"
                    onClick={() =>
                      setClamped(Object.keys(clamped).length ? {} : CLAMP_STYLE)
                    }>
                    <FormattedMessage
                      id={`common.${
                        Object.keys(clamped).length ? 'showMore' : 'showLess'
                      }`}
                    />
                  </Button>
                )}
              </>
            )}
            {business.status === 'ACTIVE' && (
              <Stack
                alignSelf="stretch"
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between">
                <Chip
                  size="small"
                  variant="outlined"
                  color="primary"
                  label={business.category.nameIntl[lang]}
                />
                <Typography align="left" variant="body2">
                  <FormattedMessage
                    id="businessInformation.availableOffers"
                    values={{count: business.couponsCount || 0}}
                  />
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
};

export default BusinessInformation;
