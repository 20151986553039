import useAppLanguage from '../../lib/intl/hooks/use-app-language';
import TextAppBar from '../../lib/ui/molecules/TextAppBar';

const CatalogDetailsAppBar = ({
  catalog,
  backVisible = false,
}: {
  catalog?: PopulatedCatalog;
  backVisible?: boolean;
}) => {
  const language = useAppLanguage();

  return (
    <TextAppBar
      text={catalog?.nameIntl[language] || ''}
      backVisible={backVisible}
    />
  );
};

export default CatalogDetailsAppBar;
