import React from 'react';
import {Outlet, useNavigate} from 'react-router-dom';

import firebase from '../../lib/app/firebase';

const AuthRoute = () => {
  const [authenticated, setAuthenticated] = React.useState<boolean | undefined>(
    undefined,
  );
  const navigate = useNavigate();

  React.useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        navigate('/');
      } else {
        setAuthenticated(false);
      }
    });

    return () => unsub();
  }, [navigate]);

  if (authenticated === false) {
    return <Outlet />;
  }

  return null;
};

export default AuthRoute;
