import {useParams} from 'react-router-dom';

import {Search} from '@mui/icons-material';
import {Box, Input} from '@mui/material';

import useDebouncedSearch from '../../lib/hooks/useDebouncedSearch';

import CatalogCard from '../../lib/ui/molecules/CatalogCard/CatalogCard';
import DataList from '../../lib/ui/organisms/DataList';
import EmptyPageList from '../../lib/ui/organisms/EmptyPageList';

import {FormattedMessage, useIntl} from '../../lib/intl';
import {useGetPartnersCatalogsQuery} from '../../redux/catalogsApi';

const PartnersCatalogs = () => {
  const {id} = useParams();
  const intl = useIntl();
  const {search, debouncedSearchChange} = useDebouncedSearch();

  return (
    <DataList
      header={
        <Box m={1}>
          <Input
            sx={{
              py: 1,
            }}
            fullWidth
            onChange={evt => debouncedSearchChange(evt.target.value || '')}
            size="medium"
            placeholder={intl.formatMessage({
              id: 'partnerCatalogs.searchPlaceholder',
            })}
            startAdornment={<Search fontSize="medium" />}
          />
        </Box>
      }
      emptyList={
        <EmptyPageList
          message={<FormattedMessage id="partnerCatalogs.noCatalogFound" />}
        />
      }
      useQuery={useGetPartnersCatalogsQuery}
      extraQueryParams={{
        business: id,
        search,
      }}
      renderItem={(catalog: PopulatedCatalog) => (
        <CatalogCard key={catalog._id} catalog={catalog} />
      )}
    />
  );
};

export default PartnersCatalogs;
