import {useNavigate} from 'react-router-dom';

import {Box, ButtonBase, Paper, Stack, Typography} from '@mui/material';

import format from 'date-fns/format';

import FirebaseStorageImage from '../../../firebase-image/components/FirebaseStorageImage';
import useAppLanguage from '../../../intl/hooks/use-app-language';
import AspectRatioImage from '../../atoms/AspectRatioImage';

const formatDate = (date: Date | number) => format(date, 'd MMMM yyyy - hh:mm');

const PostCard = ({post}: any) => {
  const navigate = useNavigate();
  const lang = useAppLanguage();

  return (
    <Paper variant="outlined">
      <ButtonBase
        sx={{
          width: '100%',
        }}
        onClick={() => {
          navigate(`/posts/${post._id}`, {
            state: {
              goBack: true,
            },
          });
        }}>
        <Stack
          sx={{
            width: '100%',
            p: 2,
          }}
          direction="column"
          spacing={1}>
          <Box
            display="flex"
            flexDirection="row"
            sx={{
              margin: post.media ? '-16px 0 -4px -16px' : '-16px 0 -8px -16px',
            }}>
            <Typography
              variant="h6"
              align="left"
              sx={{
                color: theme => theme.palette.common.white,
                backgroundColor: '#b96d74',
                px: 2,
                ml: 0,
              }}>
              {post.business.name}
            </Typography>
          </Box>
          {post.media && (
            <FirebaseStorageImage
              Component={AspectRatioImage}
              reference={post.media.reference}
              ratio="16:9"
            />
          )}
          <Typography
            variant="h4"
            align="left"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              // paddingTop: post.media ? 0 : 5,
            }}>
            {post.titleIntl[lang]}
          </Typography>
          <Stack direction="column" spacing={1}>
            <Typography align="justify" variant="body1">
              {post.contentIntl[lang]}
            </Typography>
            <Typography align="left" variant="body2" pt={1}>
              {formatDate(new Date(post.createdAt))}
            </Typography>
          </Stack>
          <Typography align="left" variant="body2" color="grey.700">
            {post.category?.nameIntl[lang]}
          </Typography>
          <Typography align="left" variant="body2" color="grey.700">
            {post.business?.address || ''}
          </Typography>
        </Stack>
      </ButtonBase>
    </Paper>
  );
};

export default PostCard;
