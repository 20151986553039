export default {
  'businessesList.emptyList': 'No se encontraron negocios',
  'businessCard.available': 'Disponible',
  'notFound.content': '404 No Encontrado',
  'internalError.content': 'Error Desconocido',
  'sendCoupon.couponSoldout': 'El cupón ya está agotado',
  'sendCoupon.couponSent': 'El cupón se ha enviado correctamente',
  'sendCoupon.couponNotActivated':
    'Este cupón aún no está activado. Por favor, inténtelo de nuevo más tarde',
  'sendCoupon.dailyQuotaExceeded':
    'Ha alcanzado el número máximo de intentos para este cupón hoy',
  'couponsList.searchCoupons': 'Buscar cupones',
  'couponsList.emptyCouponsList': 'No se encontraron cupones',
  'couponsList.defaultTitle': 'Cupones y cupones de socios',
  'activeBusinessTabs.home': 'Inicio',
  'activeBusinessTabs.partners': 'Socios',
  'activeBusinessTabs.catalogs': 'Catálogos',
  'activeBusinessTabs.coupons': 'Cupones',
  'activeBusinessTabs.posts': 'Noticias',
  'userConscent.accept': 'Aceptar',
  'userConscent.dataUsage': 'Uso de datos',
  'unauthorized.accessDenied': 'Acceso denegado',
  'unauthorized.message': 'No tienes acceso a esta página',
  'dashboardFilter.search': 'Buscar',
  'dashboardFilter.filter': 'Filtrar',
  'dashboardFilter.customInterval': 'Intervalo Personalizado',
  'confirmation.description':
    '¿Estás seguro de que deseas confirmar esta acción?',
  'catalogsList.searchCatalogs': 'Buscar catálogos por producto...',
  'catalogsList.emptyList': 'No se encontraron catálogos',
  'dashboardFilter.thisMonth': 'Este mes',
  'dashboardFilter.from': 'Desde',
  'dashboardFilter.to': 'Hasta',
} as const;
