import {ButtonBase, Paper, Stack, Typography} from '@mui/material';
import {useParams} from 'react-router-dom';

import {useGetAd} from '../../../hooks/ads';
import {useUpdateCampaignInsightMutation} from '../../../redux/adsApi';
import FirebaseStorageImage from '../../firebase-image/components/FirebaseStorageImage';
import {FormattedMessage} from '../../intl';
import {AspectRatioImage2} from '../atoms/AspectRatioImage';

const AdCard = ({ad, width = '100%'}: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateCampaignInsight, _mutation] = useUpdateCampaignInsightMutation();
  return (
    <Paper variant="outlined" sx={{width}}>
      <ButtonBase
        sx={{
          width: '100%',
        }}
        onClick={() => {
          if (ad.websiteURL) {
            updateCampaignInsight({
              campaign: ad._id,
              field: 'clicks',
              placement: ad.placement,
              category: ad.category,
            });
            window.open(ad.websiteURL);
          }
        }}>
        <Stack
          sx={{
            width: '100%',
            p: 1,
          }}
          direction="column"
          spacing={1}>
          {ad?.media && (
            <FirebaseStorageImage
              Component={AspectRatioImage2}
              reference={ad?.media}
              ratio={ad?.imageAspectRatio}
            />
          )}
          <Typography variant="body2" align="right" color="grey.400">
            <FormattedMessage id="ads.sponsored" />
          </Typography>
          <Typography
            variant="h5"
            align="left"
            sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
            {ad?.headline}
          </Typography>
          <Typography variant="body1" align="left" color={'GrayText'}>
            {ad.primaryText}
          </Typography>
          <Typography variant="body2" align="left" color={'GrayText'}>
            {ad?.description}
          </Typography>
        </Stack>
      </ButtonBase>
    </Paper>
  );
};

const AdDisplay = ({options, ...props}: any) => {
  const {id} = useParams<any>();
  const {quantity = 1, Wrapper = <></>, isSlider = false} = options;

  const ads = useGetAd(id, quantity);
  const adItems = ads.length
    ? quantity === 1
      ? ads[0]
      : ads.slice(0, 2)
    : null;

  if (!ads.length) {
    return null;
  }

  if (quantity === 1) {
    return <AdCard ad={adItems} />;
  }

  if (!isSlider && quantity === 2) {
    return (
      <Stack direction="row" gap={1}>
        {adItems.map((ad: any) => (
          <AdCard key={ad._id} ad={ad} width="50%" />
        ))}
      </Stack>
    );
  }

  return adItems.map((ad: any) => {
    return (
      <Wrapper {...props} key={ad._id}>
        <AdCard ad={ad} />
      </Wrapper>
    );
  });
};

export default AdDisplay;

AdDisplay.displayName = 'SwiperSlide';
