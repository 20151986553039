import React from 'react';

import {ButtonBase, DialogTitle, Paper, Stack, Typography} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import getLineBreakHTMLProp from '../../../helpers/getLineBreakHTMLProp';

import BLANK_IMAGE_SQUARE from '../../../../assets/images/blankimage_square.png';
import FirebaseStorageImage from '../../../firebase-image/components/FirebaseStorageImage';
import AspectRatioImage from '../../atoms/AspectRatioImage';
import Dialogable from '../Dialogable';
import ProductCardButton from './ProductCardButton';

const ProductCard = ({
  product,
  TitleWrapper = React.Fragment,
}: {
  product: Product & Populate<Product, 'image'>;
  TitleWrapper?: React.ComponentType<React.PropsWithChildren>;
}) => (
  <Dialogable
    source={({onOpen}) => (
      <ButtonBase
        sx={{
          width: '100%',
        }}
        onClick={onOpen}>
        <Paper square elevation={0} sx={{width: '100%', border: 0}}>
          <Stack
            sx={{
              width: '100%',
              p: 2,
            }}
            direction="column"
            spacing={1}>
            {product.image.reference ? (
              <FirebaseStorageImage
                Component={AspectRatioImage}
                reference={product.image.reference}
                ratio="1:1"
              />
            ) : (
              <AspectRatioImage src={BLANK_IMAGE_SQUARE} ratio="1:1" />
            )}
            <TitleWrapper>
              <Typography
                variant="body1"
                align="left"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}>
                {product.name}
              </Typography>
            </TitleWrapper>
            <Typography align="left" variant="body1">
              {`${product.price || 0} €`}
            </Typography>
            {product.ctaEnabled && product.ctaLink && (
              <ProductCardButton
                ctaLink={product.ctaLink}
                ctaText={product.ctaText}
              />
            )}
          </Stack>
        </Paper>
      </ButtonBase>
    )}
    dialog={({open, onClose}) => (
      <Dialog
        sx={{
          '& .MuiDialog-paper': {
            width: 'calc(100vw - 40px)',
          },
        }}
        open={open}
        onClose={onClose}>
        <DialogTitle variant="h5">{product.name}</DialogTitle>
        <DialogContent>
          <Stack spacing={1}>
            <FirebaseStorageImage
              Component={AspectRatioImage}
              reference={product.image?.reference}
              ratio="1:1"
            />
            <Typography align="left" variant="h6">
              {product.price || 0} €
            </Typography>
            {product.description && (
              <Typography
                align="left"
                variant="body1"
                {...getLineBreakHTMLProp(product.description)}
              />
            )}
            {product.ctaEnabled && product.ctaLink && (
              <ProductCardButton
                fullWidth
                ctaLink={product.ctaLink}
                ctaText={product.ctaText}
              />
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    )}
  />
);

export default ProductCard;
