import {Outlet} from 'react-router-dom';

import {AppBar, Box} from '@mui/material';

import ActiveBusinessTabs from '../../lib/ui/organisms/ActiveBusinessTabs';

const StaffPage = () => {
  return (
    <Box>
      <Box
        sx={{
          pb: '50px',
        }}>
        <Outlet />
      </Box>
      <AppBar
        position="fixed"
        color="primary"
        sx={{
          top: 'auto',
          bottom: 0,
          backgroundColor: theme => theme.palette.common.white,
          color: theme => theme.palette.common.black,
        }}>
        <ActiveBusinessTabs />
      </AppBar>
    </Box>
  );
};

export default StaffPage;
