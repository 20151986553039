export default {
  'couponDescription.reductionBy': '<b>{percentage}</b> Discount',
  'couponDescription.withAt': 'at <b>{name}</b> in',
  'couponDescription.ALL_PRODUCTS': 'on all products or services:',
  'couponDescription.PRODUCT': 'on the following products or services:',
  'couponDescription.CATEGORY': 'on the following categories:',
  'couponDescription.enterCouponInfo':
    'Enter your information to receive the coupon',
  'sendCoupon.contactType': 'Contact type?',
  'sendCoupon.labels.phoneNumber': 'Phone',
} as const;
