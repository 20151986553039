import Common from '../../lib/common-intl/es';
import LanguagePicker from '../../lib/language-picker/components/intl/es';
import Atoms from '../../lib/ui/atoms/intl/es';
import Molecules from '../../lib/ui/molecules/intl/es';
import BusinessesList from '../../lib/ui/organisms/BusinessesList/intl/es';
import Organisms from '../../lib/ui/organisms/intl/es';
import AcceptBusinessReferral from '../../pages/AcceptBusinessReferral/intl/es';
import BusinessPage from '../../pages/BusinessPage/intl/es';
import BusinessSections from '../../pages/BusinessSections/intl/es';
import CatalogDetails from '../../pages/CatalogDetails/intl/es';
import Home from '../../pages/Home/intl/es';
import SendCoupon from '../../pages/SendCoupon/intl/es';
import StaffCoupons from '../../pages/StaffCoupons/intl/es';

const ES = {
  ...AcceptBusinessReferral,
  ...BusinessesList,
  ...Organisms,
  ...BusinessPage,
  ...BusinessSections,
  ...CatalogDetails,
  ...Home,
  ...Common,
  ...StaffCoupons,
  ...SendCoupon,
  ...Atoms,
  ...Molecules,
  ...LanguagePicker,
} as const;

export default ES;
