import React from 'react';
import {NotificationManager} from 'react-notifications';

import {internalError} from '../../lib/helpers/intenralError';
import {useIntl} from '../../lib/intl';

const useSendCouponMutationEffect = ({
  mutation,
  ref,
}: {
  mutation: any;
  ref: any;
}) => {
  const intl = useIntl();

  return React.useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success(
        intl.formatMessage({id: 'sendCoupon.couponSent'}),
      );
      ref.current.fetchLatestCouponSend();
    }

    if (mutation.isError) {
      const errorCode = (mutation.error as any)?.data?.data?.error?.code;

      if (errorCode === 'COUPON_QTY_IS_ZERO') {
        NotificationManager.error(
          intl.formatMessage({id: 'sendCoupon.couponSoldout'}),
        );
      } else if (errorCode === 'COUPON_SENDS_DAILY_QUOTA_EXCEEDED') {
        NotificationManager.error(
          intl.formatMessage({id: 'sendCoupon.dailyQuotaExceeded'}),
        );
      } else if (errorCode === 'INVALID_SUBSCRIPTION') {
        NotificationManager.error(
          intl.formatMessage({id: 'sendCoupon.couponNotActivated'}),
        );
      } else {
        internalError(mutation.error);
      }
    }
  }, [mutation.isSuccess, mutation.isError, mutation.error, ref]);
};

export default useSendCouponMutationEffect;
