import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate} from 'react-router-dom';

import {Box, ButtonBase, Paper, Stack, Typography} from '@mui/material';

import isStandardCoupon from '../../../helpers/isStandardCoupon';

import {useIntl} from '../../../intl';
import useAppLanguage from '../../../intl/hooks/use-app-language';
import CouponSelection from '../../atoms/CouponSelection';
import GoogleMapsLink from '../../atoms/GoogleMapsLink';
import PhoneNumberLink from '../../atoms/PhoneNumberLink';
import CouponAmount from './CouponAmount';
import CouponCoverImage from './CouponCoverImage';
import CouponQuantity from './CouponQuantity';

const CouponCard: React.FC<{
  coupon: PopulatedCoupon;
  addressVisible?: boolean;
  canSend?: boolean;
  sendCouponURL?: (id: string) => string;
}> = ({
  coupon,
  canSend = true,
  addressVisible = false,
  sendCouponURL = id => `/send-coupon/${id}`,
}) => {
  const navigate = useNavigate();
  const business = coupon.business as PopulatedBusiness;

  const language = useAppLanguage();
  const intl = useIntl();

  const openCouponDetails = () => {
    if (!canSend) {
      return NotificationManager.error(
        intl.formatMessage({id: 'couponCard.couponsNotActivatedYet'}),
      );
    }
    navigate(sendCouponURL(coupon._id), {
      state: {
        goBack: true,
      },
    });
  };

  const address = coupon.address?.address || business.address;

  return (
    <ButtonBase
      sx={{flexDirection: 'column', alignItems: 'stretch', mb: 1}}
      onClick={openCouponDetails}>
      <Paper variant="outlined">
        <Stack
          direction="column"
          spacing={1}
          sx={{
            cursor: 'pointer',
            position: 'relative',
          }}>
          <Stack direction="column" pb={1}>
            <Stack direction="row">
              <Typography
                variant="h6"
                align="left"
                sx={{
                  color: theme => theme.palette.common.white,
                  backgroundColor: '#b96d74',
                  px: 2,
                }}>
                {coupon.business.name}
              </Typography>
            </Stack>
            <Box position="relative" mt={'4px'}>
              <CouponCoverImage coupon={coupon} />
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  top: 0,
                  right: 0,
                  left: 0,
                  backgroundColor: 'rgba(0, 0, 0, .15)',
                  zIndex: 1,
                }}>
                <CouponQuantity coupon={coupon} />
                {isStandardCoupon(coupon) && <CouponAmount coupon={coupon} />}
              </Box>
            </Box>
            <Stack direction="column" spacing={1} px={2} pt={1}>
              <Typography variant="h6" align="left">
                {coupon.nameIntl[language]}
              </Typography>
              <Typography variant="body1" align="left" color={'GrayText'}>
                <CouponSelection coupon={coupon} />
              </Typography>
              {(addressVisible || address) && (
                <Stack direction="column" spacing={1}>
                  <Typography variant="body2" align="left" color="grey.700">
                    {coupon?.category?.nameIntl[language] || ''}
                  </Typography>
                  <Typography variant="body2" align="left">
                    {address}
                  </Typography>
                  <GoogleMapsLink address={address} />
                </Stack>
              )}
              {coupon.phoneNumber?.phoneNumber && (
                <PhoneNumberLink
                  phoneNumber={coupon.phoneNumber?.phoneNumber}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </ButtonBase>
  );
};
export default CouponCard;
