export default {
  'common.send': 'Enviar',
  'common.internalError':
    'Se ha producido un error, por favor inténtelo de nuevo más tarde',
  'common.save': 'Guardar',
  'common.yes': 'Sí',
  'common.no': 'No',
  'common.fieldRequired': 'Campo requerido',
  'common.invalidPhone': 'Número de teléfono no válido',
  'common.inValidEmail': 'Correo electrónico no válido',
  'common.invalidContact': 'Contacto no válido',
  'common.emailUsed': 'Correo electrónico ya utilizado',
  'common.phoneUsed': 'Número de teléfono ya utilizado',
  'common.safeGuard': 'Guardar',
  'common.showMore': '...mostrar más',
  'common.showLess': 'mostrar menos',
  'common.category': 'Categoría',
  'common.apply': 'Aplicar',
  'common.geoLocationNotActivated': 'La geolocalización no está activada',
  'common.country': 'País',
  'common.phoneNumber': 'Número de teléfono',
} as const;
