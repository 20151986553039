export default {
  'acceptBusinessReferral.labels.yourName': 'Your name',
  'acceptBusinessReferral.labels.yourFirstName': 'Your first name',
  'acceptBusinessReferral.labels.email': 'Email',
  'acceptBusinessReferral.labels.phoneNumber': 'Phone number',
  'acceptBusinessReferral.labels.yourBusinessName': 'Your business name',
  'acceptBusinessReferral.labels.businessSector': 'Business sector',
  'acceptBusinessReferral.button.continue': 'Continue',
  'acceptBusinessReferral.messages.acceptPhoneNumberEmail':
    'I accept that my phone number or email be used',
  'acceptBusinessReferral.messages.thankYouForRegistration':
    'Thank you for registering. To continue, download the application',
  'acceptBusinessReferral.errorMessages.emailAlreadyTaken':
    'Email already in use',
  'acceptBusinessReferral.errorMessages.phoneNumberAlreadyTaken':
    'Phone number already in use',
  'acceptBusinessReferral.validationErrors.requiredField': 'Required field',
  'acceptBusinessReferral.validationErrors.invalidEmail': 'Invalid email',
} as const;
