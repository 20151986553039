import {Stack, Typography} from '@mui/material';

import AspectRatioImage from '../../lib/ui/atoms/AspectRatioImage';

import FirebaseStorageImage from '../../lib/firebase-image/components/FirebaseStorageImage';
import useAppLanguage from '../../lib/intl/hooks/use-app-language';
import HtmlParser from '../../lib/ui/atoms/HtmlParser';

const BusinessInformationSectionBlockCard = ({
  block,
}: {
  block: PopulatedBusinessInformationSectionBlock;
}) => {
  const lang = useAppLanguage();

  return (
    <Stack spacing={1} px={1} pt={1} pb={2} width="100%">
      {block?.image && (
        <FirebaseStorageImage
          reference={block.image.reference}
          Component={AspectRatioImage}
          ratio={block.aspectRatio}
        />
      )}
      {block.headingIntl?.[lang] && (
        <Typography variant="h6" align="left">
          {block.headingIntl[lang]}
        </Typography>
      )}
      {block.contentIntl?.[lang] && (
        <Typography
          sx={{
            '& h4, & h5, & h6': {
              marginTop: theme => theme.spacing(1),
              marginBottom: theme => theme.spacing(1),
            },
            '& p': {
              marginTop: theme => theme.spacing(1),
              marginBottom: theme => theme.spacing(1),
            },
            '& ul, & ol': {
              marginTop: theme => theme.spacing(1),
              marginBottom: theme => theme.spacing(1),
            },
          }}
          component="div"
          variant="body1"
          align="left">
          <HtmlParser>{block.contentIntl[lang]}</HtmlParser>
        </Typography>
      )}
    </Stack>
  );
};

export default BusinessInformationSectionBlockCard;
