import {FormatXMLElementFn, PrimitiveType} from 'intl-messageformat';
import React from 'react';
import {useIntl as useLibIntl} from 'react-intl';
import type {FrIntlKeys} from '../../app/intl/fr';
import DE_FLAG from '../../assets/images/de_flag.png';
import ES_FLAG from '../../assets/images/es_flag.png';
import FR_FLAG from '../../assets/images/fr_flag.png';
import EN_FLAG from '../../assets/images/us_flag.png';

export const supportedLanguagesMap = {
  FR: 'Français',
  EN: 'English',
  ES: 'Spanish',
  // IT: 'Italien',
  DE: 'Deutsh',
} as const;

export type LanguageCode = keyof typeof supportedLanguagesMap;

export const supportedLanguages = Object.keys(supportedLanguagesMap)
  .map(key => ({
    code: key,
    name: supportedLanguagesMap[key as LanguageCode],
  }))
  .sort((a, b) => a.name.localeCompare(b.name));

type FormatMessageValuesType = Record<
  string,
  PrimitiveType | FormatXMLElementFn<string, string>
>;
type FormatMessageType = {id: FrIntlKeys; values?: FormatMessageValuesType};

export const defaultRichTextElements = {
  a: (chunks: React.ReactNode[]) => <a>{chunks}</a>,
  b: (chunks: React.ReactNode[]) => <b>{chunks}</b>,
  br: () => <br />,
  p: (chunks: React.ReactNode[]) => <p>{chunks}</p>,
};

export const useIntl = () => {
  const intl = useLibIntl();

  return {
    ...intl,
    formatMessage: ({id, values}: FormatMessageType) =>
      intl.formatMessage({id}, values),
  };
};

export const FormattedMessage = (props: FormatMessageType) => {
  const intl = useIntl();

  return <>{intl.formatMessage(props)}</>;
};

export const DEFAULT_BROWSER_LANGUAGE = (() => {
  const defaultLanguage = 'EN';

  const language = navigator.language
    .split('-')[0]
    .toUpperCase() as LanguageCode;

  return supportedLanguagesMap[language] ? language : defaultLanguage;
})() as LanguageCode;

export const languageFlags = {
  DE: DE_FLAG,
  EN: EN_FLAG,
  ES: ES_FLAG,
  FR: FR_FLAG,
} as const;
