import {createApi} from '@reduxjs/toolkit/dist/query/react';

import providesTagsFromListResult from '../lib/helpers/providesTagsFromListResult';
import providesTagsFromOneResult from '../lib/helpers/providesTagsFromOneResult';

import {authBaseQuery} from './apiBaseQuery';

export type SuperUserForm = Pick<
  SuperUser,
  'firstName' | 'lastName' | 'email' | 'role' | 'parent'
> & {password?: string};

export const superuserApi = createApi({
  reducerPath: 'superuserApi',
  baseQuery: authBaseQuery,
  tagTypes: ['SuperUser', 'SuperUserStats'],
  endpoints(build) {
    return {
      deactivateSuperuser: build.mutation({
        query: args => ({
          url: `/deactivateSuperuser/${args.id}`,
          method: 'patch',
        }),
        invalidatesTags: result =>
          result
            ? [
                {id: result._id, type: 'SuperUser'},
                {id: 'LIST', type: 'SuperUser'},
              ]
            : [{id: 'LIST', type: 'SuperUser'}],
      }),
      enableSuperuser: build.mutation({
        query: args => ({
          url: `/enableSuperuser/${args.id}`,
          method: 'patch',
        }),
        invalidatesTags: result =>
          result
            ? [
                {id: result._id, type: 'SuperUser'},
                {id: 'LIST', type: 'SuperUser'},
              ]
            : [{id: 'LIST', type: 'SuperUser'}],
      }),
      createSuperuser: build.mutation<SuperUser, SuperUserForm>({
        query: data => ({
          url: `/createSuperuser`,
          method: 'post',
          data,
        }),
        invalidatesTags: result =>
          result
            ? [
                {id: result._id, type: 'SuperUser'},
                {id: 'LIST', type: 'SuperUser'},
              ]
            : [{id: 'LIST', type: 'SuperUser'}],
      }),
      updateSuperuser: build.mutation<SuperUser, SuperUserForm & {id: string}>({
        query: data => ({
          url: `/updateSuperuserProfile/${data.id}`,
          method: 'put',
          data,
        }),
        invalidatesTags: result =>
          result
            ? [
                {id: result._id, type: 'SuperUser'},
                {id: 'LIST', type: 'SuperUser'},
              ]
            : [{id: 'LIST', type: 'SuperUser'}],
      }),
      updateSuperuserPassword: build.mutation<
        SuperUser,
        {id: string; password: string}
      >({
        query: data => ({
          url: `/updateSuperuserPassword/${data.id}`,
          method: 'patch',
          data,
        }),
      }),
      updateSuperuserParent: build.mutation<
        SuperUser,
        Pick<SuperUser, 'parent'> & {id: string}
      >({
        query: data => ({
          url: `/updateSuperuserParent/${data.id}`,
          method: 'patch',
          data: {
            parent: data.parent,
          },
        }),
        invalidatesTags: result =>
          result
            ? [
                {id: result._id, type: 'SuperUser'},
                {id: 'LIST', type: 'SuperUser'},
              ]
            : [{id: 'LIST', type: 'SuperUser'}],
      }),
      getSuperusers: build.query<PaginateResult<SuperUser>, PaginateParams>({
        query: params => ({
          url: '/getSuperusers',
          method: 'get',
          params,
        }),
        providesTags: providesTagsFromListResult('SuperUser'),
      }),
      getSuperuserById: build.query<SuperUser, string>({
        query: id => ({
          url: `/getSuperuserById/${id}`,
          method: 'get',
        }),
        providesTags: result =>
          result
            ? [
                {
                  type: 'SuperUser' as const,
                  id: result._id,
                },
                {type: 'SuperUser', id: 'LIST'},
              ]
            : [{type: 'SuperUser', id: 'LIST'}],
      }),
      getSuperuserDashboard: build.query<
        BusinessStats,
        {
          filterType: string;
          id: string;
          date1?: Date;
          date2?: Date;
        }
      >({
        query: params => ({
          url: `/getSuperuserStats/${params.id}`,
          method: 'get',
          params: {
            filterType: params.filterType,
            date1: params.date1 ? params.date1 : undefined,
            date2: params.date2 ? params.date2 : undefined,
          },
        }),
        providesTags: providesTagsFromOneResult('SuperUserStats'),
      }),
      getSalesUsers: build.query<PaginateResult<SuperUser>, PaginateParams>({
        query: params => ({
          url: '/getSalesUsers',
          method: 'get',
          params,
        }),
        providesTags: providesTagsFromListResult('SuperUser'),
      }),
      getHeadManagers: build.query<PaginateResult<SuperUser>, PaginateParams>({
        query: params => ({
          url: '/getHeadManagers',
          method: 'get',
          params,
        }),
        providesTags: providesTagsFromListResult('SuperUser'),
      }),
      getManagers: build.query<PaginateResult<SuperUser>, PaginateParams>({
        query: params => ({
          url: '/getManagers',
          method: 'get',
          params,
        }),
        providesTags: providesTagsFromListResult('SuperUser'),
      }),
    };
  },
});

export const {
  useGetSuperusersQuery,
  useGetSalesUsersQuery,
  useGetHeadManagersQuery,
  useGetManagersQuery,
} = superuserApi;

export default superuserApi;
