import Common from '../../lib/common-intl/fr';
import LanguagePicker from '../../lib/language-picker/components/intl/fr';
import Atoms from '../../lib/ui/atoms/intl/fr';
import Molecules from '../../lib/ui/molecules/intl/fr';
import BusinessesList from '../../lib/ui/organisms/BusinessesList/intl/fr';
import Organisms from '../../lib/ui/organisms/intl/fr';
import AcceptBusinessReferral from '../../pages/AcceptBusinessReferral/intl/fr';
import BusinessPage from '../../pages/BusinessPage/intl/fr';
import BusinessSections from '../../pages/BusinessSections/intl/fr';
import CatalogDetails from '../../pages/CatalogDetails/intl/fr';
import Home from '../../pages/Home/intl/fr';
import SendCoupon from '../../pages/SendCoupon/intl/fr';
import StaffCoupons from '../../pages/StaffCoupons/intl/fr';
import EN from './en';

const FR = {
  ...AcceptBusinessReferral,
  ...BusinessesList,
  ...Organisms,
  ...BusinessPage,
  ...BusinessSections,
  ...CatalogDetails,
  ...Home,
  ...Common,
  ...StaffCoupons,
  ...SendCoupon,
  ...Atoms,
  ...Molecules,
  ...LanguagePicker,
} as const;

export type FrIntlKeys = keyof typeof FR | keyof typeof EN | string;

export default FR;
