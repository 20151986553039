import React from 'react';

const useAppStorage = (key: string): [string, (val: string) => void] => {
  const [item, setItem] = React.useState(localStorage.getItem(key) || '');

  const updateItem = React.useCallback(
    (val: string) => {
      localStorage.setItem(key, val);
      setItem(val);
    },
    [key],
  );

  React.useMemo(() => {
    setItem(localStorage.getItem(key) || '');
  }, [key]);

  return [item, updateItem];
};

export default useAppStorage;
