import {Stack, Typography} from '@mui/material';
import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useJsApiLoader,
} from '@react-google-maps/api';
import React from 'react';

import STORE_IMAGE from '../../assets/images/store.png';
import config from '../../config.json';
import {FormattedMessage} from '../../lib/intl';
import {useGetNearBusinessesQuery} from '../../redux/businessesApi';

const Home = () => {
  const {isLoaded} = useJsApiLoader({
    id: 'react-google-map-js',
    googleMapsApiKey: config.googleMapsApi,
  });
  const [map, setMap] = React.useState<any>();
  const [currentLocation, setCurrentLocation] = React.useState<any>({
    lat: 48.8566,
    lng: 2.3522,
  });
  const [showInfoWindow, setShowInfoWindow] = React.useState<
    number | undefined
  >();

  const query = useGetNearBusinessesQuery(
    currentLocation,
    // {skip: !currentLocation},
  );

  React.useEffect(() => {
    if (map) {
      if (query.isSuccess && query?.data?.locations?.length) {
        const bounds = new window.google.maps.LatLngBounds();
        // eslint-disable-next-line array-callback-return
        query?.data?.locations?.map((location: any) => {
          bounds.extend({
            lat: location?.location?.coordinates[1],
            lng: location?.location?.coordinates[0],
          });
        });
        map.fitBounds(bounds);
      }
    }
  }, [isLoaded, map, query?.data?.locations, query.isSuccess]);

  const onLoad = React.useCallback((map: any) => {
    if (navigator.geolocation) {
      navigator?.geolocation.getCurrentPosition(
        ({coords: {latitude: lat, longitude: lng}}) => {
          setMap(map);
          setCurrentLocation({lat, lng});
        },
      );
    }
  }, []);

  const onUnmount = React.useCallback(() => {
    setMap(null);
  }, []);

  const loadMore = React.useCallback(() => {
    if (map) {
      const _currentLocation = map.getCenter();
      if (_currentLocation) {
        setCurrentLocation({..._currentLocation.toJSON()});
      }
    }
  }, [map]);

  return (
    <Stack
      ml={1}
      mr={1}
      mt={1}
      boxShadow={1}
      overflow={'hidden'}
      alignItems="center"
      borderRadius={1}
      minHeight={'98vh'}>
      <Typography variant="h1" mb={5} mt={5}>
        <FormattedMessage id="home.nearbyBusinesses" />
      </Typography>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{width: '100%', height: '100vh'}}
          center={{lat: currentLocation?.lat, lng: currentLocation?.lng}}
          zoom={8}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onDragEnd={loadMore}
          onZoomChanged={loadMore}>
          {query?.data?.locations?.map((location: any, index: number) => (
            <MarkerF
              position={{
                lat: location?.location?.coordinates[1],
                lng: location?.location?.coordinates[0],
              }}
              icon={STORE_IMAGE}
              key={index}
              onMouseOver={() => setShowInfoWindow(index)}
              onMouseOut={() => setShowInfoWindow(undefined)}
              onClick={() =>
                setShowInfoWindow(showInfoWindow === index ? undefined : index)
              }>
              {showInfoWindow === index && (
                <InfoWindowF>
                  <>
                    <Typography variant="h4">{location.name}</Typography>
                    <Typography variant="body1" color="grey.700">
                      {location.category.name}
                    </Typography>
                    <Typography variant="body2" color="grey.700">
                      {location.address}
                    </Typography>
                  </>
                </InfoWindowF>
              )}
            </MarkerF>
          ))}
        </GoogleMap>
      )}
      {query?.isLoading && (
        <Typography>
          <FormattedMessage id="home.loading" />
        </Typography>
      )}
    </Stack>
  );
};

export default React.memo(Home);
