import {Box, CircularProgress, Stack} from '@mui/material';
import {useCallback, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useParams} from 'react-router-dom';

import {FormattedMessage} from '../../lib/intl';
import AdDisplay from '../../lib/ui/molecules/AdDisplay';
import PostCard from '../../lib/ui/molecules/PostCard/PostCard';
import BusinessesCategoriesScrollableList from '../../lib/ui/organisms/BusinessesCategoriesScrollableList';
import {ActivityIndicator, EndMessage} from '../../lib/ui/organisms/DataList';
import EmptyPageList from '../../lib/ui/organisms/EmptyPageList';
import {useGetPostsQuery} from '../../redux/postsApi';

const BusinessPosts = () => {
  const {id}: any = useParams();
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState('');

  const query = useGetPostsQuery(
    {business: id, page, category},
    {skip: !id, refetchOnMountOrArgChange: true},
  );
  const hasMore =
    query.isSuccess && query.data?.total > query.data?.data.length;

  const handleSelectCategory = useCallback(
    (id: string) => {
      setCategory(id === category ? '' : id);
    },
    [category],
  );

  return (
    <Box p={1}>
      <BusinessesCategoriesScrollableList
        selectedCategory={category}
        onSelectCategory={handleSelectCategory}
      />
      <Stack position="relative">
        {query.isLoading && <CircularProgress sx={{alignSelf: 'center'}} />}
        {query.isSuccess && query.data?.data.length === 0 && (
          <Box position="absolute" sx={{pointerEvents: 'none', inset: 0}}>
            <EmptyPageList
              message={<FormattedMessage id="businessPosts.noPosts" />}
            />
          </Box>
        )}
        <InfiniteScroll
          dataLength={query.data?.length || 0} //This is important field to render the next data
          next={() => {
            if (query.data && query.data?.limit < query.data?.total) {
              setPage(prevPage => prevPage + 1);
            }
          }}
          hasMore={hasMore}
          loader={<ActivityIndicator />}
          endMessage={
            <EndMessage
              visible={query.isSuccess && !hasMore && query.data.total > 0}
            />
          }
          // below props only if you need pull down functionality
          refreshFunction={() => query.refetch()}
          pullDownToRefresh
          pullDownToRefreshContent={<ActivityIndicator />}
          releaseToRefreshContent={<ActivityIndicator />}
          pullDownToRefreshThreshold={50}>
          <Stack spacing={1} direction="column">
            <AdDisplay options={{quantity: 1}} />
            {query.data?.data.map((post: any) => (
              <PostCard post={post} key={post._id} />
            ))}
          </Stack>
        </InfiniteScroll>
      </Stack>
    </Box>
  );
};

export default BusinessPosts;
