export default {
  'acceptBusinessReferral.labels.yourName': 'Votre nom',
  'acceptBusinessReferral.labels.yourFirstName': 'Votre prénom',
  'acceptBusinessReferral.labels.email': 'Email',
  'acceptBusinessReferral.labels.phoneNumber': 'Numéro de téléphone',
  'acceptBusinessReferral.labels.yourBusinessName': 'Nom de votre commerce',
  'acceptBusinessReferral.labels.businessSector': "Secteur d'activité",
  'acceptBusinessReferral.button.continue': 'Continuer',
  'acceptBusinessReferral.messages.acceptPhoneNumberEmail':
    'J’accepte que mon numéro de téléphone ou mon e-mail soit utilisé',
  'acceptBusinessReferral.messages.thankYouForRegistration':
    "Merci pour votre inscription. Pour continuer téléchargez l'application",
  'acceptBusinessReferral.errorMessages.emailAlreadyTaken':
    'Email déjà utilisé',
  'acceptBusinessReferral.errorMessages.phoneNumberAlreadyTaken':
    'Numéro de téléphone déjà utilisé',
  'acceptBusinessReferral.validationErrors.requiredField': 'Champs requis',
  'acceptBusinessReferral.validationErrors.invalidEmail': 'Email invalide',
} as const;
