export default {
  'couponDescription.reductionBy': 'Réduction de <b>{percentage}</b>',
  'couponDescription.withAt': 'chez <b>{name}</b> à',
  'couponDescription.ALL_PRODUCTS': 'sur tous les produits ou services:',
  'couponDescription.PRODUCT': 'sur les produits ou services suivants:',
  'couponDescription.CATEGORY': 'sur les catégories suivantes:',
  'couponDescription.enterCouponInfo':
    'Saisissez vos informations pour reçevoir le coupon',
  'sendCoupon.contactType': 'Type de contact?',
  'sendCoupon.labels.phoneNumber': 'Tél',
} as const;
