export default {
  'common.send': 'Senden',
  'common.internalError':
    'Ein Fehler ist aufgetreten, bitte versuchen Sie es später erneut',
  'common.save': 'Speichern',
  'common.yes': 'Ja',
  'common.no': 'Nein',
  'common.fieldRequired': 'Feld erforderlich',
  'common.invalidPhone': 'Ungültige Telefonnummer',
  'common.inValidEmail': 'Ungültige E-Mail',
  'common.invalidContact': 'Ungültiger Kontakt',
  'common.emailUsed': 'E-Mail bereits verwendet',
  'common.phoneUsed': 'Telefonnummer bereits verwendet',
  'common.safeGuard': 'Speichern',
  'common.showMore': '...mehr anzeigen',
  'common.showLess': 'weniger anzeigen',
  'common.category': 'Kategorie',
  'common.apply': 'Anwenden',
  'common.geoLocationNotActivated': 'Geolokalisierung ist nicht aktiviert',
  'common.country': 'Land',
  'common.phoneNumber': 'Telefonnummer',
} as const;
