export default {
  'businessesList.emptyList': 'No businesses found',
  'businessCard.available': 'Available',
  'notFound.content': '404 Not Found',
  'internalError.content': 'Unknown Error',
  'sendCoupon.couponSoldout': 'The coupon is already sold out',
  'sendCoupon.couponSent': 'The coupon has been successfully sent',
  'sendCoupon.couponNotActivated':
    'This coupon is not yet activated. Please try again later',
  'sendCoupon.dailyQuotaExceeded':
    'You have reached the maximum attempts for this coupon today',
  'couponsList.searchCoupons': 'Search for coupons',
  'couponsList.emptyCouponsList': 'No coupons found',
  'couponsList.defaultTitle': 'Coupons and partner coupons',
  'activeBusinessTabs.home': 'Home',
  'activeBusinessTabs.partners': 'Partners',
  'activeBusinessTabs.catalogs': 'Catalogs',
  'activeBusinessTabs.coupons': 'Coupons',
  'activeBusinessTabs.posts': 'News',
  'userConscent.accept': 'Accept',
  'userConscent.dataUsage': 'Data usage',
  'unauthorized.accessDenied': 'Access Denied',
  'unauthorized.message': 'You do not have access to this page',
  'dashboardFilter.search': 'Search',
  'dashboardFilter.filter': 'Filter',
  'dashboardFilter.customInterval': 'Custom Interval',
  'confirmation.description': 'Are you sure you want to confirm this action?',
  'catalogsList.searchCatalogs': 'Search for catalogs by product...',
  'catalogsList.emptyList': 'No catalogs found',
  'dashboardFilter.thisMonth': 'This month',
  'dashboardFilter.from': 'From',
  'dashboardFilter.to': 'To',
} as const;
