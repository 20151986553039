export default {
  'businessesList.emptyList': 'Aucun commerce trouvé',
  'businessCard.available': 'Disponible',
  'notFound.content': '404 Introuvable',
  'internalError.content': 'Erreur Inconnue',
  'sendCoupon.couponSoldout': 'Le coupon est déjà epuise',
  'sendCoupon.couponSent': 'Le coupon a été bien envoyé',
  'sendCoupon.couponNotActivated':
    'Ce coupon n’est pas encore activé. Veuillez réessayer plus tard',
  'sendCoupon.dailyQuotaExceeded':
    "Vous avez atteint le maximum de tentatives pour ce coupon pour aujourd'hui",
  'couponsList.searchCoupons': 'Rechercher des coupons',
  'couponsList.emptyCouponsList': 'Aucun coupon trouvé',
  'couponsList.defaultTitle': 'Coupons et coupons partenaire',
  'activeBusinessTabs.home': 'Accueil',
  'activeBusinessTabs.partners': 'Partenaires',
  'activeBusinessTabs.catalogs': 'Catalogues',
  'activeBusinessTabs.coupons': 'Coupons',
  'activeBusinessTabs.posts': 'Actualités',
  'userConscent.accept': 'Accepter',
  'userConscent.dataUsage': 'Utilisations de vos données',
  'unauthorized.accessDenied': 'Accès non authorisé',
  'unauthorized.message': "Vous n'avez pas le droit d'accès à cette page",
  'dashboardFilter.search': 'Rechercher',
  'dashboardFilter.filter': 'Filtre',
  'dashboardFilter.customInterval': 'Dans un intervalle personnalisé',
  'confirmation.description': 'Êtes-vous sûr vouloir confirmer cette action?',
  'catalogsList.searchCatalogs': 'Rechercher des catalogues par produit...',
  'catalogsList.emptyList': 'Aucun catalogue trouvé',
  'dashboardFilter.thisMonth': 'Ce mois',
  'dashboardFilter.from': 'Du',
  'dashboardFilter.to': "Jusqu'à",
} as const;
