import React from 'react';

import {MenuItem} from '@mui/material';

import {useGetAllBusinessCategoriesQuery} from '../../../redux/businessesApi';
import useQueryInternalError from '../../hooks/useQueryInternalError';
import useAppLanguage from '../../intl/hooks/use-app-language';
import FormikSelect from '../atoms/formik/FormikSelect';

const FormikBusinessCategoryPicker = ({
  name,
  label,
}: {
  name: string;
  label: React.ReactNode | string;
}) => {
  const lang = useAppLanguage();
  const businessCategoriesQuery = useGetAllBusinessCategoriesQuery();

  const pickerOptions = React.useMemo(() => {
    if (businessCategoriesQuery.data) {
      return businessCategoriesQuery.data.map(o => ({
        value: o._id,
        label: o.nameIntl[lang],
      }));
    }

    return [];
  }, [businessCategoriesQuery.data]);

  useQueryInternalError(businessCategoriesQuery);

  if (businessCategoriesQuery.isSuccess) {
    return (
      <FormikSelect label="common.category" name="category">
        {pickerOptions.map((option: any) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </FormikSelect>
    );
  }

  return null;
};

export default FormikBusinessCategoryPicker;
