import React from 'react';

import {
  AccountTree,
  Business,
  LocalActivity,
  MenuBook,
  RssFeed,
} from '@mui/icons-material';
import {IconButton, Toolbar, Typography} from '@mui/material';

import {useIntl} from '../../intl';
import MenuLink from '../atoms/MenuLink';

const MenuIcon: React.FC<{
  Icon: any;
  text: string;
  sx?: any;
}> = ({Icon, text, sx}) => (
  <IconButton color="inherit" sx={{flexDirection: 'column', ...sx}}>
    <Icon fontSize="medium" />
    <Typography variant="body2">{text}</Typography>
  </IconButton>
);

const ActiveBusinessTabs = ({
  sectionsTabVisible = false,
}: {
  sectionsTabVisible?: boolean;
}) => {
  const intl = useIntl();

  return (
    <Toolbar sx={{justifyContent: 'space-around', flex: 1}}>
      {sectionsTabVisible && (
        <MenuLink to="site">
          <MenuIcon
            Icon={AccountTree}
            text={intl.formatMessage({
              id: 'activeBusinessTabs.home',
            })}
          />
        </MenuLink>
      )}
      <MenuLink to="partners">
        <MenuIcon
          Icon={Business}
          text={intl.formatMessage({
            id: 'activeBusinessTabs.partners',
          })}
        />
      </MenuLink>
      <MenuLink to="catalogs">
        <MenuIcon
          Icon={MenuBook}
          text={intl.formatMessage({
            id: 'activeBusinessTabs.catalogs',
          })}
        />
      </MenuLink>
      <MenuLink to="coupons">
        <MenuIcon
          Icon={LocalActivity}
          text={intl.formatMessage({
            id: 'activeBusinessTabs.coupons',
          })}
        />
      </MenuLink>
      <MenuLink to="posts">
        <MenuIcon
          Icon={RssFeed}
          text={intl.formatMessage({
            id: 'activeBusinessTabs.posts',
          })}
        />
      </MenuLink>
    </Toolbar>
  );
};

export default ActiveBusinessTabs;
