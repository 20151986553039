export default {
  'businessesList.labels.title': 'Negocios',
  'businessFilter.allFilters': 'Todos los Filtros',
  'businessFilter.sort': 'Ordenar',
  'businessFilter.use': 'Usar:',
  'businessFilter.order': 'Orden:',
  'businessFilter.distance': 'Distancia',
  'businessFilter.distanceValue': '{distance} km',
  'businessFilter.asc': 'Ascendente',
  'businessFilter.desc': 'Descendente',
  'businessFilter.relevance': 'Relevancia',
} as const;
