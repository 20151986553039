import Common from '../../lib/common-intl/en';
import LanguagePicker from '../../lib/language-picker/components/intl/en';
import Atoms from '../../lib/ui/atoms/intl/en';
import Molecules from '../../lib/ui/molecules/intl/en';
import BusinessesList from '../../lib/ui/organisms/BusinessesList/intl/en';
import Organisms from '../../lib/ui/organisms/intl/en';
import AcceptBusinessReferral from '../../pages/AcceptBusinessReferral/intl/en';
import BusinessPage from '../../pages/BusinessPage/intl/en';
import BusinessSections from '../../pages/BusinessSections/intl/en';
import CatalogDetails from '../../pages/CatalogDetails/intl/en';
import Home from '../../pages/Home/intl/en';
import SendCoupon from '../../pages/SendCoupon/intl/en';
import StaffCoupons from '../../pages/StaffCoupons/intl/en';

const EN = {
  ...AcceptBusinessReferral,
  ...BusinessesList,
  ...Organisms,
  ...BusinessPage,
  ...BusinessSections,
  ...CatalogDetails,
  ...Home,
  ...Common,
  ...StaffCoupons,
  ...SendCoupon,
  ...Atoms,
  ...Molecules,
  ...LanguagePicker,
} as const;

export default EN;
