import React from 'react';
import {NavLink as RouterLink} from 'react-router-dom';

import {Link} from '@mui/material';
import {grey} from '@mui/material/colors';

const MenuLink = ({to, children}: {to: string; children: React.ReactNode}) => (
  <Link
    sx={{
      '&, &:active': {
        textDecoration: 'none',
      },
      '&:not(.active) *': {
        color: theme => `${grey['500']}!important`,
      },
    }}
    component={RouterLink}
    to={to}>
    {children}
  </Link>
);

export default MenuLink;
