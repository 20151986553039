import React from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';

import {Box, useTheme} from '@mui/material';

import GlobalLoader from '../lib/ui/organisms/GlobalLoader';

import Unauthorized from '../lib/ui/organisms/Unauthorized';
import PubRoute from './components/PubRoute';

import AcceptBusinessReferral from '../pages/AcceptBusinessReferral/AcceptBusinessReferral';
import Businesses from '../pages/Businesses/Businesses';
import BusinessCatalogs from '../pages/BusinessPage/BusinessCatalogs';
import BusinessCoupons from '../pages/BusinessPage/BusinessCoupons';
import BusinessDetails from '../pages/BusinessPage/BusinessDetails';
import BusinessNearby from '../pages/BusinessPage/BusinessNearby';
import BusinessPartners from '../pages/BusinessPage/BusinessPartners';
import BusinessPosts from '../pages/BusinessPage/BusinessPosts';
import PartnersCatalogs from '../pages/BusinessPage/PartnersCatalogs';
import PartnersCoupons from '../pages/BusinessPage/PartnersCoupons';
import BusinessSectionsSite from '../pages/BusinessSections/BusinessSectionsSite';
import CatalogDetails from '../pages/CatalogDetails/CatalogDetails';
import Home from '../pages/Home/Home';
import PostDetails from '../pages/Post/PostDetails';
import SendCouponB2C from '../pages/SendCoupon/SendCouponB2C';
import StaffCatalogs from '../pages/StaffCoupons/StaffCatalogs';
import StaffCoupons from '../pages/StaffCoupons/StaffCoupons';
import StaffPage from '../pages/StaffCoupons/StaffPage';
import StaffPartners from '../pages/StaffCoupons/StaffPartners';
import {useGetBusinessByIdQuery} from '../redux/businessesApi';

const AppRoutes = () => {
  const theme = useTheme();
  const location = useLocation();
  const [businessId, setBusinessId] = React.useState('');

  const businessQuery = useGetBusinessByIdQuery(businessId, {
    skip: !businessId,
  });

  React.useEffect(() => {
    if (location.pathname.includes('business-space')) {
      setBusinessId(location.pathname.replace(/^\//, '').split('/')[1]);
      document.title = businessQuery.data?.name
        ? `Fluxence Pro - ${businessQuery.data?.name}`
        : 'Fluxence Pro';
    }
  }, [businessQuery.data?.name, location.pathname]);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        paddingBottom: 10,
        position: 'relative',
      }}>
      <GlobalLoader />
      <Routes>
        <Route path="unauthorized" element={<PubRoute />}>
          <Route path="" element={<Unauthorized />} />
        </Route>
        <Route path="send-coupon/:id" element={<SendCouponB2C />} />
        <Route
          path="staff/:staff/send-coupon/:id"
          element={<SendCouponB2C />}
        />
        <Route path="catalog/:id" element={<CatalogDetails />} />
        <Route
          path="accept-business-referral/:id"
          element={<AcceptBusinessReferral />}
        />
        <Route path="posts/:id" element={<PostDetails />} />
        <Route path="partner-coupons/:id" element={<PartnersCoupons />} />
        <Route path="partner-catalogs/:id" element={<PartnersCatalogs />} />
        <Route path="business-space/:id" element={<BusinessDetails />}>
          <Route path="site" element={<BusinessSectionsSite />} />
          <Route path="coupons" element={<BusinessCoupons />} />
          <Route path="catalogs" element={<BusinessCatalogs />} />
          <Route path="posts" element={<BusinessPosts />} />
          <Route path="nearby" element={<BusinessNearby />} />
          <Route path="partners" element={<BusinessPartners />}>
            <Route path="coupons" element={<PartnersCoupons />} />
            <Route path="catalogs" element={<PartnersCatalogs />} />
            <Route index element={<PartnersCoupons />} />
          </Route>
          <Route index element={<BusinessCoupons />} />
        </Route>
        <Route path="/staff-coupons/:id" element={<StaffPage />}>
          <Route path="coupons" element={<StaffCoupons />} />
          <Route path="catalogs" element={<StaffCatalogs />} />
          <Route path="partners" element={<StaffPartners />} />
          <Route index element={<StaffCoupons />} />
        </Route>
        <Route path="/data" element={<Businesses />} />
        <Route index element={<Home />} />
      </Routes>
    </Box>
  );
};

export default AppRoutes;
