import {useEffect} from 'react';

import {Box, Stack, Typography} from '@mui/material';

import useQueryInternalError from '../../lib/hooks/useQueryInternalError';
import LoadingOverlay from '../../lib/ui/molecules/LoadingOverlay';

import {useGetBusinessInformationSectionsQuery} from '../../redux/businessInformationSectionsApi';

import {FormattedMessage} from '../../lib/intl';
import BusinessInformationSectionChip from './BusinessInformationSectionChip';

const BusinessInformationSectionsList = ({
  businessId,
  selectedSection,
  onSelectSection,
}: {
  businessId: string;
  selectedSection?: string | null;
  onSelectSection: (section: string) => void;
}) => {
  const query = useGetBusinessInformationSectionsQuery(businessId);

  useEffect(() => {
    if (query.isSuccess && query.data.length > 0 && !selectedSection) {
      onSelectSection(query.data[0]._id);
    }
  }, [query, onSelectSection, selectedSection]);

  useQueryInternalError(query);

  if (query.isLoading) {
    return (
      <Box py={4}>
        <LoadingOverlay />
      </Box>
    );
  }
  if (query.data?.length === 0) {
    return (
      <Box px={2} py={2}>
        <Typography align="center" variant="body1">
          <FormattedMessage id="businessSectionsSite.noSectionAvailable" />
        </Typography>
      </Box>
    );
  }

  return (
    <Stack
      direction="row"
      py={2}
      px={1}
      mb={1}
      spacing={1}
      sx={{
        overflowX: 'scroll',
        borderRadius: '2px',
      }}>
      {query.data?.map(section => (
        <BusinessInformationSectionChip
          key={section._id}
          section={section}
          onSelect={() => onSelectSection(section._id)}
          selected={section._id === selectedSection}
        />
      ))}
    </Stack>
  );
};

export default BusinessInformationSectionsList;
