import {createApi} from '@reduxjs/toolkit/dist/query/react';

import {authBaseQuery} from './apiBaseQuery';

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: authBaseQuery,
  tagTypes: ['AuthUser'],
  endpoints(build) {
    return {
      getAuthUser: build.query<User, void>({
        query: () => ({
          url: '/auth/user',
          method: 'get',
        }),
        providesTags: result =>
          result
            ? [
                {type: 'AuthUser', id: result._id},
                {type: 'AuthUser', id: 'LIST'},
              ]
            : [{type: 'AuthUser', id: 'LIST'}],
      }),
    };
  },
});

export const {useGetAuthUserQuery} = authApi;

export default authApi;
