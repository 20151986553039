import FirebaseStorageImage from '../../lib/firebase-image/components/FirebaseStorageImage';
import AspectRatioImage from '../../lib/ui/atoms/AspectRatioImage';

import BLANK_IMAGE_RECTANGULAR from '../../assets/images/blankimage_rectangular.png';

const SendCouponCoverImage = ({coupon}: {coupon: PopulatedCoupon}) => {
  const reference =
    coupon?.coverImage?.reference || coupon.business.image?.reference;

  return (
    <>
      {reference ? (
        <FirebaseStorageImage
          reference={reference}
          Component={AspectRatioImage}
          ratio={(coupon.coverImageAspectRatio as any) || '16:9'}
        />
      ) : (
        <img
          src={BLANK_IMAGE_RECTANGULAR}
          style={{
            width: '250px',
            height: 'auto',
            alignSelf: 'center',
          }}
        />
      )}
    </>
  );
};

export default SendCouponCoverImage;
