import {createApi} from '@reduxjs/toolkit/dist/query/react';

import providesTagsFromListResult from '../lib/helpers/providesTagsFromListResult';

import {uniqBy} from 'lodash';

import {apiBaseQuery} from './apiBaseQuery';

const postsApi = createApi({
  reducerPath: 'PostsApi',
  baseQuery: apiBaseQuery,
  tagTypes: ['Posts'],
  endpoints(build) {
    return {
      getPosts: build.query<
        any,
        {
          business: string;
          page?: number;
          category?: string;
        }
      >({
        query: ({business, page = 1, category}) => ({
          url: '/posts',
          method: 'get',
          params: {
            business,
            page,
            shareOptionType: 'b2c',
            $populate: 'media',
            '$sort[createdAt]': -1,
            category,
          },
        }),
        serializeQueryArgs: ({queryArgs}) => {
          const newQueryArgs = {...queryArgs};
          if (newQueryArgs.page) {
            delete newQueryArgs.page;
          }
          return newQueryArgs;
        },
        merge: (currentCache, newItems) => {
          if (currentCache.data) {
            return {
              ...currentCache,
              ...newItems,
              data: uniqBy([...currentCache.data, ...newItems.data], '_id'),
            };
          }
          return newItems;
        },
        providesTags: result => providesTagsFromListResult('Posts')(result),
      }),
      getPostById: build.query<any, {id: string}>({
        query: id => ({
          url: `/posts/${id}`,
          method: 'get',
          params: {
            '$populate[0][path]': 'business',
            '$populate[0][populate][path]': 'category',
            '$populate[1]': 'media',
          },
          // $populate[path]=pages&$populate[populate][path]=components&$populate[populate][model]=Component
        }),
        providesTags: result => providesTagsFromListResult('Posts')(result),
      }),
    };
  },
});

export const {useGetPostsQuery, useGetPostByIdQuery} = postsApi;

export default postsApi;
