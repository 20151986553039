import {Box, Paper, Stack, Typography} from '@mui/material';
import format from 'date-fns/format';
import {useLocation, useParams} from 'react-router-dom';

import FirebaseStorageImage from '../../lib/firebase-image/components/FirebaseStorageImage';
import getLineBreakHTMLProp from '../../lib/helpers/getLineBreakHTMLProp';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';
import AspectRatioImage from '../../lib/ui/atoms/AspectRatioImage';
import GoogleMapsLink from '../../lib/ui/atoms/GoogleMapsLink';
import LoadingOverlay from '../../lib/ui/molecules/LoadingOverlay';
import Maps from '../../lib/ui/organisms/Maps';
import NotFound from '../../lib/ui/organisms/NotFound';
import {useLoadingEffect} from '../../redux/loaderSlice';
import {useGetPostByIdQuery} from '../../redux/postsApi';
import PostDetailsAppBar from './PostDetailsAppBar';

const formatDate = (date: Date | number) => format(date, 'd MMMM yyyy - hh:mm');

const PostDetails = () => {
  const {state} = useLocation();
  const {id}: any = useParams();

  const query = useGetPostByIdQuery(id);
  const post = query.data;

  useLoadingEffect(query.isLoading);
  useQueryInternalError(query);

  if (query.isError && (query.error as any)?.status === 404) {
    return <NotFound />;
  }

  if (query.isLoading) {
    return <LoadingOverlay />;
  }

  const postImageReference = post?.media?.reference;

  const address = post?.business.address;

  return (
    <Stack p={1} direction="column" spacing={2}>
      <PostDetailsAppBar post={post} backVisible={state?.goBack} />
      <Box
        sx={{
          paddingTop: !state?.goBack ? '0px' : '30px',
        }}>
        {query.isLoading && <LoadingOverlay />}
        <Stack spacing={2} mb={3}>
          <Typography align="left" variant="h5">
            {post?.business.name}
          </Typography>
          <Typography
            align="left"
            variant="body1"
            color="grey.700"
            lineHeight={0}>
            {post?.business?.category?.name}
          </Typography>
          <Stack spacing={1}>
            <Typography variant="body1" align="left" color="grey.700">
              {address}
            </Typography>
            {address && <GoogleMapsLink address={address} />}
          </Stack>
          <Typography
            align="left"
            variant="body1"
            {...getLineBreakHTMLProp(post?.business?.description)}
          />
        </Stack>
        <Paper variant="outlined">
          <Stack
            sx={{
              width: '100%',
              p: 2,
            }}
            direction="column"
            spacing={1}>
            {postImageReference && (
              <FirebaseStorageImage
                Component={AspectRatioImage}
                reference={postImageReference}
                ratio="16:9"
              />
            )}
            <Typography
              variant="h4"
              align="left"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
              {post.title}
            </Typography>
            <Stack direction="column" spacing={1}>
              <Typography align="justify" variant="body1">
                {post.content}
              </Typography>
              <Typography align="left" variant="body2" pt={1}>
                {formatDate(new Date(post.createdAt))}
              </Typography>
            </Stack>
          </Stack>
        </Paper>
        <Box mt={2}>
          <Maps
            location={{
              lat: post?.business?.location?.coordinates[1],
              lng: post?.business?.location?.coordinates[0],
            }}
          />
        </Box>
      </Box>
    </Stack>
  );
};

export default PostDetails;
