import {useState} from 'react';
import {useParams} from 'react-router-dom';

import {Search} from '@mui/icons-material';
import {Box, Input} from '@mui/material';

import {useDebouncedCallback} from 'use-debounce';

import CouponCard from '../../lib/ui/molecules/CouponCard/CouponCard';
import DataList from '../../lib/ui/organisms/DataList';
import EmptyPageList from '../../lib/ui/organisms/EmptyPageList';

import {FormattedMessage, useIntl} from '../../lib/intl';
import {useGetPartnersCouponsQuery} from '../../redux/couponApi';

const PartnersCoupons = () => {
  const {id} = useParams();
  const intl = useIntl();
  const [search, setSearch] = useState('');
  const debouncedSearchChange = useDebouncedCallback(val => {
    setSearch(val);
  }, 500);

  return (
    <DataList
      header={
        <Box m={1}>
          <Input
            sx={{
              width: '100%',
              py: 1,
            }}
            onChange={evt => debouncedSearchChange(evt.target.value || '')}
            size="medium"
            placeholder={intl.formatMessage({
              id: 'couponsList.searchCouponsPartners',
            })}
            startAdornment={<Search fontSize="medium" />}
          />
        </Box>
      }
      emptyList={
        <EmptyPageList
          message={<FormattedMessage id="couponsList.emptyCouponsList" />}
        />
      }
      useQuery={useGetPartnersCouponsQuery}
      extraQueryParams={{
        orderType: 'MOST_USED',
        business: id,
        hideSoldOut: true,
        search,
      }}
      renderItem={(coupon: PopulatedCoupon) => (
        <CouponCard coupon={coupon} addressVisible />
      )}
    />
  );
};

export default PartnersCoupons;
