import {Phone} from '@mui/icons-material';
import {Stack, Typography} from '@mui/material';

const PhoneNumberLink = ({
  phoneNumber,
  variant = 'body2',
}: {
  phoneNumber: string;
  variant?: string;
}) => (
  <a
    href={`tel://${phoneNumber}`}
    style={{
      textDecoration: 'none',
      color: 'inherit',
    }}
    onClick={e => e.stopPropagation()}>
    <Stack alignItems="center" direction="row" spacing={1}>
      <Phone fontSize="small" />
      <Typography variant={variant as any}>{phoneNumber}</Typography>
    </Stack>
  </a>
);

export default PhoneNumberLink;
