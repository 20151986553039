import React from 'react';
import {useLocation, useParams} from 'react-router-dom';

import {
  Box,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';

import getMobileOperatingSystem from '../../lib/helpers/getMobileOperatingSystem';
import {getCouponSendContact} from '../../lib/helpers/storage';
import {validateEmail, validatePhoneNumber} from '../../lib/helpers/validation';
import useGeolocation from '../../lib/hooks/use-geolocation';

import {Formik, useField, useFormikContext} from 'formik';
import * as yup from 'yup';

import FormikPhoneNumberInput from '../../lib/ui/atoms/formik/FormikPhoneNumberInput';
import FormikRadio from '../../lib/ui/atoms/formik/FormikRadio';
import FormikTextField from '../../lib/ui/atoms/formik/FormikTextField';
import PhoneNumberLink from '../../lib/ui/atoms/PhoneNumberLink';
import LoadingOverlay from '../../lib/ui/molecules/LoadingOverlay';
import InternalError from '../../lib/ui/organisms/InternalError';
import NotFound from '../../lib/ui/organisms/NotFound';

import Apple_Wallet from '../../assets/images/Add_to_Apple_Wallet.svg';
import Google_Wallet from '../../assets/images/Add_to_Google_Wallet.svg';
import LOGO_IMAGE from '../../assets/images/logo_text.png';

import {
  useGetCouponByIdQuery,
  useSendCouponB2CMutation,
  useSendCouponStaffMutation,
} from '../../redux/couponApi';
import {useLoadingEffect} from '../../redux/loaderSlice';

import config from '../../config.json';
import {FormattedMessage} from '../../lib/intl';
import useAppLanguage from '../../lib/intl/hooks/use-app-language';
import UserConscent from '../../lib/ui/organisms/UserConscent';
import CouponDescription from './CouponDescription';
import CouponSendButton from './CouponSendButton';
import SendCouponBar from './SendCouponBar';
import SendCouponCoverImage from './SendCouponCoverImage';

const validationSchema = yup.object({
  contact: yup
    .string()
    .required('common.fieldRequired')
    // @ts-ignore
    .test('testContact', 'common.invalidContact', function (value: string) {
      if (this.parent.contactType === 'email') {
        if (validateEmail(value)) {
          return true;
        } else {
          return this.createError({
            path: this.path,
            message: 'common.inValidEmail',
          });
        }
      } else {
        if (validatePhoneNumber(value)) {
          return true;
        } else {
          return this.createError({
            path: this.path,
            message: 'common.invalidPhone',
          });
        }
      }
    }),
});

const ContactTypeChangeListener = () => {
  const [field] = useField('contactType');
  const form = useFormikContext();
  const previousValRef = React.useRef(field.value);

  React.useEffect(() => {
    if (previousValRef.current !== field.value) {
      form.setFieldValue('contact', '');
      previousValRef.current = field.value;
    }
  }, [field.value, form]);

  return null;
};

const SendCoupon = React.forwardRef(
  (
    {
      mutation,
      sendCoupon,
    }: {
      mutation: ReturnType<
        typeof useSendCouponB2CMutation | typeof useSendCouponStaffMutation
      >[1];
      sendCoupon: (data: {
        contact: string;
        coupon: string;
        geolocation?: {
          longitude: number;
          latitude: number;
        };
      }) => void;
    },
    ref,
  ) => {
    const language = useAppLanguage();
    const geolocation = useGeolocation();
    const platform = getMobileOperatingSystem();
    const [walletPass, setWalletPass] = React.useState<any>(null);
    const {id} = useParams<{
      id: string;
    }>();
    const {state} = useLocation();
    const query = useGetCouponByIdQuery(id as string);

    const coupon = query.data as PopulatedCoupon;

    useLoadingEffect(query.isLoading);

    const androidPassURL = React.useCallback(
      async (couponExemplary: {_id: string}) => {
        const response = await fetch(
          `${config.baseURL}/generatePass/${couponExemplary?._id}?platform=${platform}`,
        );
        const jsonData = await response.json();
        setWalletPass({link: jsonData.passURL, image: Google_Wallet});
      },
      [platform],
    );

    React.useEffect(() => {
      if (mutation.isSuccess) {
        const couponExemplary = mutation.data;
        if (platform === 'Android') {
          androidPassURL(couponExemplary);
        }
        if (platform === 'iOS') {
          setWalletPass({
            link: `${config.baseURL}/generatePass/${couponExemplary?._id}?platform=${platform}`,
            image: Apple_Wallet,
          });
        }
      }
    }, [androidPassURL, mutation, platform]);

    if (query.isError && (query.error as any)?.status === 404) {
      return <NotFound />;
    }

    if (query.isError) {
      return <InternalError />;
    }

    if (query.isLoading) {
      return <LoadingOverlay />;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flex: 1,
        }}>
        <UserConscent />
        <Formik
          validateOnBlur={false}
          validateOnChange
          validationSchema={validationSchema}
          initialValues={{
            contactType: 'phoneNumber',
            contact: getCouponSendContact() || '',
          }}
          onSubmit={({contact}) =>
            sendCoupon({
              contact,
              coupon: id as string,
              geolocation: geolocation.position
                ? {
                    longitude: geolocation.position.coords.longitude,
                    latitude: geolocation.position.coords.latitude,
                  }
                : undefined,
            })
          }>
          {({handleSubmit, values}) => (
            <Stack py={4} px={2} spacing={2} direction="column">
              <SendCouponBar coupon={coupon} backVisible={state?.goBack} />
              <Stack
                sx={{
                  paddingTop: state?.goBack ? '20px' : 0,
                }}
                spacing={2}
                alignSelf="stretch">
                {!state?.goBack && (
                  <img
                    src={LOGO_IMAGE}
                    style={{
                      width: '180px',
                      height: '180px',
                      alignSelf: 'center',
                    }}
                    alt=""
                  />
                )}
                <Typography variant="body1">
                  <FormattedMessage id="couponDescription.enterCouponInfo" />{' '}
                  <b>{coupon?.nameIntl[language]}</b>
                </Typography>
                <SendCouponCoverImage coupon={coupon} />
              </Stack>
              <CouponDescription coupon={coupon} />
              <Stack spacing={1}>
                <FormLabel>
                  <FormattedMessage id="sendCoupon.contactType" />
                </FormLabel>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}>
                  <Box>
                    <RadioGroup row>
                      <FormControlLabel
                        control={
                          <FormikRadio name="contactType" value="phoneNumber" />
                        }
                        label={
                          <FormattedMessage id="sendCoupon.labels.phoneNumber" />
                        }
                      />
                      <FormControlLabel
                        control={
                          <FormikRadio name="contactType" value="email" />
                        }
                        label={
                          <FormattedMessage id="acceptBusinessReferral.labels.email" />
                        }
                      />
                    </RadioGroup>
                  </Box>
                  <Box style={{width: '40%'}}>
                    {walletPass && (
                      <a href={walletPass.link}>
                        <img
                          style={{width: '100%', height: 'auto'}}
                          src={walletPass.image}
                          alt="coupon"
                        />
                      </a>
                    )}
                  </Box>
                </Stack>

                <ContactTypeChangeListener />
                {values.contactType === 'phoneNumber' ? (
                  <FormikPhoneNumberInput
                    name="contact"
                    label={
                      <FormattedMessage id="sendCoupon.labels.phoneNumber" />
                    }
                  />
                ) : (
                  <FormikTextField
                    name="contact"
                    label={
                      <FormattedMessage id="acceptBusinessReferral.labels.email" />
                    }
                  />
                )}
              </Stack>
              <CouponSendButton
                ref={ref}
                disabled={mutation.isSuccess}
                onCouponReachedZero={() => mutation.reset()}
                coupon={query.data?._id}
                loading={mutation.isLoading}
                onClick={mutation.isLoading ? undefined : () => handleSubmit()}
              />
              <Typography
                variant="subtitle1"
                style={{marginTop: 30, fontStyle: 'italic'}}>
                {coupon?.message}
              </Typography>
              <Stack spacing={1}>
                {coupon.phoneNumber && (
                  <>
                    <Typography>
                      Pour plus d'informations, veuillez appeler le :
                    </Typography>
                    <PhoneNumberLink
                      variant="body1"
                      phoneNumber={coupon.phoneNumber?.phoneNumber}
                    />
                  </>
                )}
              </Stack>
            </Stack>
          )}
        </Formik>
      </Box>
    );
  },
);

export default SendCoupon;
