import {FilterList, Search} from '@mui/icons-material';
import {Box, IconButton} from '@mui/material';

import {useIntl} from '../../../intl';
import RoundInput from '../../atoms/RoundInput';

const SearchBarWithFilterButton = ({
  onSearchChange,
  onOpenFilter,
}: {
  onSearchChange: (search: string) => void;
  onOpenFilter: () => void;
}) => {
  const intl = useIntl();
  return (
    <Box
      sx={{
        display: 'flex',
      }}>
      <RoundInput
        placeholder={intl.formatMessage({id: 'dashboardFilter.search'})}
        onChange={evt => onSearchChange(evt.target.value)}
        startAdornment={<Search fontSize="medium" />}
        endAdornment={
          <IconButton onClick={onOpenFilter}>
            <FilterList />
          </IconButton>
        }
      />
    </Box>
  );
};

export default SearchBarWithFilterButton;
