import {createApi} from '@reduxjs/toolkit/dist/query/react';

import {authBaseQuery} from './apiBaseQuery';

const businessReferralsApi = createApi({
  reducerPath: 'businessReferralsApi',
  baseQuery: authBaseQuery,
  endpoints(build) {
    return {
      getBusinessReferralById: build.query<BusinessReferral, string>({
        query: (id: string) => ({
          url: `/getBusinessReferralById/${id}`,
          method: 'get',
        }),
      }),
      acceptBusinessReferral: build.mutation<
        BusinessReferral,
        {
          name: string;
          firstName: string;
          lastName: string;
          phoneNumber: string;
          email: string;
          category: string;
          businessReferral: string;
        }
      >({
        query: data => ({
          url: '/signup',
          method: 'post',
          data,
        }),
        async onQueryStarted(args, api) {
          await api.queryFulfilled;
          api.dispatch(businessReferralsApi.util.resetApiState());
        },
      }),
    };
  },
});

export const {
  useAcceptBusinessReferralMutation,
  useGetBusinessReferralByIdQuery,
} = businessReferralsApi;

export default businessReferralsApi;
