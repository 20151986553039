export default {
  'businessesList.labels.title': 'Unternehmen',
  'businessFilter.allFilters': 'Alle Filter',
  'businessFilter.sort': 'Sortieren',
  'businessFilter.use': 'Verwenden:',
  'businessFilter.order': 'Reihenfolge:',
  'businessFilter.distance': 'Entfernung',
  'businessFilter.distanceValue': '{distance} km',
  'businessFilter.asc': 'Aufsteigend',
  'businessFilter.desc': 'Absteigend',
  'businessFilter.relevance': 'Relevanz',
} as const;
