import React from 'react';

import {internalError} from '../helpers/intenralError';

const useQueryInternalError = (query: any) => {
  return React.useEffect(() => {
    if (query.isError) {
      internalError(query.error);
    }
  }, [query.error, query.isError]);
};

export default useQueryInternalError;
