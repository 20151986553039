import {useState} from 'react';

import {useDebouncedCallback} from 'use-debounce';

const useDebouncedSearch = () => {
  const [search, setSearch] = useState('');
  const debouncedSearchChange = useDebouncedCallback(val => {
    setSearch(val);
  }, 500);

  return {search, debouncedSearchChange};
};

export default useDebouncedSearch;
