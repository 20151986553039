import {Stack, Typography} from '@mui/material';
import {FormattedMessage} from '../../intl';

const Unauthorized = () => (
  <Stack
    sx={{height: '100%'}}
    spacing={2}
    direction="column"
    alignItems="center"
    justifyContent="center">
    <Typography variant="h4" align="center">
      <FormattedMessage id="unauthorized.accessDenied" />
    </Typography>
    <Typography variant="body1">
      <FormattedMessage id="unauthorized.message" />
    </Typography>
  </Stack>
);

export default Unauthorized;
