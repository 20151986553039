import React from 'react';
import {useParams} from 'react-router-dom';

import {Close} from '@mui/icons-material';
import {Box, IconButton, Stack, Typography} from '@mui/material';
import Dialog from '@mui/material/Dialog';

import BusinessCard from '../../lib/ui/molecules/BusinessCard/BusinessCard';
import BusinessMiniDetails from '../../lib/ui/molecules/BusinessMiniDetails';
import BusinessesCategoriesScrollableList from '../../lib/ui/organisms/BusinessesCategoriesScrollableList';
import DataList from '../../lib/ui/organisms/DataList';
import EmptyPageList from '../../lib/ui/organisms/EmptyPageList';

import {FormattedMessage} from '../../lib/intl';
import {useGetReferenceBusinessNearbyBusinessesQuery} from '../../redux/businessesApi';

const BusinessNearby = () => {
  const {id} = useParams<any>();
  const [category, setCategory] = React.useState('');
  const [selectedBusiness, setSelectedBusiness] = React.useState<any>(null);

  const handleCategorySelect = React.useCallback(
    (id: string) => {
      setCategory(id === category ? '' : id);
    },
    [category],
  );

  return (
    <Box position="relative">
      {selectedBusiness && (
        <Dialog
          fullScreen
          open={!!selectedBusiness}
          onClose={() => setSelectedBusiness(null)}>
          <BusinessMiniDetails
            header={
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <Typography variant="h6">{selectedBusiness?.name}</Typography>
                <IconButton onClick={() => setSelectedBusiness(null)}>
                  <Close />
                </IconButton>
              </Stack>
            }
            imageVisible
            nameVisible={false}
            business={selectedBusiness}
          />
        </Dialog>
      )}
      <DataList
        header={
          <BusinessesCategoriesScrollableList
            onSelectCategory={handleCategorySelect}
            selectedCategory={category}
          />
        }
        columns={2}
        emptyList={
          <EmptyPageList
            message={
              <FormattedMessage id="businessDetailsContent.NoSellerNearby" />
            }
          />
        }
        useQuery={useGetReferenceBusinessNearbyBusinessesQuery}
        renderItem={(item: WithCouponsSent<PopulatedBusiness>) => (
          <BusinessCard
            business={item}
            onClick={() => setSelectedBusiness(item)}
          />
        )}
        extraQueryParams={{
          id,
          category,
        }}
      />
    </Box>
  );
};

export default BusinessNearby;
