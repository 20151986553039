import {useGetBusinessSiteHomePageNameQuery} from '../../redux/businessesApi';
import useQueryInternalError from '../hooks/useQueryInternalError';
import useAppLanguage from '../intl/hooks/use-app-language';

const useGetBusinessSiteHomePageName = (id: string) => {
  const lang = useAppLanguage();
  const {data: siteHomePageNameIntl, ...query} =
    useGetBusinessSiteHomePageNameQuery(id as string);

  useQueryInternalError(query);

  return (
    siteHomePageNameIntl?.[lang] ||
    {
      FR: 'Accueil',
      EN: 'Home',
      ES: 'Inicio',
      DE: 'Startseite',
    }[lang]
  );
};

export default useGetBusinessSiteHomePageName;
