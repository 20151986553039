import {useNavigate} from 'react-router-dom';

import {ArrowBack} from '@mui/icons-material';
import {AppBar, IconButton, Stack, Toolbar, Typography} from '@mui/material';

const TextAppBar = ({
  text,
  backVisible = false,
}: {
  text: string;
  backVisible?: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <AppBar
      position="fixed"
      variant="outlined"
      elevation={0}
      color="primary"
      sx={{
        top: 0,
        backgroundColor: theme => theme.palette.background.default,
        color: theme => theme.palette.common.black,
        border: 0,
      }}>
      <Toolbar>
        <Stack
          spacing={2}
          sx={{width: '100%'}}
          direction="row"
          alignItems="center">
          {backVisible && (
            <IconButton color="inherit" onClick={() => navigate(-1)}>
              <ArrowBack fontSize="medium" />
            </IconButton>
          )}
          <Typography
            sx={{
              flex: 1,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
            align="left"
            variant="h6">
            {text}
          </Typography>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default TextAppBar;
