import {useParams} from 'react-router-dom';

import BusinessesList from '../../lib/ui/organisms/BusinessesList/BusinessesList';

import {useGetStaffBusinessesQuery} from '../../redux/businessesApi';

const StaffPartners = () => {
  const {id} = useParams();

  return (
    <BusinessesList
      useQuery={useGetStaffBusinessesQuery}
      extraParams={{
        staff: id,
      }}
    />
  );
};

export default StaffPartners;
