import React from 'react';

import BLANK_IMAGE_RECTANGULAR from '../../../../assets/images/blankimage_rectangular.png';

import FirebaseStorageImage from '../../../firebase-image/components/FirebaseStorageImage';
import AspectRatioImage from '../../atoms/AspectRatioImage';

const CouponCoverImage: React.FC<{
  coupon: Coupon;
  failover?: boolean;
}> = ({coupon, failover = true}) => {
  const couponImage = (coupon.coverImage as AppFile)?.reference;
  const couponBusinessImage = ((coupon.business as Business).image as AppFile)
    ?.reference;

  const renderImage = (uri: string) => (
    <FirebaseStorageImage
      Component={AspectRatioImage}
      reference={uri}
      ratio={(coupon.coverImageAspectRatio as any) || '16:9'}
    />
  );

  if (couponImage) {
    return renderImage(couponImage);
  }

  if (failover) {
    if (couponBusinessImage) {
      return renderImage(couponBusinessImage);
    }
  }

  return <AspectRatioImage src={BLANK_IMAGE_RECTANGULAR} ratio="16:9" />;
};

export default CouponCoverImage;
