import React, {ComponentProps} from 'react';
import PhoneInput, {
  getCountryCallingCode,
  parsePhoneNumber,
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import {Field, FieldProps, useField} from 'formik';
import countries from 'i18n-iso-countries';
import {FormattedMessage, useIntl} from '../../../intl';

const ComponentContext = React.createContext({
  name: '',
});

countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));

const CustomInput = React.forwardRef(({onKeyDown, ...props}: any, ref: any) => {
  const {name} = React.useContext(ComponentContext);
  const [, meta] = useField(name);
  const theme = useTheme();
  const intl = useIntl();

  return (
    <Box
      sx={{
        flex: 1,
        '& > input': {
          border: `1px solid ${
            meta.touched && !!meta.error ? theme.palette.error.main : '#AAA'
          }`,
          borderRadius: 1,
          padding: '18px 14.5px',
          fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontSize: '1rem',
          width: '100%',
        },
      }}>
      <input
        ref={ref}
        {...props}
        placeholder={intl.formatMessage({id: 'common.phoneNumber'})}
        value={
          props.value?.startsWith('+')
            ? parsePhoneNumber(props.value)?.nationalNumber || ''
            : props.value
        }
      />
    </Box>
  );
});

const Container = (props: any) => (
  <Stack direction="row" spacing={1} {...props} />
);

const SelectComponent = (props: any) => {
  const {name} = React.useContext(ComponentContext);
  const [, meta] = useField(name);

  const IconComponent = props.iconComponent;

  const value = props.value || '';

  return (
    <FormControl>
      <InputLabel>
        <FormattedMessage id="common.country" />
      </InputLabel>
      <Select
        sx={{minWidth: 100, maxWidth: 200}}
        label={<FormattedMessage id="common.country" />}
        value={value}
        error={meta.touched && !!meta.error}
        onChange={evt => props.onChange(evt.target.value)}
        renderValue={value => (
          <Stack direction="row" spacing={1} alignItems="center">
            {value && <IconComponent country={value} />}
            <Typography>{getCountryCode(value)}</Typography>
          </Stack>
        )}>
        {props.options
          .filter((option: any) => !!option.value)
          .map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              <Stack direction="row" spacing={1} alignItems="center">
                {option.value && option.label && (
                  <IconComponent country={option.value} label={option.label} />
                )}
                <Typography>{getCountryCodeAndLabel(option.value)}</Typography>
              </Stack>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

const getCountryCodeAndLabel = (val: string) => {
  if (val) {
    const callingCode = getCountryCallingCode(val as any);
    const countryName = countries.getName(val, 'fr');

    return `${countryName || ''} (+${callingCode})`;
  }

  return '';
};

const getCountryCode = (val: string) => {
  if (val) {
    const callingCode = getCountryCallingCode(val as any);

    return `(+${callingCode})`;
  }

  return '';
};

const FormikPhoneNumberInput = ({
  name,
}: {name: string} & Omit<
  ComponentProps<typeof PhoneInput>,
  'onChange' | 'value'
>) => {
  return (
    <ComponentContext.Provider
      value={{
        name,
      }}>
      <Field name={name}>
        {({field, form}: FieldProps) => {
          const defaultCountry = field.value
            ? (parsePhoneNumber(field.value)?.country as any) || 'FR'
            : 'FR';

          return (
            <Stack>
              <PhoneInput
                {...field}
                value={field.value}
                defaultCountry={defaultCountry}
                onChange={value => {
                  form.setFieldValue(field.name, value);
                }}
                inputComponent={CustomInput}
                containerComponent={Container}
                countrySelectComponent={SelectComponent}
              />

              {form.errors[name] && (
                <FormHelperText error={!!form.errors[name]}>
                  <FormattedMessage id={form.errors[name] as string} />
                </FormHelperText>
              )}
            </Stack>
          );
        }}
      </Field>
    </ComponentContext.Provider>
  );
};

export default FormikPhoneNumberInput;
