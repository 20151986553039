import {Box, Stack, Typography} from '@mui/material';
import {useEffect, useRef, useState} from 'react';
// import { useNavigate } from "react-router-dom";
// import LinkTab from "../../components/atoms/LinkTab";
// import last from "lodash/last";

import {FormattedMessage} from '../../lib/intl';
import BusinessMiniDetails from '../../lib/ui/molecules/BusinessMiniDetails';
import HorizontalBusinessPartnersList from './HorizontalBusinessPartnersList';

const BusinessPartners = () => {
  // const location = useLocation();
  // const navigate = useNavigate();
  // const tabValue = last(location.pathname.split("/"));
  const ref = useRef<any>();
  const [selectedBusiness, setSelectedBusiness] =
    useState<WithCouponsSent<PopulatedBusiness>>();

  useEffect(() => {
    setTimeout(() => {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }, 500);
  }, []);

  /* useEffect(() => {
    if (
      location.pathname.endsWith("partners") ||
      location.pathname.endsWith("partners/")
    ) {
      navigate(`coupons`);
    }
  }, [location.pathname, navigate]); */

  return (
    <Stack spacing={1}>
      <Box mt={2}>
        <HorizontalBusinessPartnersList
          onClickItem={(data: any) => setSelectedBusiness(data)}
        />
      </Box>
      {/*<Tabs value={tabValue}>
        <LinkTab label="Catalogues" value="catalogs" to="catalogs" />
        <LinkTab value="coupons" label="Coupons" to="coupons" />
      </Tabs>*/}
      {selectedBusiness ? (
        <BusinessMiniDetails imageVisible business={selectedBusiness} />
      ) : (
        <Typography align="center" variant="body1">
          <FormattedMessage id="businessPartners.noPartnerSelected" />
        </Typography>
      )}
    </Stack>
  );
};

export default BusinessPartners;
