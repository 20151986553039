const invalidatesTags =
  <T extends string | string[]>(type: T) =>
  (result: {_id: string} | undefined) =>
    result
      ? [
          {id: result._id, type},
          {id: 'LIST', type},
        ]
      : [{id: 'LIST', type}];

export default invalidatesTags;
